import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ProgressBar from "../../component/progressbar";
import Mongen from "../../component/static-anim/mongen";
import Evol from "../../component/thumbnail/evol";
import PlotImage from "../../component/thumbnail/plot-image";
import Typo from "../../component/typo";
import { MongenStatType, PlotType, Rarity } from "../../config/interface";
import MongenEntity from "../../config/mongen.entity";
import PlotEntity from "../../config/plot.entity";
import getMongenStat from "../../services/get-mongen-stat";

export default function FusionResult({
  mongenInfo,
  plotInfo,
  onClose,
}: {
  mongenInfo?: MongenEntity | null;
  plotInfo?: PlotEntity | null;
  onClose: Function;
}) {
  const { t } = useTranslation();
  return (
    <div className="absolute top-0 left-0 w-full h-full ui-background z-20">
      <div className="w-full h-ful p-5">
        <p className="text-texttitle font-bold text-[64px] text-center">
          {t("fusion.fusion_workshop")}
        </p>
        {mongenInfo ? (
          <div className="flex justify-center">
            <div className="flex mx-auto mt-[160px] items-center">
              <div className="w-[500px]">
                <div className="flex justify-center">
                  <div className="w-[500px] h-[500px]">
                    <Mongen dna={mongenInfo.dna} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            {plotInfo && (
              <div className="flex justify-center">
                <div className="flex mx-auto mt-[160px] items-center gap-8">
                  <div className="">
                    <div className="flex justify-center">
                      <div className="w-[300px] h-[300px]">
                        <PlotImage plotInfo={plotInfo} />
                      </div>
                    </div>
                  </div>
                  <div className="text-center">
                    <p className="text-4xl text-textnumberyellow">
                      #{plotInfo.id}
                    </p>
                    <p className="text-4xl">
                      <Typo type="plottype" value={plotInfo.plot_type} />
                    </p>
                    <p className="text-4xl">
                      {" "}
                      <Typo type="rarity" value={plotInfo.rarity} />
                    </p>
                  </div>
                </div>
              </div>
            )}
          </>
        )}

        <button
          className="ui-button_confirm_fusion mx-auto block mt-10 interactive"
          onClick={() => {
            onClose();
          }}
        >
          <p className="text-4xl">{t("result.confirm")}</p>
        </button>
      </div>
    </div>
  );
}
