import { useDispatch } from "react-redux";
import { getItems } from "../slices/inventory.slice";
import {
  getAuthInfos,
  getStake,
  setCustomerInfo,
  setStake,
  updateSetting as _updateSetting,
} from "../slices/user.slice";
import { AppDispatch } from "../store";

export default function useCustomer() {
  const dispatch = useDispatch<AppDispatch>();
  function updateCustomerInfo(data: any) {
    dispatch(setCustomerInfo(data));
  }
  function updateStake(data: any) {
    dispatch(setStake(data));
  }
  function getAuth() {
    dispatch(getAuthInfos());
  }
  function refreshStake() {
    dispatch(getStake());
  }
  function refreshInventory() {
    dispatch(getItems());
  }
  function updateSetting(setting: any) {
    dispatch(_updateSetting(setting));
  }
  return {
    updateSetting,
    updateCustomerInfo,
    updateStake,
    refreshStake,
    refreshInventory,
    getAuth,
  };
}
