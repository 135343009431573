import { AppDispatch } from "../store";
import MongenEntity from "config/mongen.entity";
import PlotEntity from "config/plot.entity";
import { useDispatch } from "react-redux";
import { PlotType } from "../config/interface";
import {
  activePlot,
  createBackup,
  getItems,
  addImportedItems as _addImportedItems,
  resetImportedItems as _resetImportedItems,
  removeItems as _removeItems,
  destroyItems as _destroyItems,
  addItems as _addItems,
} from "../slices/inventory.slice";

export default function useInventory() {
  const dispatch = useDispatch<AppDispatch>();
  function activeInventoryPlot(id: number, plotType: PlotType) {
    dispatch(activePlot({ id, plotType }));
  }
  function addImportedItems(payload: {
    mongenInfos: MongenEntity[];
    plotInfos: PlotEntity[];
  }) {
    dispatch(_addImportedItems(payload));
  }
  function resetImportedItems() {
    dispatch(_resetImportedItems({}));
  }
  function backupInventory() {
    dispatch(createBackup());
  }
  function restoreInventory() {
    dispatch(createBackup());
  }
  function refreshInventory() {
    dispatch(getItems());
  }
  function removeItems(data: any) {
    dispatch(_removeItems(data));
  }
  function destroyItems(data: any) {
    dispatch(_destroyItems(data));
  }
  function addItems(data: any) {
    dispatch(_addItems(data));
  }
  return {
    removeItems,
    addImportedItems,
    resetImportedItems,
    activeInventoryPlot,
    backupInventory,
    restoreInventory,
    refreshInventory,
    addItems,
    destroyItems,
  };
}
