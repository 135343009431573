import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { BodyPart, MongenRace, MongenState, MongenStatType, Rarity } from "../../config/interface";
import utils from "../../services/utils";
import Balance from "component/balance";
import RarityFilter from "component/rarity-filter";
import { useNavigate } from "react-router-dom";
import MongenEntity from "config/mongen.entity";
import { FusionThumbnail } from "component/thumbnail/fusion.thumbnail";
import Mongen from "component/static-anim/mongen";
import useUI from "hooks/useUI";
import api from "services/api";
import useCustomer from "hooks/useCustomer";
import Button from "component/button";

export default function Mutation() {
  const { t } = useTranslation();
  const { mongenInfos } = useSelector(
    (state: RootState) => state.inventorySlice
  );
  const { customerInfo } = useSelector((state: RootState) => state.userSlice);
  const [rarity, setRarity] = useState<Rarity>(Rarity.All);
  const [sortField, setSortField] = useState<"id" | "rarity" | "capacity">(
    "id"
  );
  const nav = useNavigate();
  const [fee, setFee] = useState<{ mstr: number; mutated_gen: number } | null>(null);
  const [mongenInfo, setMongenInfo] = useState<MongenEntity>(null);
  const [openInventory, setOpenInventory] = useState(false)
  const [selectedPart, setSelectedPart] = useState(null);
  const [tempPartValue, setTempPartValue] = useState(null);
  const ui = useUI();
  const customerService = useCustomer();

  const startMutate = async () => {
    if (!mongenInfo || selectedPart === null) {
      return;
    }
    try {
      ui.showLoading();
      let { mongenInfo: newMongenInfo, customerInfo } = await api.configMutatation(
        mongenInfo.id,
        selectedPart
      );
      setTempPartValue(newMongenInfo.temp_mutated_part_value);
      setMongenInfo(newMongenInfo)
      customerService.updateCustomerInfo(customerInfo);
      customerService.refreshInventory();
    } catch (error: any) {
      ui.openMessage(t(error.message));
    } finally {
      ui.hideLoading();
    }
  }

  const discard = async () => {
    try {
      ui.showLoading();
      let { mongenInfo: newMongenInfo, customerInfo } = await api.discardMutation(
        mongenInfo.id,
      );
      setTempPartValue(newMongenInfo.temp_mutated_part_value);
      setMongenInfo(newMongenInfo)
      setSelectedPart(null);
      customerService.updateCustomerInfo(customerInfo);
      customerService.refreshInventory();
    } catch (error: any) {
      ui.openMessage(t(error.message));
    } finally {
      ui.hideLoading();
    }
  }

  const confirm = async () => {
    try {
      ui.showLoading();
      let { mongenInfo: newMongenInfo, customerInfo } = await api.applyMutation(
        mongenInfo.id,
      );
      setTempPartValue(newMongenInfo.temp_mutated_part_value);
      setMongenInfo(newMongenInfo)
      setSelectedPart(null);
      // setTempPartValue(null);
      // setMongenInfo(null)
      // setSelectedPart(null);
      customerService.updateCustomerInfo(customerInfo);
      customerService.refreshInventory();
    } catch (error: any) {
      ui.openMessage(t(error.message));
    } finally {
      ui.hideLoading();
    }
  }

  function slotButton() {
    return (
      <div
        className={`duration-150 ui-blank_fusion_slot mx-auto flex items-center justify-center interactive`}
        onClick={() => {
          setOpenInventory(true);
        }}
      >
        {mongenInfo ? <div>{
          mongenInfo ?
            <FusionThumbnail
              mongenInfo={mongenInfo}
            />
            : <div></div>
        }</div> :
          <div className="ui-added_fusion"></div>
        }
      </div>
    );
  }

  function onBackClick() {
    nav(-1);
  }

  useEffect(() => {
    if (customerInfo?.mutating_mongen) {
      console.log(customerInfo.mutating_mongen)
      let mongenInfo = mongenInfos.find((i) => i.id === customerInfo.mutating_mongen)
      setMongenInfo(mongenInfo)
      setTempPartValue(mongenInfo?.temp_mutated_part_value)
      setSelectedPart(mongenInfo?.temp_mutated_part_value?.body_part)
    }
  }, [])

  useEffect(() => {
    if (mongenInfo && selectedPart)
      setFee(utils.getMutatedPrice(mongenInfo, selectedPart))
    else {
      setFee({
        mstr: 0,
        mutated_gen: 0
      })
    }
  }, [mongenInfo, selectedPart])

  return (
    <>
      <div className="ui-background p-4">
        <div className="flex justify-between w-full items-center">
          <p className="text-[90px] font-bold text-texttitle h-24">
            {t("mutation_lab")}
          </p>
          <div className="mb-4">
            <Balance />
          </div>
          <button
            className="ui-button_back_on interactive text-3xl font-bold"
            onClick={() => {
              onBackClick();
            }}
          >
            {t("global.back")}
          </button>
        </div>
        {tempPartValue?.value ?
          <Result mongenInfo={mongenInfo} selectedPart={selectedPart} discard={discard} confirm={confirm} setTempPartValue={setTempPartValue} setMongenInfo={setMongenInfo} />
          : <div>
            <div className="flex">
              <div className="w-full bg-[#353230] h-[820px] rounded-xl flex">
                {openInventory ? <div className={`w-full`}>
                  <div className="flex justify-center items-center">
                    <div className="overflow-y-auto overflow-x-hidden h-[820px] pr-2">
                      {
                        mongenInfos
                          .filter((m: MongenEntity) => {
                            if (m.mongen_state !== MongenState.Idle) return false;
                            if (m.is_attach || m.is_click2earn) return false;
                            if (rarity !== Rarity.All && m.dna[9][0] !== rarity) {
                              return false;
                            }
                            return true;
                          })
                          .sort((a: MongenEntity, b: MongenEntity) => {
                            switch (sortField) {
                              case "id":
                                return b.id - a.id;
                              case "rarity":
                                return b.dna[9][0] - a.dna[9][0];
                            }
                            return b.id - a.id;
                          })
                          .map((m: MongenEntity) => (
                            <div className="w-1/6 p-1 inline-block" key={m.id}>
                              <FusionThumbnail
                                mongenInfo={m}
                                onClick={() => {
                                  setOpenInventory(false);
                                  setMongenInfo(m)
                                  setSelectedPart(null);
                                }}
                                active={mongenInfo?.id === m.id}
                              />
                            </div>
                          ))
                      }
                    </div>
                  </div>
                </div> :
                  <div className="w-full">
                    <div className="px-[200px] py-[80px]">
                      <div className="flex gap-4 h-580px]">
                        <div className="w-[200px]">
                          {slotButton()}
                        </div>
                        <div className="flex flex-col gap-4 w-full px-4 rounded-xl">
                          {[BodyPart.Head, BodyPart.Eyes, BodyPart.Horns, BodyPart.Tail, BodyPart.Back].map((bodyPart, idx) => {
                            let dna = mongenInfo?.dna;
                            let baseStatConfig = dna ? utils.getMongenBaseStatConfig(dna, mongenInfo.mutated_part_value, mongenInfo.mutated_part_value) : null;
                            return (
                              <div className="flex gap-2 w-full">
                                <div className="self-center w-[80px] pr-[14px] text-center">{BodyPart[bodyPart]}</div>
                                <div className={`flex flex-col gap-1 self-center px-1 py-2 w-[180px] text-center bg-rarity-${dna?.[bodyPart]?.[1] ?? 0} rounded-xl`}>
                                  {dna ? <div className={`ui-icon_${MongenRace[dna[bodyPart][0]].toLowerCase()} self-center text-center mx-auto`}></div> : <div className="h-8"></div>}
                                  <div className="text-[14px]">{dna ? utils.getDnaName(dna[bodyPart]) : ""}</div>
                                </div>
                                <div className="grid grid-cols-2 gap-x-2 self-center ml-4 w-[600px]">
                                  {baseStatConfig?.origin?.map((values, part) => {
                                    if (bodyPart === part) {
                                      return values.map((value, stat) => {
                                        let changedValue = baseStatConfig.changed[bodyPart][stat]
                                        return (
                                          <div className="flex gap-2">
                                            <img src={`/assets/images/stats/${MongenStatType[stat].toLowerCase()}.svg`} alt="icon" className="h-6" />
                                            <div className="flex gap-1">
                                              <div className="self-center">{MongenStatType[stat]}: {Math.round(value * 100) / 100}</div>
                                              {!!changedValue && <div
                                                className={`${changedValue > 0 && "text-[#48Bf53] self-center"} ${changedValue < 0 && "text-[#B74545]"}`}>{changedValue >= 0 ? `+ ${changedValue}` : `- ${Math.abs(changedValue)}`}
                                              </div>}
                                            </div>
                                          </div>
                                        )
                                      })
                                    }
                                  })}
                                </div>
                                <div>
                                  <button
                                    onClick={async () => {
                                      if (dna) {
                                        setSelectedPart(bodyPart);
                                      }
                                    }}
                                    className={`ui-button_confirm_mutation text-lg font-bold scale-[0.6] uppercase ${bodyPart === selectedPart || !dna ? "grayscale" : "interactive"}`}
                                  >
                                    {bodyPart === selectedPart ? t("selected") : t("select")}
                                  </button>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                      {mongenInfo && <div className="w-full flex self-center">
                        <button
                          onClick={startMutate}
                          className={`ui-button_start_mutate_pressed ${(mongenInfo && selectedPart !== null) ? "interactive" : "grayscale"} text-3xl font-bold scale-[0.6] mx-auto`}
                        >
                          <p>{t("start_mutation")}</p>
                          <div className="flex gap-4 items-center justify-center w-full">
                            <div className="flex items-center">
                              <div className="ui-icon_mstr mr-1"></div>
                              <span className={`${fee?.mstr > customerInfo.mstr ? "text-red-400" : "text-textnumberyellow "}`}>
                                {utils.convertToInternationalCurrencySystem(
                                  fee?.mstr || 0
                                )}
                              </span>
                            </div>
                            +
                            <div className="flex items-center">
                              <div className="mr-1">
                                <img src="/assets/images/mutategen.png" alt="mutategen" className="h-8" />
                              </div>
                              <span className={`${fee?.mutated_gen > customerInfo.mutated_gen ? "text-red-400" : "text-textnumberyellow "}`}>
                                {utils.convertToInternationalCurrencySystem(
                                  fee?.mutated_gen || 0
                                )}
                              </span>
                            </div>
                          </div>
                        </button>
                      </div>}
                    </div>
                  </div>
                }
              </div>
            </div>
            <div className="w-full absolute bottom-0 left-0 p-4">
              <div className="relative w-full">
                <div>
                  <RarityFilter
                    rarity={rarity}
                    sort={sortField}
                    setRarity={(r: Rarity) => {
                      setRarity(r);
                    }}
                    setSortField={setSortField}
                  />
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </>
  );
}

const Result = ({
  mongenInfo,
  selectedPart,
  discard,
  confirm,
  setTempPartValue,
  setMongenInfo
}:
  {
    mongenInfo: MongenEntity,
    selectedPart: BodyPart,
    discard: any,
    confirm: any,
    setTempPartValue: any,
    setMongenInfo: any
  }) => {
  let baseStatConfig = utils.getMongenBaseStatConfig(mongenInfo.dna, mongenInfo.mutated_part_value);
  let newBaseStatConfig = utils.getMongenBaseStatConfig(mongenInfo.dna, null, mongenInfo.temp_mutated_part_value);

  let mongenStat = utils.getMongenBaseStat(mongenInfo, mongenInfo.mutated_part_value)
  let newMongenStat = utils.getMongenBaseStat(mongenInfo, mongenInfo.mutated_part_value, mongenInfo.temp_mutated_part_value);
  const [fee, setFee] = useState(utils.getMutatedPrice(mongenInfo, selectedPart))

  const ui = useUI();
  const customerService = useCustomer();
  const { customerInfo } = useSelector((state: RootState) => state.userSlice);

  const rerollMutate = async () => {
    if (!mongenInfo || selectedPart === null) {
      return;
    }
    try {
      ui.showLoading();
      let { mongenInfo: newMongenInfo, customerInfo } = await api.rerollMutation(
        mongenInfo.id,
        selectedPart
      );
      setTempPartValue(newMongenInfo.temp_mutated_part_value);
      setMongenInfo(newMongenInfo)
      customerService.updateCustomerInfo(customerInfo);
      customerService.refreshInventory();
    } catch (error: any) {
      ui.openMessage(t(error.message));
    } finally {
      ui.hideLoading();
    }
  }

  useMemo(() => {
    let newFee = utils.getMutatedPrice(mongenInfo, selectedPart)
    setFee(newFee)
  }, [mongenInfo])

  const { t } = useTranslation();

  return (
    <div className="w-full bg-[#353230] h-[910px] rounded-xl">
      <p className="text-[90px] font-bold text-texttitle h-24 text-center mx-auto">
        {t("mutation_success")}
      </p>
      <p className="text-[40px] font-bold text-md mb-4 text-center mx-auto">
        Mutated Part: {BodyPart[selectedPart]}
      </p>
      <div className="grid grid-cols-11 gap-4">
        <div className="self-center mx-auto col-span-5">
          <div className="grid grid-cols-2 gap-x-2 gap-y-2 gap self-center ml-4 w-full">
            {baseStatConfig.origin.map((values, bodyPart) => {
              if (bodyPart === selectedPart) {
                return values.map((value, stat) => {
                  let changedValue = baseStatConfig.changed[bodyPart][stat]
                  return (
                    <div className="flex gap-2">
                      <img src={`/assets/images/stats/${MongenStatType[stat].toLowerCase()}.svg`} alt="icon" className="h-6" />
                      <div className="flex gap-1">
                        <div className="self-center">{MongenStatType[stat]}: {Math.round(value * 100) / 100}</div>
                        {!!changedValue && <div
                          className={`${changedValue > 0 && "text-[#48Bf53] self-center"} ${changedValue < 0 && "text-[#B74545]"}`}>{changedValue >= 0 ? `+ ${changedValue}` : `- ${Math.abs(changedValue)}`}
                        </div>}
                      </div>
                    </div>
                  )
                })
              }
            })}
          </div>
        </div>
        <div className="flex self-center w-full">
          <img src="/assets/images/convert_to.png" alt="convert" className="text-center mx-auto h-[60px]" />
        </div>
        <div className="self-center mx-auto col-span-5">
          <div className="grid grid-cols-2 gap-x-2 gap-y-2 self-center ml-4 w-full">
            {newBaseStatConfig.origin.map((values, bodyPart) => {
              if (bodyPart === selectedPart) {
                return values.map((value, stat) => {
                  let changedValue = newBaseStatConfig.changed[bodyPart][stat]
                  return (
                    <div className="flex gap-2">
                      <img src={`/assets/images/stats/${MongenStatType[stat].toLowerCase()}.svg`} alt="icon" className="h-6" />
                      <div className="flex gap-1">
                        <div className="self-center">{MongenStatType[stat]}: {Math.round(value * 100) / 100}</div>
                        {!!changedValue && <div
                          className={`${changedValue > 0 && "text-[#48Bf53] self-center"} ${changedValue < 0 && "text-[#B74545]"}`}>{changedValue >= 0 ? `+ ${changedValue}` : `- ${Math.abs(changedValue)}`}
                        </div>}
                      </div>
                    </div>
                  )
                })
              }
            })}
          </div>
        </div>
      </div>

      <p className="text-[40px] font-bold text-md mt-14 text-center mx-auto">
        Mongen Stats
      </p>
      <div className="grid grid-cols-11 gap-4">
        <div className="self-center mx-auto col-span-5 translate-y-[-100px]">
          <div className="h-[320px]">
            <Mongen dna={mongenInfo.dna} />
          </div>
          <div className="grid grid-cols-2 gap-x-2 gap-y-2 gap self-center ml-4 w-full mt-2">
            {mongenStat.originStat.map((value, stat) => {
              let changedValue = mongenStat.changed[stat];
              return (
                <div className="flex gap-2">
                  <img src={`/assets/images/stats/${MongenStatType[stat].toLowerCase()}.svg`} alt="icon" className="h-6" />
                  <div className="flex gap-1">
                    <div className="self-center">{MongenStatType[stat]}: {Math.round(value * 100) / 100}</div>
                    {!!changedValue && <div
                      className={`${changedValue > 0 && "text-[#48Bf53] self-center"} ${changedValue < 0 && "text-[#B74545]"}`}>{changedValue >= 0 ? `+ ${changedValue}` : `- ${Math.abs(changedValue)}`}
                    </div>}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <div className="flex self-center w-full">
          <img src="/assets/images/convert_to.png" alt="convert" className="text-center mx-auto" />
        </div>
        <div className="self-center mx-auto col-span-5 translate-y-[-100px]">
          <div className="h-[320px]">
            <Mongen dna={mongenInfo.dna} />
          </div>
          <div className="grid grid-cols-2 gap-x-2 gap-y-2 self-center ml-4 w-full mt-2">
            {newMongenStat.originStat.map((value, stat) => {
              let changedValue = newMongenStat.changed[stat];
              return (
                <div className="flex gap-2">
                  <img src={`/assets/images/stats/${MongenStatType[stat].toLowerCase()}.svg`} alt="icon" className="h-6" />
                  <div className="flex gap-1">
                    <div className="self-center">{MongenStatType[stat]}: {Math.round(value * 100) / 100}</div>
                    {!!changedValue && <div
                      className={`${changedValue > 0 && "text-[#48Bf53] self-center"} ${changedValue < 0 && "text-[#B74545]"}`}>{changedValue >= 0 ? `+ ${changedValue}` : `- ${Math.abs(changedValue)}`}
                    </div>}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>

      <div className="grid grid-cols-3 gap-4 justify-between w-full mt-16 px-[160px] translate-y-[-180px]">
        <Button
          text={t("discard")}
          className="uppercase self-center mx-auto"
          color="red"
          size="lg"
          onClick={discard}
        />
        <button
          onClick={rerollMutate}
          className={`ui-button_start_mutate_pressed ${(mongenInfo && selectedPart !== null) ? "interactive" : "grayscale"} text-3xl font-bold scale-[0.6] mx-auto mx-auto translate-y-[-40px]`}
        >
          <p>{t("reroll_mutation")}</p>
          <div className="flex gap-4 items-center justify-center w-full">
            <div className="flex items-center">
              <div className="ui-icon_mstr mr-1"></div>
              <span className={`${fee?.mstr > customerInfo.mstr ? "text-red-400" : "text-textnumberyellow "}`}>
                {utils.convertToInternationalCurrencySystem(
                  fee?.mstr || 0
                )}
              </span>
            </div>
            +
            <div className="flex items-center">
              <div className="mr-1">
                <img src="/assets/images/mutategen.png" alt="mutategen" className="h-8" />
              </div>
              <span className={`${fee?.mutated_gen > customerInfo.mutated_gen ? "text-red-400" : "text-textnumberyellow "}`}>
                {utils.convertToInternationalCurrencySystem(
                  fee?.mutated_gen || 0
                )}
              </span>
            </div>
          </div>
        </button>
        <Button
          text={t("dialog.confirm")}
          className="uppercase self-center mx-auto"
          color="green"
          size="lg"
          onClick={confirm}
        />
      </div>
    </div>
  )
}