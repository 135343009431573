import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { PlotThumbnail } from "../../component/thumbnail/plot.thumbnail";
import MongenEntity from "../../config/mongen.entity";
import PlotEntity from "../../config/plot.entity";
import { RootState } from "../../store";
import { headShake as anim, pulse } from "react-animations";
import styled, { keyframes } from "styled-components";
import _ from "lodash";
import useUI from "../../hooks/useUI";
import {
  Chain,
  LoginType,
  MongenState,
  NFTType,
  Rarity,
} from "../../config/interface";
import useCustomer from "../../hooks/useCustomer";
import Balance from "component/balance";
import RarityFilter from "component/rarity-filter";
import { MongenThumbnail } from "component/thumbnail/mongen.thumbnail";
import { Dropdown } from "semantic-ui-react";
import AuthEntity from "config/auth.entity";
import utils from "services/utils";
import api, { CHAIN_MAP } from "services/api";
import useInventory from "hooks/useInventory";
import SelectChain from "component/select-chain";
import dayjs from "dayjs";
const AnimDiv = styled.div`
  .animate {
    animation: 1s ${keyframes`${anim}`} infinite;
  }
`;

const CHAIN_ICON: { [key in Chain]?: string } = {
  [Chain.BSC]: "bsc",
  [Chain.Avalanche]: "avalanche",
  [Chain.Okex]: "okex",
};
const TypeMap = {
  mongen: NFTType.Mongen,
  soulcore: NFTType.SoulCore,
  plot: NFTType.Plot,
  all: 1,
};
export default function Inventory() {
  const { t } = useTranslation();
  const nav = useNavigate();
  const ui = useUI();
  const inventory = useInventory();
  const params = useParams();
  const customerService = useCustomer();
  const { mongenInfos: offchainMongenInfos, plotInfos: offchainPlotInfos } =
    useSelector((state: RootState) => state.inventorySlice);

  const { customerInfo, authInfos, stake } = useSelector(
    (state: RootState) => state.userSlice
  );

  const [type, setType] = useState<"mongen" | "plot" | "soulcore" | "all">(
    "all"
  );
  const [newMongen, setNewMongen] = useState<MongenEntity | null>(null);
  const [newPlot, setNewPlot] = useState<PlotEntity | null>(null);
  const [fee, setFee] = useState<{ mstr: number; elixir: number } | null>(null);
  const [selected, setSelected] = useState<number[]>([]);
  const [selectedType, setSelectedType] = useState<
    "mongen" | "plot" | "soulcore" | "all"
  >("all");
  const [mode, setMode] = useState<"view" | "convert" | "import">("view");
  const [slot, setSlot] = useState<number>(0);
  const [attach, setAttach] = useState<number[]>([]);
  const [rarity, setRarity] = useState<Rarity>(Rarity.All);
  const [sortField, setSortField] = useState<"id" | "rarity" | "capacity">(
    "id"
  );
  const [mongenInfos, setMongenInfos] = useState<MongenEntity[]>([]);
  const [chain, setChain] = useState<Chain>(Chain.BSC);
  const [auth, setAuth] = useState<AuthEntity>(null);
  const [plotInfos, setPlotInfos] = useState<PlotEntity[]>([]);
  // @ts-ignore
  const [tab, setTab] = useState<"off" | "on">(params.tab);
  function onBackClick() {
    nav(-1);
  }
  async function loadOnchainItem() {
    ui.showLoading();
    try {
      let { mongenInfos, plotInfos } = await api.GetNfts(
        CHAIN_MAP[auth.type],
        auth.identity
      );
      setMongenInfos(mongenInfos);
      setPlotInfos(plotInfos);
    } catch (error) {
    } finally {
      ui.hideLoading();
    }
  }
  useEffect(() => {
    if (auth && tab === "on") {
      loadOnchainItem();
    }
  }, [auth, tab]);
  useEffect(() => {
    if (tab === "off") {
      setMongenInfos(offchainMongenInfos);
      setPlotInfos(offchainPlotInfos);
    }
  }, [tab, mode, offchainMongenInfos, offchainMongenInfos]);
  function onTypeClick(_type: "mongen" | "plot" | "all" | "soulcore") {
    if (_type === type) {
      return;
    }
    setNewMongen(null);
    setNewPlot(null);
    setAttach([]);
    setSlot(0);
    setType(_type);
  }
  function onTabClick(_tab: "on" | "off") {
    if (_tab === tab) return;
    if (_tab === "on" && !auth) {
      return ui.openMessage(t("inventory.alert_attach_wallet"));
    }
    setMode("view");
    setTab(_tab);
    setSelected([]);
    setSelectedType("all");
  }
  function onMongenClick(item: MongenEntity) {
    if (mode === "view") return;
    let tmp = _.cloneDeep(selected);
    if (tmp.includes(item.id)) {
      tmp = tmp.filter((i: number) => i !== item.id);
    } else {
      tmp.push(item.id);
    }
    setSelected(tmp);
    if (tmp.length) {
      if (item.mongen_state === MongenState.Egg) {
        setSelectedType("soulcore");
      } else {
        setSelectedType("mongen");
      }
    } else {
      setSelectedType("all");
    }
  }
  function onPlotClick(item: PlotEntity) {
    if (mode === "view") return;
    let tmp = _.cloneDeep(selected);
    if (tmp.includes(item.id)) {
      tmp = tmp.filter((i: number) => i !== item.id);
    } else {
      tmp.push(item.id);
    }
    setSelected(tmp);
    if (tmp.length) {
      setSelectedType("plot");
    } else {
      setSelectedType("all");
    }
  }
  async function onConfirmClick() {
    if (!selected.length) {
      return;
    }
    if (tab === "off") {
      let chain: number = await ui.openSelectChain({
        type: selectedType,
        ids: selected,
      });

      try {
        ui.showLoading();
        await api.getNFTSignature(selected, TypeMap[selectedType], chain);
      } catch (error) {
      } finally {
        inventory.refreshInventory();
        ui.hideLoading();
        setSelected([]);
        setSelectedType("all");
      }
    } else {
      try {
        ui.showLoading();
        await api.depositNFT(
          selected,
          TypeMap[selectedType],
          CHAIN_MAP[auth.type],
          auth.identity
        );
      } catch (error) {
      } finally {
        loadOnchainItem();
        setSelected([]);
        setSelectedType("all");
      }
    }
  }
  return (
    <>
      <div className="ui-background p-4">
        <div className="flex justify-between w-full items-center">
          <p className="text-[90px] font-bold text-texttitle h-24">
            {t("inventory.inventory")}
          </p>
          <div className="mb-4">
            <Balance />
          </div>
          <button
            className="ui-button_back_on interactive text-3xl font-bold"
            onClick={() => {
              onBackClick();
            }}
          >
            {t("global.back")}
          </button>
        </div>
        <div className="flex gap-8 w-full">
          <div className="w-full overflow-hidden">
            <div className="flex justify-between">
              <div className="gap-2 flex">
                <button
                  onClick={() => {
                    onTabClick("off");
                  }}
                  className={`ui-button_mongen_on interactive text-3xl font-bold ${
                    tab !== "off" && "grayscale"
                  }`}
                >
                  {t("inventory.in_game")}
                </button>
                <button
                  onClick={() => {
                    onTabClick("on");
                  }}
                  className={`ui-button_mongen_on interactive text-3xl font-bold ${
                    tab !== "on" && "grayscale"
                  }`}
                >
                  {t("inventory.on_chain")}
                </button>
              </div>
              <div className="">{<SelectChain onAuthChange={setAuth} />}</div>
            </div>
            <div className="overflow-y-auto overflow-x-hidden h-[682px] mt-2">
              {(type === "mongen" || type === "soulcore" || type === "all") &&
                mongenInfos
                  .filter((m: MongenEntity) => {
                    if (type === "mongen" && m.mongen_state === MongenState.Egg)
                      return false;
                    if (
                      type === "soulcore" &&
                      m.mongen_state !== MongenState.Egg
                    )
                      return;
                    if (mode === "convert" && dayjs(m.freeze_until) > dayjs())
                      return false;
                    if (selectedType === "plot") return false;
                    if (
                      selectedType === "soulcore" &&
                      m.mongen_state !== MongenState.Egg
                    )
                      return false;
                    if (
                      selectedType === "mongen" &&
                      m.mongen_state === MongenState.Egg
                    )
                      return false;
                    if (
                      rarity !== Rarity.All &&
                      utils.getDNARarity(m.dna) !== rarity
                    ) {
                      return false;
                    }
                    return true;
                  })
                  .sort((a: MongenEntity, b: MongenEntity) => {
                    switch (sortField) {
                      case "id":
                        return b.id - a.id;
                      case "rarity":
                        return (
                          utils.getDNARarity(b.dna) - utils.getDNARarity(a.dna)
                        );
                    }
                    return b.id - a.id;
                  })
                  .map((m: MongenEntity) => (
                    <div className="w-1/5 p-1 inline-block" key={m.id}>
                      <MongenThumbnail
                        showInUse={mode !== "view"}
                        mongenInfo={m}
                        onClick={() => {
                          onMongenClick(m);
                        }}
                        active={selected.includes(m.id)}
                      />
                    </div>
                  ))}
              {(type === "plot" || type === "all") &&
                plotInfos
                  .filter((m: PlotEntity) => {
                    if (
                      selectedType === "mongen" ||
                      selectedType === "soulcore"
                    )
                      return false;
                    if (mode === "convert" && dayjs(m.freeze_until) > dayjs())
                      return false;
                    if (rarity !== Rarity.All && m.rarity !== rarity) {
                      return false;
                    }
                    return true;
                  })
                  .sort((a: PlotEntity, b: PlotEntity) => {
                    switch (sortField) {
                      case "id":
                        return b.id - a.id;
                      case "rarity":
                        return b.rarity - a.rarity;
                    }
                    return b.id - a.id;
                  })
                  .map((m: PlotEntity) => (
                    <div className="w-1/5 p-1 inline-block" key={m.id}>
                      <PlotThumbnail
                        showInUse={mode !== "view"}
                        plotInfo={m}
                        onClick={() => {
                          onPlotClick(m);
                        }}
                        active={selected.includes(m.id)}
                      />
                    </div>
                  ))}
            </div>
          </div>
          <div className="text-right w-[300px] flex flex-col justify-between">
            <div>
              <button
                onClick={() => {
                  onTypeClick("all");
                }}
                className={`ui-button_mongen_on interactive text-3xl font-bold ${
                  type !== "all" && "grayscale"
                }`}
              >
                {t("inventory.all_items")}
              </button>
              <button
                onClick={() => {
                  onTypeClick("mongen");
                }}
                className={`ui-button_mongen_on interactive text-3xl font-bold mt-4 ${
                  type !== "mongen" && "grayscale"
                }`}
              >
                {t("global.mongen")}
              </button>
              <button
                onClick={() => {
                  onTypeClick("soulcore");
                }}
                className={`ui-button_mongen_on interactive text-3xl font-bold mt-4 ${
                  type !== "soulcore" && "grayscale"
                }`}
              >
                {t("global.soulcore")}
              </button>
              <button
                onClick={() => {
                  onTypeClick("plot");
                }}
                className={`ui-button_mongen_on interactive text-3xl font-bold mt-4 ${
                  type !== "plot" && "grayscale"
                }`}
              >
                {t("global.plot")}
              </button>
            </div>
          </div>
        </div>
        <div className="w-full absolute bottom-0 left-0 p-4">
          <div className="relative w-full flex justify-between items-end">
            <div>
              <RarityFilter
                rarity={rarity}
                sort={sortField}
                setRarity={(r: Rarity) => {
                  setRarity(r);
                }}
                setSortField={setSortField}
              />
            </div>
            <div className="flex gap-2">
              {tab === "off" && mode === "view" && (
                <button
                  onClick={() => {
                    setMode("convert");
                  }}
                  className={`pointer-events-auto block ui-button_confirm_mutation interactive`}
                >
                  <p className="text-2xl">{t("inventory.convert_to_nft")}</p>
                </button>
              )}
              {tab === "on" && mode === "view" && (
                <button
                  onClick={() => {
                    setMode("import");
                  }}
                  className={`pointer-events-auto block ui-button_confirm_mutation interactive`}
                >
                  <p className="text-2xl">{t("inventory.import_to_game")}</p>
                </button>
              )}

              {mode !== "view" && (
                <div className="flex gap-3">
                  <button
                    className="ui-button_discard_fusion interactive text-3xl font-bold"
                    onClick={() => {
                      setMode("view");
                      setSelected([]);
                      setSelectedType("all");
                    }}
                  >
                    {t("inventory.cancel")}
                  </button>
                  <button
                    onClick={async () => {
                      onConfirmClick();
                    }}
                    className={`ui-button_confirm_mutation interactive text-3xl font-bold ${
                      !selected.length && "grayscale"
                    }`}
                  >
                    {t("inventory.confirm")}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
