import "App.css";
import { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { SSO_META } from "config/game-config";
import Login from "./login";
import Loading from "component/dialog/dialog-loading";
import api from "services/api";
import { PageComponent } from "component/page-component/page-component";

export const WelcomePage = ({
  showLogin,
  setShowLogin,
}: {
  showLogin: boolean;
  setShowLogin: any;
}) => {
  const [page, setPage] = useState("loading");
  const [isLogining, setLogining] = useState(false);

  async function getCustomerInfo() {
    let token = sessionStorage.getItem("token");
    if (token) {
      try {
        await api.post("/click2earn/get-customer-info", {});
        setShowLogin(false);
      } catch (error) {
        setLogining(true);
        sessionStorage.clear();
      }
    } else {
      setLogining(true);
    }
  }

  useEffect(() => {
    setPage("home");
    getCustomerInfo();
  }, []);

  return (
    <div className="w-screen h-screen fixed top-0 left-0 z-10 flex items-center justify-center overflow-hidden">
      <div
        className="bg-white flex items-center justify-center bg-center bg-cover relative"
        style={{
          backgroundImage: `url(splash_celest.jpg)`,
          width: "100vw",
          height: "100vh",
        }}
      >
        <div className="header-menu gap-2">
          <img
            src="/assets/images/slider.png"
            alt="logo"
            className="w-[97px] mt-3 ml-3"
          />
        </div>
        <div className="header-menu gap-2">
          <div className="">
            <PageComponent SSO_META={SSO_META()} />
          </div>
        </div>
        {isLogining && (
          <div
            className="mx-auto text-center"
            style={{
              zoom: 0.88,
            }}
          >
            {page === "loading" && <Loading />}
            {page === "home" && (
              <Login showLogin={showLogin} setShowLogin={setShowLogin} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};
