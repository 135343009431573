import PlotEntity from "../../config/plot.entity";
import Typo from "../typo";
import PlotImage from "./plot-image";

export function FusionPlotThumbnail({
  plotInfo,
  active = false,
  onClick,
}: {
  onClick?: Function;
  plotInfo: PlotEntity;
  active?: boolean;
}) {
  return (
    <div
      className="relative p-1 group interactive"
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      {active && (
        <div className="w-full h-full ui-border_hl_mongen absolute top-[5px] left-[5px] z-10"></div>
      )}
      <div className="flex ui-blank_fusion_mongen gap-2 p-2 relative">
        <div className="w-full p-5">
          <PlotImage plotInfo={plotInfo} />
        </div>
        <div className="absolute top-0 left-0 w-full">
          <div className="flex w-full justify-between px-2 mt-1">
            <p>
              <Typo type="plottype" value={plotInfo.plot_type} />
            </p>
            <Typo type="rarity" value={plotInfo.rarity} />
          </div>
        </div>
        <div className="absolute bottom-0 left-0 w-full">
          <div className="flex w-full justify-between px-2 mt-1">
            <p>#{plotInfo.id}</p>
            <p>Lv. {plotInfo.level}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
