import { MapPlotType, PlotSize, Rarity } from "../../config/interface";
import styled, { keyframes } from "styled-components";
import { flash, pulse } from "react-animations";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import PlotEntity from "../../config/plot.entity";
import utils from "../../services/utils";
const bounceAnimation = keyframes`${pulse}`;
const BouncyDiv = styled.div`
  animation: 2s ${bounceAnimation} infinite;
`;
const animName = keyframes`${flash}`;

const Smoke1 = styled.div`
  animation: 5s ${animName} infinite;
`;
const Smoke2 = styled.div`
  animation: 5s ${animName} infinite;
  animation-delay: 1s;
`;
const Smoke3 = styled.div`
  animation: 5s ${animName} infinite;
  animation-delay: 2s;
`;
const Smoke4 = styled.div`
  animation: 5s ${animName} infinite;
  animation-delay: 3s;
`;
const Smoke5 = styled.div`
  animation: 5s ${animName} infinite;
  animation-delay: 4s;
`;
export default function PlotWorkshop({
  rarity,
  onClick,
}: {
  rarity: Rarity;
  onClick?: Function;
}) {
  const [size, setSize] = useState<PlotSize>(PlotSize.Medium);
  const { plotInfos, mongenInfos, mapItems } = useSelector(
    (state: RootState) => state.inventorySlice
  );
  useEffect(() => {
    let total = plotInfos.filter((m) => m.is_click2earn).length;
    let workshop = mapItems[MapPlotType.Workshop]?.length || 0;
    setSize(utils.calculateSize((workshop / total) * 100));
  }, [plotInfos, mongenInfos]);
  function renderContent() {
    switch (rarity) {
      case Rarity.Common:
        return PlotCommon(size);
      case Rarity.Uncommon:
        return PlotUncommon(size);
      case Rarity.Rare:
        return PlotRare(size);
      case Rarity.Epic:
        return PlotEpic(size);
      case Rarity.Legendary:
        return PlotLegend(size);
    }
  }
  return (
    <button
      className="interactive w-[400px] h-[250px] -top-16 -left-16 relative pointer-events-auto"
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      {renderContent()}
    </button>
  );
}
function Smoke() {
  const [index, setIndex] = useState<number>(0);
  useEffect(() => {
    let time = setInterval(() => {
      setIndex((index: number) => {
        if (index > 8) {
          index = 0;
        } else {
          index++;
        }
        return index;
      });
    }, 300);
    return () => {
      clearInterval(time);
    };
  }, []);
  return (
    <>
      <div
        className={`anim-smoke absolute pointer-events-none bottom-0 left-0 duration-100 ${
          index === 0 ? "opacity-100" : "opacity-0"
        }`}
      ></div>
      <div
        className={`anim-smoke2 absolute pointer-events-none bottom-0 left-0 duration-100 ${
          index === 1 ? "opacity-100" : "opacity-0"
        }`}
      ></div>
      <div
        className={`anim-smoke3 absolute pointer-events-none bottom-0 left-0 duration-100 ${
          index === 2 ? "opacity-100" : "opacity-0"
        }`}
      ></div>
      <div
        className={`anim-smoke4 absolute pointer-events-none bottom-0 left-0 duration-100 ${
          index === 3 ? "opacity-100" : "opacity-0"
        }`}
      ></div>
      <div
        className={`anim-smoke5 absolute pointer-events-none bottom-0 left-0 duration-100 ${
          index === 4 ? "opacity-100" : "opacity-0"
        }`}
      ></div>
    </>
  );
}
function PlotCommon(size: PlotSize) {
  switch (size) {
    case PlotSize.Small:
      return (
        <div className="map-island_workshop_CO_1 absolute left-[35px] bottom-0 pointer-events-none">
          <div className="bottom-[223px] left-[87px] absolute">
            <Smoke />
          </div>
        </div>
      );
    case PlotSize.Medium:
      return (
        <div className="map-island_workshop_CO_2 absolute left-0 bottom-[-119px] pointer-events-none">
          <div className="bottom-[337px] left-[100px] absolute">
            <Smoke />
          </div>
        </div>
      );
    case PlotSize.Large:
      return (
        <div className="map-island_workshop_CO_3 absolute left-[-92px] bottom-[-66px] pointer-events-none">
          <div className="bottom-[300px] left-[239px] absolute">
            <Smoke />
          </div>
        </div>
      );
  }
}
function PlotUncommon(size: PlotSize) {
  switch (size) {
    case PlotSize.Small:
      return (
        <div className="map-island_workshop_UC_1 absolute left-[35px] bottom-0 pointer-events-none">
          <div className="bottom-[260px] left-[166px] absolute">
            <Smoke />
          </div>
        </div>
      );
    case PlotSize.Medium:
      return (
        <div className="map-island_workshop_UC_2 absolute left-0 bottom-[-119px] pointer-events-none">
          <div className="bottom-[355px] left-[166px] absolute">
            <Smoke />
          </div>
        </div>
      );
    case PlotSize.Large:
      return (
        <div className="map-island_workshop_UC_3 absolute left-[-92px] bottom-[-66px] pointer-events-none">
          <div className="bottom-[345px] left-[233px] absolute">
            <Smoke />
          </div>
        </div>
      );
  }
}

function PlotRare(size: PlotSize) {
  switch (size) {
    case PlotSize.Small:
      return (
        <div className="map-island_workshop_RA_1 absolute left-[35px] bottom-0 pointer-events-none">
          <div className="bottom-[258px] left-[168px] absolute">
            <Smoke />
          </div>
        </div>
      );
    case PlotSize.Medium:
      return (
        <div className="map-island_workshop_RA_2 absolute left-0 bottom-[-119px] pointer-events-none">
          <div className="bottom-[352px] left-[170px] absolute">
            <Smoke />
          </div>
        </div>
      );
    case PlotSize.Large:
      return (
        <div className="map-island_workshop_RA_3 absolute left-[-92px] bottom-[-66px] pointer-events-none">
          <div className="bottom-[321px] left-[273px] absolute">
            <Smoke />
          </div>
        </div>
      );
  }
}

function PlotEpic(size: PlotSize) {
  switch (size) {
    case PlotSize.Small:
      return (
        <div className="map-island_workshop_EP_1 absolute left-[35px] bottom-0 pointer-events-none">
          <div className="bottom-[261px] left-[220px] absolute">
            <Smoke />
          </div>
        </div>
      );
    case PlotSize.Medium:
      return (
        <div className="map-island_workshop_EP_2 absolute left-0 bottom-[-119px] pointer-events-none">
          <div className="bottom-[350px] left-[220px] absolute">
            <Smoke />
          </div>
        </div>
      );
    case PlotSize.Large:
      return (
        <div className="map-island_workshop_EP_3 absolute left-[-92px] bottom-[-66px] pointer-events-none">
          <div className="bottom-[355px] left-[272px] absolute">
            <Smoke />
          </div>
        </div>
      );
  }
}

function PlotLegend(size: PlotSize) {
  switch (size) {
    case PlotSize.Small:
      return (
        <div className="map-island_workshop_LE_1 absolute left-[35px] bottom-0 pointer-events-none">
          <div className="bottom-[257px] left-[230px] absolute">
            <Smoke />
          </div>
        </div>
      );
    case PlotSize.Medium:
      return (
        <div className="map-island_workshop_LE_2 absolute left-0 bottom-[-119px] pointer-events-none">
          <div className="bottom-[350px] left-[230px] absolute">
            <Smoke />
          </div>
        </div>
      );
    case PlotSize.Large:
      return (
        <div className="map-island_workshop_LE_3 absolute left-[-92px] bottom-[-66px] pointer-events-none">
          <div className="bottom-[331px] left-[272px] absolute">
            <Smoke />
          </div>
        </div>
      );
  }
}
