import {
  MapPlotType,
  PlotSize,
  PlotType,
  Rarity,
} from "../../config/interface";
import PlotHabitat from "./plot-habitat";
import PlotLandcore from "./plot-landcore";
import PlotWorkshop from "./plot-workshop";
import PlotStorage from "./plot-storage";
import PlotDefense from "./plot-defense";
export default function PlotBase({
  plot_type,
  rarity,
  size,
  onClick,
}: {
  size: PlotSize;
  plot_type: MapPlotType;
  rarity: Rarity;
  onClick?: Function;
}) {
  function render() {
    switch (plot_type) {
      case MapPlotType.Landcore:
        return <PlotLandcore rarity={rarity} onClick={onClick} />;
      case MapPlotType.Habitat:
        return <PlotHabitat rarity={rarity} onClick={onClick} />;
      case MapPlotType.Workshop:
        return <PlotWorkshop rarity={rarity} onClick={onClick} />;
      case MapPlotType.Storage:
        return <PlotStorage rarity={rarity} onClick={onClick} />;
      case MapPlotType.Defense:
        return <PlotDefense rarity={rarity} onClick={onClick} />;
    }
  }
  return <>{render()}</>;
}
