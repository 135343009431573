import {
  MapPlotType,
  NFTType,
  PlotSize,
  PlotType,
  Rarity,
} from "../../config/interface";
import styled, { keyframes } from "styled-components";
import { flash, pulse } from "react-animations";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import utils from "services/utils";
import PlotEntity from "config/plot.entity";
import gameConfig from "config/game-config";
const animName = keyframes`${flash}`;
const AnimDiv = styled.div`
  animation: 4s ${animName} infinite;
`;
const Waterfall1 = styled.div`
  animation: 3s ${animName} infinite;
`;
const Waterfall2 = styled.div`
  animation: 3s ${animName} infinite;
  animation-delay: 0.5s;
`;
const Waterfall3 = styled.div`
  animation: 3s ${animName} infinite;
  animation-delay: 1s;
`;
export default function PlotHabitat({
  rarity,
  onClick,
}: {
  rarity: Rarity;
  onClick?: Function;
}) {
  const [size, setSize] = useState<PlotSize>(PlotSize.Medium);
  const { plotInfos, mongenInfos, mapItems, mongenIds } = useSelector(
    (state: RootState) => state.inventorySlice
  );
  useEffect(() => {
    let landCore = plotInfos.find(
      (p: PlotEntity) => p.is_click2earn && p.plot_type === PlotType.LandCore
    );
    if (!landCore) {
      return;
    }
    let config = gameConfig.getItemInfo(NFTType.Plot, PlotType.Pasture);
    if (!config) {
      return;
    }
    let total =
      mapItems[MapPlotType.Habitat].length *
      config.limit_mongen[config.limit_mongen.length - 1];
    let mongen = mongenIds.length;
    setSize(utils.calculateSize((mongen / total) * 100));
  }, [plotInfos, mongenInfos]);
  function renderContent() {
    switch (rarity) {
      case Rarity.Common:
        return PlotLandcoreCommon(size);
      case Rarity.Uncommon:
        return PlotLandcoreUncommon(size);
      case Rarity.Rare:
        return PlotLandcoreRare(size);
      case Rarity.Epic:
        return PlotLandcoreEpic(size);
      case Rarity.Legendary:
        return PlotLandcoreLegend(size);
    }
  }
  return (
    <div>
      <button
        className="interactive w-[500px] h-[360px] relative"
        onClick={() => {
          if (onClick) {
            onClick();
          }
        }}
      >
        {renderContent()}
        <Waterfall1>
          <div className="anim-waterfall1 absolute pointer-events-none left-[325px] bottom-[-51px]"></div>
        </Waterfall1>
        <Waterfall2>
          <div className="anim-waterfall2 absolute pointer-events-none left-[325px] bottom-[-51px]"></div>
        </Waterfall2>
        <Waterfall3>
          <div className="anim-waterfall3 absolute pointer-events-none left-[325px] bottom-[-51px]"></div>
        </Waterfall3>

        <Waterfall1>
          <div className="anim-foam1 absolute pointer-events-none left-[276px] bottom-[-87px]"></div>
        </Waterfall1>
        <Waterfall2>
          <div className="anim-foam2 absolute pointer-events-none left-[276px] bottom-[-87px]"></div>
        </Waterfall2>
        <Waterfall3>
          <div className="anim-foam3 absolute pointer-events-none left-[276px] bottom-[-87px]"></div>
        </Waterfall3>
        <div className="absolute bottom-[97px] left-[117px]">
          {size >= 0 && (
            <div className="map-animal_1 bottom-0 left-0 absolute"></div>
          )}
          {size >= 1 && (
            <div className="map-animal_2 bottom-0 left-0 absolute"></div>
          )}
          {size >= 2 && (
            <div className="map-animal_3 bottom-0 left-0 absolute"></div>
          )}
        </div>
      </button>
    </div>
  );
}
function PlotLandcoreCommon(size: PlotSize) {
  return (
    <div className="map-island_habitat_CO absolute left-0 bottom-[-73px] pointer-events-none"></div>
  );
}
function PlotLandcoreUncommon(size: PlotSize) {
  return (
    <div className="map-island_habitat_UC absolute left-0 bottom-[-73px] pointer-events-none"></div>
  );
}

function PlotLandcoreRare(size: PlotSize) {
  return (
    <div className="map-island_habitat_RA absolute left-0 bottom-[-73px] pointer-events-none"></div>
  );
}

function PlotLandcoreEpic(size: PlotSize) {
  return (
    <div className="map-island_habitat_EP absolute left-0 bottom-[-73px] pointer-events-none">
      <Waterfall1>
        <div className="anim-luminous_1_1 z-10 absolute left-[209px] bottom-[396px]"></div>
      </Waterfall1>
      <Waterfall2>
        <div className="anim-luminous_1_2 z-10 absolute left-[209px] bottom-[396px]"></div>
      </Waterfall2>
      <Waterfall3>
        <div className="anim-luminous_1_3 z-10 absolute left-[209px] bottom-[396px]"></div>
      </Waterfall3>
    </div>
  );
}

function PlotLandcoreLegend(size: PlotSize) {
  return (
    <div className="map-island_habitat_LE absolute left-0 bottom-[-73px] pointer-events-none">
      <Waterfall1>
        <div className="anim-luminous_2_1 z-10 absolute left-[84px] bottom-[311px]"></div>
      </Waterfall1>
      <Waterfall2>
        <div className="anim-luminous_2_2 z-10 absolute left-[84px] bottom-[311px]"></div>
      </Waterfall2>
      <Waterfall3>
        <div className="anim-luminous_2_3 z-10 absolute left-[84px] bottom-[311px]"></div>
      </Waterfall3>
    </div>
  );
}
