import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import _, { mergeWith } from "lodash";
import {
  MapPlotType,
  NFTType,
  PlotSize,
  PlotType,
  Rarity,
} from "../config/interface";
import MongenEntity from "../config/mongen.entity";
import PlotEntity from "../config/plot.entity";
import api from "../services/api";
import gameConfig from "../config/game-config";
interface InventoryData {
  backup?: any;
  mongenInfos: MongenEntity[];
  plotInfos: PlotEntity[];
  mapItems: {
    [key in MapPlotType]?: { rarity: Rarity; size: PlotSize; length: number };
  };
  plotIds: number[];
  mongenIds: number[];
  totalMongen?: number;
  requireMongen?: number;
  maxMongen?: number;
  loadingItems: boolean;
  getMessage: boolean;
  importedItems: {
    mongenInfos: MongenEntity[];
    plotInfos: PlotEntity[];
  };
}
const initialState: InventoryData = {
  mongenInfos: [],
  plotInfos: [],
  mapItems: {},
  backup: null,
  totalMongen: 0,
  requireMongen: 0,
  maxMongen: 0,
  mongenIds: [],
  plotIds: [],
  loadingItems: true,
  getMessage: false,
  importedItems: {
    mongenInfos: [],
    plotInfos: [],
  },
};
export const getItems = createAsyncThunk("inventory/get_items", () => {
  return api.getItems();
});
export const inventorySlice = createSlice({
  name: "dialog",
  initialState,
  reducers: {
    addItems: (state, action) => {
      let { mongenInfos, plotInfos } = action.payload;
      //check duplicate
      let mongenIds = state.mongenInfos.map((i) => i.id);
      let plotIds = state.plotInfos.map((i) => i.id);
      state.mongenInfos = state.mongenInfos.concat(
        mongenInfos.filter((i: any) => !mongenIds.includes(i.id))
      );
      state.plotInfos = state.plotInfos.concat(
        plotInfos.filter((i: any) => !plotIds.includes(i.id))
      );
    },
    destroyItems: (state, action) => {
      let { mongenIds, plotIds } = action.payload;
      state.mongenInfos = state.mongenInfos.filter(
        (i) => !mongenIds.includes(i.id)
      );
      state.plotInfos = state.plotInfos.filter((i) => !plotIds.includes(i.id));
    },
    removeItems: (state, action) => {
      let { mongenIds, plotIds } = action.payload;
      state.mongenInfos.forEach((m) => {
        if (mongenIds.includes(m.id)) {
          m.is_click2earn = false;
        }
      });
      state.plotInfos.forEach((p) => {
        if (plotIds.includes(p.id)) {
          p.is_click2earn = false;
        }
      });
    },
    addImportedItems: (state, action) => {
      let { mongenInfo, plotInfo } = action.payload;
      if (mongenInfo) {
        state.importedItems.mongenInfos.push(mongenInfo);
        state.mongenInfos.push(mongenInfo);
      }
      if (plotInfo) {
        state.importedItems.plotInfos.push(plotInfo);
        state.plotInfos.push(plotInfo);
      }
    },
    resetImportedItems: (state, action) => {
      state.importedItems = { mongenInfos: [], plotInfos: [] };
    },
    setGetMessage: (state, action) => {
      state.getMessage = action.payload;
    },
    createBackup: (state) => {
      state.backup = {
        mongenInfos: _.cloneDeep(state.mongenInfos),
        plotInfos: _.cloneDeep(state.plotInfos),
      };
    },
    restoreBackup: (state) => {
      state.mongenInfos = state.backup.mongenInfos;
      state.plotInfos = state.backup.plotInfos;
      Object.assign(state, calculateMap(state.mongenInfos, state.plotInfos));
    },
    activeMongen: (state, action) => {
      const { id } = action.payload;
      state.mongenInfos.forEach((m: MongenEntity) => {
        if (m.id === id) {
          m.is_click2earn = !m.is_click2earn;
        }
      });
      Object.assign(state, calculateMap(state.mongenInfos, state.plotInfos));
    },
    activePlot: (state, action) => {
      const { id, plotType } = action.payload;
      if (state.plotIds?.includes(id)) {
        state.plotInfos.forEach((p: PlotEntity) => {
          if (p.id === id) {
            p.is_click2earn = false;
          }
        });
      } else {
        state.plotInfos.forEach((p: PlotEntity) => {
          switch (plotType) {
            case PlotType.LandCore:
            case PlotType.Storage:
            case PlotType.Tree:
              if (p.plot_type !== plotType) {
                return;
              }
              if (p.id === action.payload.id) {
                p.is_click2earn = true;
              } else {
                if (p.is_click2earn) {
                  p.is_click2earn = false;
                }
              }
              break;
            default:
              if (p.id === id) {
                p.is_click2earn = true;
              }
              break;
          }
        });
      }

      Object.assign(state, calculateMap(state.mongenInfos, state.plotInfos));
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getItems.fulfilled, (state, action: any) => {
      state.mongenInfos = action.payload.mongenInfos;
      state.plotInfos = action.payload.plotInfos;
      state.backup = {
        mongenInfos: action.payload.mongenInfos,
        plotInfos: action.payload.plotInfos,
      };
      state.loadingItems = false;
      Object.assign(state, calculateMap(state.mongenInfos, state.plotInfos));
    });
  },
});
function calculateMap(
  mongenInfos: MongenEntity[],
  plotInfos: PlotEntity[]
): {
  mongenIds: number[];
  plotIds: number[];
  mapItems: { [key in MapPlotType]?: { rarity: Rarity; size: PlotSize } };
} {
  let rs: {
    plotIds: number[];
    mongenIds: number[];
    mapItems: {
      [key in MapPlotType]?: { rarity: Rarity; size: PlotSize; length: number };
    };
    totalMongen: number;
    requireMongen: number;
  } = {
    mapItems: {},
    plotIds: [],
    mongenIds: [],
    totalMongen: 0,
    requireMongen: 0,
  };
  let landCore: any = null;
  let storages: PlotEntity[] = [];
  let habitats: PlotEntity[] = [];
  let workshops: PlotEntity[] = [];
  let defense: PlotEntity[] = [];
  plotInfos.forEach((p: PlotEntity) => {
    if (!p.is_click2earn) {
      return;
    }
    let plotConfig = gameConfig.getItemInfo(NFTType.Plot, p.plot_type);
    if (!plotConfig) {
      return;
    }
    switch (p.plot_type) {
      case PlotType.LandCore:
        landCore = p;
        break;
      case PlotType.Storage:
        storages.push(p);
        break;
      case PlotType.Pasture:
        habitats.push(p);
        rs.totalMongen += plotConfig.limit_mongen[p.rarity];
        break;
      case PlotType.Production:
      case PlotType.Breeding:
      case PlotType.Hatching:
        rs.requireMongen += plotConfig.required_mongen[p.rarity];
        workshops.push(p);
        break;
      default:
        rs.requireMongen += plotConfig.required_mongen[p.rarity];
        defense.push(p);
        break;
    }
  });
  if (!landCore) {
    return rs;
  }
  rs.mapItems[MapPlotType.Landcore] = {
    rarity: landCore.rarity,
    size: PlotSize.Small,
    length: 1,
  };
  if (storages.length) {
    rs.mapItems[MapPlotType.Storage] = {
      rarity: landCore.rarity,
      size: PlotSize.Small,
      length: storages.length,
    };
  }
  if (habitats.length) {
    rs.mapItems[MapPlotType.Habitat] = {
      rarity: landCore.rarity,
      size: PlotSize.Small,
      length: habitats.length,
    };
  }
  if (workshops.length) {
    rs.mapItems[MapPlotType.Workshop] = {
      rarity: landCore.rarity,
      size: PlotSize.Small,
      length: workshops.length,
    };
  }
  if (defense.length) {
    rs.mapItems[MapPlotType.Defense] = {
      rarity: landCore.rarity,
      size: PlotSize.Small,
      length: defense.length,
    };
  }
  plotInfos.forEach((p: PlotEntity) => {
    if (!p.is_click2earn) {
      return;
    }
    rs.plotIds.push(p.id);
  });
  mongenInfos.forEach((p: MongenEntity) => {
    if (!p.is_click2earn) {
      return;
    }
    rs.mongenIds.push(p.id);
  });
  return rs;
}

// Action creators are generated for each case reducer function
export const {
  addImportedItems,
  resetImportedItems,
  activePlot,
  createBackup,
  restoreBackup,
  activeMongen,
  setGetMessage,
  removeItems,
  destroyItems,
  addItems,
} = inventorySlice.actions;
export default inventorySlice.reducer;
