export enum PlotType {
  LandCore,
  Pasture,
  Breeding,
  Hatching,
  Production,
  Storage,
  Camp,
  Tower,
  Bombard,
  Trap,
  Barracks,
  Decor,
  All,
  Tree,
}
export enum Rarity {
  Common,
  Uncommon,
  Rare,
  Epic,
  Legendary,
  All,
}
export enum PlotSize {
  Small,
  Medium,
  Large,
}
export enum MapPlotType {
  Landcore,
  Storage,
  Tree,
  Defense,
  Habitat,
  Workshop,
}

export enum ItemType {
  Plot,
  Mongen,
}
export enum MongenRace {
  Beast,
  Tectos,
  Mystic,
  Celest,
  Chaos,
  All,
}

export enum TrapType {
  Default,
  Poison,
  Slowed,
  Paralyzed,
  Confused,
  Bleeding,
  Silence,
  Burned,
  MG,
  STD,
  AGR,
}

export enum BombardType {
  Default,
  RNG,
  ENG,
}

export enum BarrackType {
  Default,
  Food,
}

export enum TowerType {
  Default,
  RNG,
  CD,
}

export enum CampType {
  Default,
  HP,
  MG,
  STD,
  AGR,
}
export enum SetupStage {
  PickLandcore,
  AttachStorage,
  AttachHabitat,
  AttachMongen,
  AttachPlot,
}

export enum NFTType {
  Mongen,
  SoulCore,
  Land,
  Plot,
  EvolutionItem,
  NormalStone,
  RerollStone,
  EliteStone,
  NormalRuneStone,
  RerollRuneStone,
  EliteRuneStone,
  TicketCO,
  TicketUC,
  TicketRA,
  TicketEP,
  TicketLE,
  TicketGE,
  TicketGL,
  Pigment,
  Box,
  ElectiveStoneCO,
  ElectiveRuneStoneCO,
  ElectiveStoneUC,
  ElectiveRuneStoneUC,
  ElectiveStoneRA,
  ElectiveRuneStoneRA,
  ElectiveStoneEP,
  ElectiveRuneStoneEP,
  ElectiveStoneLE,
  ElectiveRuneStoneLE,
  Elixir,
  MutatedGen,
  Rune,
}
export enum BodyPart {
  Form,
  Head,
  Eyes,
  Horns,
  Tail,
  Back,
  FrontLeg,
  BackLeg,
  Mouth,
  Aura,
}
export enum MongenStatType {
  Health,
  Sturdiness,
  Magic,
  Aggresion,
}
export enum LoginType {
  BSC,
  Terra,
  Email,
  Avalanche,
  Facebook,
  Google,
  GoogleMobile,
  SavedToken,
  Okex,
  Apple,
  Guest,
  Aura,
  Twitter,
  Farmer,
}
export enum Chain {
  BSC,
  Terra,
  Avalanche,
  Okex,
  Aura
}

export type SSO_TYPE = {
  GuideLinkLegend: string;
  DAOLink: string;
  GuideLink: string;
  DaoGuildLink: string;
  LiteLink: string;
  GameLink: string;
  SupportLink: string;
  TerraChainId: string;
  BattleLink: string;
  PortalLink: string;
  BridgeLink: string;
  GuildLink: string;
  EventLink: string;
  MarketLink: string;
  DocsLink: string;
  MonstvLink: string;
  MonsterraverseLink: string;
  LandingPage: string;
  contract: any;
  network: any;
  GOOGLE_CLIENT_ID: string;
  FACEBOOK_APP_ID: string;
  CAPTCHA_CLIENT_ID: string;
  S3Host: string;
  price: {
    BNB: string;
    AVAX: string;
    LUNA: string;
    UST: string;
    OKT: string;
  };
  AuraChainInfo: any;
};

export enum ContractType {
  MSTR = "MSTR",
  Admin = "ADMIN",
  Skill = "SKILL",
  MAG = "MAG",
  Mongen = "MONGEN",
  SoulCore = "SOULCORE",
  Land = "LAND",
  Plot = "PLOT",
  EvolutionItem = "EVOLUTIONITEM",
  NormalStone = "NORMALSTONE",
  RerollStone = "REROLLSTONE",
  EliteStone = "ELITESTONE",
  NormalRuneStone = "NORMALRUNESTONE",
  RerollRuneStone = "REROLLRUNESTONE",
  EliteRuneStone = "ELITERUNESTONE",
  TicketCO = "TICKETCO",
  TicketUC = "TICKETUC",
  TicketRA = "TICKETRA",
  TicketEP = "TICKETEP",
  TicketLE = "TICKETLE",
  TicketGE = "TICKETGE",
  TicketGL = "TICKETGL",
  ticket_co = "TICKETCO",
  ticket_uc = "TICKETUC",
  ticket_ra = "TICKETRA",
  ticket_ep = "TICKETEP",
  ticket_le = "TICKETLE",
  ticket_ge = "TICKETGE",
  ticket_gl = "TICKETGL",
  STAKING = "STAKING",
  ConvertBox = "CONVERTBOX",
  Pigment = "PIGMENT",
  Box = "BOX",
  SkipQuest = "SKIPQUEST",
  ElectiveStoneCO = "ELECTIVESTONECO",
  ElectiveRuneStoneCO = "ELECTIVERUNESTONECO",
  ElectiveStoneUC = "ELECTIVESTONEUC",
  ElectiveRuneStoneUC = "ELECTIVERUNESTONEUC",
  ElectiveStoneRA = "ELECTIVESTONERA",
  ElectiveRuneStoneRA = "ELECTIVERUNESTONERA",
  ElectiveStoneEP = "ELECTIVESTONEEP",
  ElectiveRuneStoneEP = "ELECTIVERUNESTONEEP",
  ElectiveStoneLE = "ELECTIVESTONELE",
  ElectiveRuneStoneLE = "ELECTIVERUNESTONELE",
  BnbGlory = "BNBGLORY",
  Elixir = "ELIXIR",
  Fusion = "FUSION",
  MutatedGen = "MUTATED_GEN",
  Rune = "RUNE",
}

export const NFTTypeMap = {
  [NFTType.Mongen]: 0,
  [NFTType.SoulCore]: 1,
  [NFTType.Land]: 2,
  [NFTType.Plot]: 3,
  [NFTType.Box]: 4,
  [NFTType.Rune]: 5,
};

export const ContractTypeMap = {
  [ContractType.Mongen]: 0,
  [ContractType.SoulCore]: 1,
  [ContractType.Land]: 2,
  [ContractType.Plot]: 3,
  [ContractType.Box]: 4,
  [ContractType.Rune]: 5,
};
export enum GameEventType {
  MailCreate,
  UserNFTToItem,
  RevertTransaction,
  OpenRatePopup,
  RemoveItem,
  AddItem,
  BattlefrontDefendLog,
  BattlefrontAttackLog,
  DailyQuestUpdated,
  ClaimReward,
  UpdateData,
  UpdateCustomerInfo,
}
export enum MongenState {
  Egg,
  Idle,
  Breeding,
  Working,
  Traning,
  Battle,
}


export enum KEPLR_ERRORS {
  Success = 'OK',
  Failed = 'FAILED',
  NoChainInfo = 'THERE IS NO CHAIN INFO FOR',
  SameChain = 'SAME CHAIN IS ALREADY REGISTERED',
  NotRegistered = 'CHAIN IS NOT REGISTERED',
  RequestRejected = 'REQUEST REJECTED',
  NotInstall = 'NOT INSTALL',
  NOT_EXIST = "KEY DOESN'T EXIST",
}

export enum WALLET_PROVIDER {
  KEPLR = 'KEPLR',
  COIN98 = 'COIN98',
}

export enum TRANSACTION_TYPE_ENUM {
  IBCTransfer = '/ibc.applications.transfer.v1.MsgTransfer',
  IBCReceived = '/ibc.core.channel.v1.MsgRecvPacket',
  IBCAcknowledgement = '/ibc.core.channel.v1.MsgAcknowledgement',
  IBCUpdateClient = '/ibc.core.client.v1.MsgUpdateClient',
  IBCTimeout = '/ibc.core.channel.v1.MsgTimeout',
  IBCChannelOpenInit = '/ibc.core.channel.v1.MsgChannelOpenInit',
  IBCConnectionOpenInit = '/ibc.core.connection.v1.MsgConnectionOpenInit',
  IBCCreateClient = '/ibc.core.client.v1.MsgCreateClient',
  IBCChannelOpenAck = '/ibc.core.channel.v1.MsgChannelOpenAck',
  Send = '/cosmos.bank.v1beta1.MsgSend',
  MultiSend = '/cosmos.bank.v1beta1.MsgMultiSend',
  Delegate = '/cosmos.staking.v1beta1.MsgDelegate',
  Undelegate = '/cosmos.staking.v1beta1.MsgUndelegate',
  Redelegate = '/cosmos.staking.v1beta1.MsgBeginRedelegate',
  GetReward = '/cosmos.distribution.v1beta1.MsgWithdrawDelegatorReward',
  SwapWithinBatch = '/tendermint.liquidity.v1beta1.MsgSwapWithinBatch',
  DepositWithinBatch = '/tendermint.liquidity.v1beta1.MsgDepositWithinBatch',
  EditValidator = '/cosmos.staking.v1beta1.MsgEditValidator',
  CreateValidator = '/cosmos.staking.v1beta1.MsgCreateValidator',
  Unjail = '/cosmos.slashing.v1beta1.MsgUnjail',
  StoreCode = '/cosmwasm.wasm.v1.MsgStoreCode',
  InstantiateContract = '/cosmwasm.wasm.v1.MsgInstantiateContract',
  InstantiateContract2 = '/cosmwasm.wasm.v1.MsgInstantiateContract2',
  ExecuteContract = '/cosmwasm.wasm.v1.MsgExecuteContract',
  ModifyWithdrawAddress = '/cosmos.distribution.v1beta1.MsgSetWithdrawAddress',
  JoinPool = '/osmosis.gamm.v1beta1.MsgJoinPool',
  LockTokens = '/osmosis.lockup.MsgLockTokens',
  JoinSwapExternAmountIn = '/osmosis.gamm.v1beta1.MsgJoinSwapExternAmountIn',
  SwapExactAmountIn = '/osmosis.gamm.v1beta1.MsgSwapExactAmountIn',
  BeginUnlocking = '/osmosis.lockup.MsgBeginUnlocking',
  Vote = '/cosmos.gov.v1beta1.MsgVote',
  Vesting = '/cosmos.vesting.v1beta1.MsgCreateVestingAccount',
  Deposit = '/cosmos.gov.v1beta1.MsgDeposit',
  SubmitProposalTx = '/cosmos.gov.v1beta1.MsgSubmitProposal',
  GetRewardCommission = '/cosmos.distribution.v1beta1.MsgWithdrawValidatorCommission',
  PeriodicVestingAccount = '/cosmos.vesting.v1beta1.MsgCreatePeriodicVestingAccount',
  BasicAllowance = '/cosmos.feegrant.v1beta1.BasicAllowance',
  PeriodicAllowance = '/cosmos.feegrant.v1beta1.PeriodicAllowance',
  MsgGrantAllowance = '/cosmos.feegrant.v1beta1.MsgGrantAllowance',
  MsgRevokeAllowance = '/cosmos.feegrant.v1beta1.MsgRevokeAllowance',
  AllowedMsgAllowance = '/cosmos.feegrant.v1beta1.AllowedMsgAllowance',
  AllowedContractAllowance = '/cosmos.feegrant.v1beta1.AllowedContractAllowance',
  GrantAuthz = '/cosmos.authz.v1beta1.MsgGrant',
  ExecuteAuthz = '/cosmos.authz.v1beta1.MsgExec',
  RevokeAuthz = '/cosmos.authz.v1beta1.MsgRevoke',
  MsgMigrateContract = '/cosmwasm.wasm.v1.MsgMigrateContract',
  AuraExecuteContract = '/auranw.aura.wasm.MsgExecuteContract',
  Fail = 'FAILED',
}