import AuthEntity from "config/auth.entity";
import { Chain, LoginType } from "config/interface";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Dropdown } from "semantic-ui-react";
import { CHAIN_MAP } from "services/api";
import utils from "services/utils";
import { RootState } from "store";

export default function SelectChain({
  onAuthChange,
}: {
  onAuthChange: Function;
}) {
  const [auth, setAuth] = useState<AuthEntity>(null);
  const { authInfos } = useSelector((state: RootState) => state.userSlice);
  const { t } = useTranslation();
  useEffect(() => {
    let tmp = _.cloneDeep(authInfos);
    let a = tmp
      .sort((a: AuthEntity, b: AuthEntity) => a.type - b.type)
      .find((a: AuthEntity) =>
        [LoginType.BSC, LoginType.Avalanche, LoginType.Okex].includes(a.type)
      );
    if (a) {
      setAuth(a);
    }
  }, [authInfos]);
  useEffect(() => {
    if (onAuthChange) {
      onAuthChange(auth);
    }
  }, [auth]);
  return (
    <div className="text-xl custom-dropdown relative border-4 rounded-md px-2 w-[260px]">
      {auth ? (
        <div className="flex gap-2 items-center current absolute pointer-events-none top-0 left-0 px-2 w-full h-[99px] overflow-hidden justify-between">
          <img
            alt="chain"
            className="w-10 h-10 object-contain"
            src={`chain/${Chain[CHAIN_MAP[auth.type]].toLowerCase()}.webp`}
          />
          <div className="text-[20px]">
            <p>{Chain[CHAIN_MAP[auth.type]]}</p>
            <p>{utils.blurAddress(auth.identity)}</p>
          </div>
          <img
            src="/assets/images/dropdown-icon.png"
            alt="dropdown"
            className="h-2"
          />
        </div>
      ) : (
        <a
          className="absolute top-5 left-7"
          target="_blank"
          href="https://dao.monsterra.io"
        >
          <p className="text-textnumberyellow">{t("chain.attach_chain")}</p>
        </a>
      )}
      <Dropdown className="w-[253px] h-[99px]" floating>
        <Dropdown.Menu className="h-[280px] overflow-y-scroll translate-x-[-15px]">
          {authInfos.map((a: any) => {
            // @ts-ignore
            let chainType = Chain[CHAIN_MAP[a.type]];
            if (chainType)
              return (
                <Dropdown.Item
                  key={a.id}
                  onClick={() => {
                    setAuth(a);
                  }}
                >
                  <div className="flex gap-2 items-center">
                    <img
                      alt="chain"
                      className="w-10 h-10"
                      src={`chain/${chainType.toLowerCase()}.webp`}
                    />
                    <div>
                      {/* @ts-ignore */}
                      <p>{Chain[CHAIN_MAP[a.type]]}</p>
                      <p>{utils.blurAddress(a.identity)}</p>
                    </div>
                  </div>
                </Dropdown.Item>
              );
          })}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
