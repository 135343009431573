import { useDispatch } from "react-redux";
import {
  createDialog,
  updateGlobalLoading,
  removeDialog as _removeDialog,
} from "../slices/ui.slice";

export default function useUI() {
  const dispatch = useDispatch();
  function showLoading() {
    dispatch(updateGlobalLoading(true));
  }
  function hideLoading() {
    dispatch(updateGlobalLoading(false));
  }
  function removeDialog(id: number) {
    dispatch(_removeDialog({ id }));
  }
  function openMessage(message: string) {
    return new Promise((resolve, reject) => {
      dispatch(
        createDialog({
          type: "message",
          content: message,
          callback: () => {
            resolve(null);
          },
        })
      );
    });
  }
  function openConfirm(message: string) {
    return new Promise((resolve, reject) => {
      dispatch(
        createDialog({
          type: "confirm",
          content: message,
          callback: (isConfirm: boolean) => {
            if (isConfirm) {
              resolve(null);
            } else {
              reject(null);
            }
          },
        })
      );
    });
  }
  function openSettings() {
    return new Promise((resolve, reject) => {
      dispatch(
        createDialog({
          type: "setting",
          callback: (isConfirm: boolean) => {},
        })
      );
    });
  }
  function showCaptcha(cb: any) {
    return new Promise((resolve, reject) => {
      dispatch(
        createDialog({
          type: "captcha",
          callback: cb,
        })
      );
    });
  }
  function showAvatar() {
    return new Promise((resolve, reject) => {
      dispatch(
        createDialog({
          type: "avatar",
        })
      );
    });
  }
  function showName() {
    return new Promise((resolve, reject) => {
      dispatch(
        createDialog({
          type: "name",
        })
      );
    });
  }
  function openSelectChain(meta: any): Promise<number> {
    console.log("open select chain ", meta);
    return new Promise((resolve, reject) => {
      dispatch(
        createDialog({
          type: "select_chain",
          meta,
          callback: (payload: any) => {
            let { error, data } = payload;
            if (error) {
              return reject(error);
            }
            return resolve(data);
          },
        })
      );
    });
  }
  function openCommunity() {
    return new Promise((resolve, reject) => {
      dispatch(
        createDialog({
          type: "community",
        })
      );
    });
  }
  function openLeaderboard() {
    return new Promise((resolve, reject) => {
      dispatch(
        createDialog({
          type: "leaderboard",
        })
      );
    });
  }
  return {
    openLeaderboard,
    openCommunity,
    openSelectChain,
    showName,
    showAvatar,
    openSettings,
    openMessage,
    openConfirm,
    showLoading,
    hideLoading,
    showCaptcha,
    removeDialog,
  };
}
