import { GameEventType } from "config/interface";
import { useEffect } from "react";
import { FitToViewport } from "react-fit-to-viewport";
import { useSelector } from "react-redux";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import api from "services/api";
import DialogManager from "../../component/dialog";
import Loading from "../../component/dialog/dialog-loading";
import gameConfig from "../../config/game-config";
import useCustomer from "../../hooks/useCustomer";
import useInventory from "hooks/useInventory";
import { RootState } from "../../store";
import Fusion from "../Fusion";
import Home from "../Home";
import Inventory from "../Inventory";
import Mutation from "../Mutation";
import SelectTree from "../SelectTree";
import Setup from "../Setup";
import WorldTree from "../WorldTree";
export default function Layout() {
  const customerService = useCustomer();
  const inven = useInventory();
  const { loadingItems } = useSelector(
    (state: RootState) => state.inventorySlice
  );
  useEffect(() => {
    gameConfig.loadConfig().then((data: any) => {
      customerService.refreshInventory();
      customerService.updateCustomerInfo(data.customerInfo);
      customerService.getAuth();
    });
    setInterval(() => {
      getEvents();
    }, 15000);
    document.addEventListener("document_active", refreshStake);
    function refreshStake() {
      customerService.refreshStake();
    }
    return () => {
      document.removeEventListener("document_active", refreshStake);
    };
  }, []);
  async function getEvents() {
    let rs = await api.getEvents();
    rs.forEach((e: any) => {
      switch (e.type) {
        case GameEventType.UserNFTToItem:
          inven.addImportedItems(e.payload);
          break;
        case GameEventType.AddItem:
          inven.addItems(e.payload);
          break;
        case GameEventType.RemoveItem:
          let plotIds = e.payload.plotInfos.map((i: any) => i.id);
          let mongenIds = e.payload.mongenInfos.map((i: any) => i.id);
          inven.destroyItems({ plotIds, mongenIds });
          break;
      }
    });
  }
  return (
    <div className="h-screen w-screen flex justify-center items-center bg-slate-700  text-white overflow-hidden">
      <FitToViewport
        width={1920}
        height={1080}
        minZoom={0.1}
        maxZoom={1.5}
        className="overflow-hidden"
      >
        <DialogManager />
        {loadingItems ? (
          <Loading />
        ) : (
          <div className="bg-[#4288d4] w-full h-full">
            <Router
            //  initialEntries={["/"]}
            >
              <Routes>
                <Route path="/" element={<Home />}></Route>
                <Route path="/setup/:stage" element={<Setup />}></Route>
                <Route path="/inventory/:tab" element={<Inventory />}></Route>
                <Route path="/fusion" element={<Fusion />}></Route>
                <Route path="/mutation" element={<Mutation />}></Route>
                <Route path="/worldtree" element={<WorldTree />}></Route>
                <Route path="/selecttree" element={<SelectTree />}></Route>
              </Routes>
            </Router>
          </div>
        )}
      </FitToViewport>
    </div>
  );
}
