import { MapPlotType, PlotSize, Rarity } from "../../config/interface";
import styled, { keyframes } from "styled-components";
import { bounce, pulse } from "react-animations";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import utils from "../../services/utils";
const bounceAnimation = keyframes`${pulse}`;
const BouncyDiv = styled.div`
  animation: 2s ${bounceAnimation} infinite;
`;
export default function PlotDefense({
  rarity,
  onClick,
}: {
  rarity: Rarity;
  onClick?: Function;
}) {
  const [size, setSize] = useState<PlotSize>(PlotSize.Medium);
  const { plotInfos, mongenInfos, mapItems } = useSelector(
    (state: RootState) => state.inventorySlice
  );
  useEffect(() => {
    let total = mongenInfos.filter((m) => m.is_click2earn).length;
    let workshop = mapItems[MapPlotType.Defense]?.length || 0;
    setSize(utils.calculateSize((workshop / total) * 100));
  }, [plotInfos, mongenInfos]);
  function renderContent() {
    switch (rarity) {
      case Rarity.Common:
        return PlotCommon(size);
      case Rarity.Uncommon:
        return PlotUncommon(size);
      case Rarity.Rare:
        return PlotRare(size);
      case Rarity.Epic:
        return PlotEpic(size);
      case Rarity.Legendary:
        return PlotLegend(size);
    }
  }
  return (
    <button
      className="interactive w-[120px] h-[250px] -top-16 -left-16 relative pointer-events-auto"
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      {renderContent()}
    </button>
  );
}
function PlotCommon(size: PlotSize) {
  switch (size) {
    case PlotSize.Small:
      return (
        <div className="map-island_defense_CO_1 absolute left-[-69px] bottom-[-43px] pointer-events-none"></div>
      );
    case PlotSize.Medium:
      return (
        <div className="map-island_defense_CO_2 absolute left-[-69px] bottom-[-43px]  pointer-events-none"></div>
      );
    case PlotSize.Large:
      return (
        <div className="map-island_defense_CO_3 absolute left-[-69px] bottom-[-43px]  pointer-events-none"></div>
      );
  }
}
function PlotUncommon(size: PlotSize) {
  switch (size) {
    case PlotSize.Small:
      return (
        <div className="map-island_defense_UC_2 absolute left-[-69px] bottom-[-43px] pointer-events-none"></div>
      );
    case PlotSize.Medium:
      return (
        <div className="map-island_defense_UC_2 absolute left-[-69px] bottom-[-43px] pointer-events-none"></div>
      );
    case PlotSize.Large:
      return (
        <div className="map-island_defense_UC_3 absolute left-[-69px] bottom-[-43px] pointer-events-none"></div>
      );
  }
}

function PlotRare(size: PlotSize) {
  switch (size) {
    case PlotSize.Small:
      return (
        <div className="map-island_defense_RA_1 absolute left-[-69px] bottom-[-43px] pointer-events-none"></div>
      );
    case PlotSize.Medium:
      return (
        <div className="map-island_defense_RA_2 absolute left-[-69px] bottom-[-43px] pointer-events-none"></div>
      );
    case PlotSize.Large:
      return (
        <div className="map-island_defense_RA_3 absolute left-[-69px] bottom-[-43px] pointer-events-none"></div>
      );
  }
}

function PlotEpic(size: PlotSize) {
  switch (size) {
    case PlotSize.Small:
      return (
        <div className="map-island_defense_EP_1 absolute left-[-69px] bottom-[-43px] pointer-events-none"></div>
      );
    case PlotSize.Medium:
      return (
        <div className="map-island_defense_EP_2 absolute left-[-69px] bottom-[-43px] pointer-events-none"></div>
      );
    case PlotSize.Large:
      return (
        <div className="map-island_defense_EP_3 absolute left-[-69px] bottom-[-43px] pointer-events-none"></div>
      );
  }
}

function PlotLegend(size: PlotSize) {
  switch (size) {
    case PlotSize.Small:
      return (
        <div className="map-island_defense_LE_1 absolute left-[-69px] bottom-[-43px] pointer-events-none"></div>
      );
    case PlotSize.Medium:
      return (
        <div className="map-island_defense_LE_2 absolute left-[-69px] bottom-[-43px] pointer-events-none"></div>
      );
    case PlotSize.Large:
      return (
        <div className="map-island_defense_LE_3 absolute left-[-69px] bottom-[-43px] pointer-events-none"></div>
      );
  }
}
