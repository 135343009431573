/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import Toolbar from "./toolbar";
import Map from "../../component/map";
import {
  Chain,
  LoginType,
  MapPlotType,
  SetupStage,
} from "../../config/interface";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import userSlice from "../../slices/user.slice";
import utils from "../../services/utils";
import gameConfig from "../../config/game-config";
import Balance from "component/balance";
import { useEffect, useState } from "react";
import AuthEntity from "config/auth.entity";
import { CHAIN_MAP } from "services/api";
import _ from "lodash";
import useUI from "hooks/useUI";
import CustomerName from "component/customer-name";
export default function Home() {
  const { t } = useTranslation();
  const nav = useNavigate();
  const ui = useUI();
  const { customerInfo, authInfos, stake } = useSelector(
    (state: RootState) => state.userSlice
  );
  const [auth, setAuth] = useState<AuthEntity>(null);

  useEffect(() => {
    if (!authInfos) {
      return;
    }
    let tmp = _.cloneDeep(authInfos);
    let a = tmp
      .sort((a, b) => a.type - b.type)
      .find((a: AuthEntity) =>
        [LoginType.BSC, LoginType.Avalanche, LoginType.Okex].includes(a.type)
      );
    setAuth(a);
  }, [authInfos]);
  return (
    <div>
      <div className="absolute top-5 left-5 z-10 flex gap-2 flex-col">
        <div className="flex gap-2 items-center">
          <div className="w-[110px] h-[110px] rounded-md border-2 p-1 ">
            <img
              onClick={() => {
                ui.showAvatar();
              }}
              src={`avatar/avatar_${((customerInfo.avatar || 1) + "").padStart(
                3,
                "0"
              )}.webp`}
              className="w-full h-full rounded-md overflow-hidden interactive"
            />

            <img src="pencil_icon.png" className="absolute top-2 left-14" />
            <img src="pencil_icon.png" className="absolute top-2 right-1" />
          </div>
          <div className="w-[350px]">
            <div
              className="flex justify-center border-2 rounded-md p-1 interactive"
              onClick={() => {
                ui.showName();
              }}
            >
              <p className="w-[250px] text-center overflow-hidden h-[32px]">
                <CustomerName />
              </p>
            </div>
            {auth ? (
              <div className="flex gap-2 items-end mt-[15px] rounded-md border-2 p-1 justify-center">
                <p>{utils.blurAddress(auth?.identity)}</p>
                <img
                  src={`chain/${Chain[
                    CHAIN_MAP[auth?.type]
                  ]?.toLowerCase()}.webp`}
                  className="w-[30px] h-[30px]"
                />
              </div>
            ) : (
              <a
                className="flex gap-2 items-end mt-[15px] rounded-md border-2 p-1 justify-center text-textnumberyellow cursor-pointer"
                href="https://dao.monsterra.io"
                target="_blank"
              >
                {t("chain.attach_chain")}
              </a>
            )}
          </div>
        </div>
      </div>
      <div className="absolute top-[160px] left-5 z-10">
        <div className="flex gap-4 items-center">
          <div
            className="text-component"
            onClick={() => window.open("https://play.monsterra.io", "_blank")}
          >
            <img
              src="social/btn_game.png"
              className="w-14 h-14 mx-auto block"
            />
            <p className="text-center text-component">{t("home.monsterra")}</p>
          </div>
          <div
            className="text-component"
            onClick={() => {
              ui.openCommunity();
            }}
          >
            <img
              src="social/btn_community.png"
              className="w-14 h-14 mx-auto block"
            />
            <p className="text-center text-component">{t("home.community")}</p>
          </div>
          <div
            className="text-component"
            onClick={() => window.open("https://event.monsterra.io", "_blank")}
          >
            <img
              src="social/btn_news.png"
              className="w-14 h-14 mx-auto block"
            />
            <p className="text-center text-component">{t("home.events")}</p>
          </div>
        </div>
        <div className="flex gap-4 mt-2">
          <div
            className="text-component"
            onClick={() => window.open("https://dao.monsterra.io", "_blank")}
          >
            <img src="social/btn_dao.png" className="w-14 h-14 mx-auto block" />
            <p className="text-center text-component">{t("home.dao")}</p>
          </div>
          <div
            className="text-component"
            onClick={() =>
              window.open("https://marketplace.monsterra.io", "_blank")
            }
          >
            <img
              src="social/btn_marketplace.png"
              className="w-14 h-14 mx-auto block"
            />
            <p className="text-center">{t("home.marketplace")}</p>
          </div>
        </div>
      </div>
      <div className="absolute top-5 right-5 z-10 text-right">
        <div className="flex gap-2 items-center">
          <Balance />
          <button
            className="interactive text-3xl font-bold"
            onClick={() => {
              ui.openSettings();
            }}
          >
            <img src="social/btn_setting.png" className="w-16 h-16" />
          </button>
        </div>
        <div className="ui-blank_slot_limit inline-block mt-4">
          <div className="w-full flex flex-col justify-center text-center pt-[10px] w-[280px]'items-center px-3 uppercase">
            <p className="text-md font-bold text-textblack mt-1">
              {t("home.mint_value")}/{t("global.total")}
            </p>
            <p className="text-xl font-bold text-textbrown mt-2 text-center">
              {utils.convertToInternationalCurrencySystem(
                customerInfo.nft_points
              )}
              /
              {utils.convertToInternationalCurrencySystem(stake.totalNftPoints)}
            </p>
          </div>
        </div>
        <div
          className="mt-4 text-component absolute top-[220px] right-1"
          onClick={() => {
            ui.openLeaderboard();
          }}
        >
          <img src="social/btn_event.png" className="w-14 h-14 mx-auto block" />
          <p className="text-center">{t("global.leaderboard")}</p>
        </div>
      </div>
      <Map
        onPlotClick={(type: MapPlotType) => {
          switch (type) {
            case MapPlotType.Landcore:
              nav(`/setup/${SetupStage.PickLandcore}`);
              break;
            case MapPlotType.Defense:
            case MapPlotType.Workshop:
              nav(`/setup/${SetupStage.AttachPlot}`);
              break;
            case MapPlotType.Habitat:
              nav(`/setup/${SetupStage.AttachHabitat}`);
              break;
            case MapPlotType.Storage:
              nav(`/setup/${SetupStage.AttachStorage}`);
              break;
          }
        }}
      />
      <div className="w-full absolute bottom-0 left-0 z-10 pointer-events-none">
        <Toolbar />
      </div>
    </div>
  );
}
