import { store } from "store";
import api from "../services/api";
import { PlotType, NFTType, LoginType, SSO_TYPE, Chain } from "./interface";
interface NftPoint {
  points: number[];
  fusionElixirCost: number[];
  fusionMstrCost: number[];
  limit_mongen: number[];
  plot_type: number;
  limit_habitat: number[];
  required_mongen: number[];
  max_elixir: number[];
  max_mutatedgen: number[];
  type: number;
}
let data: {
  NftStakePoint: NftPoint[];
  EvolutionLevel: number[];
  MaxMongenLevel: number;
  MaxPlotLevel: number[];
  elixirPool: number;
  mutatedgenPool: number;
  FormRarityBaseMultiply: number[][];
  BaseFormStat: number[][];
  BodyPartRarityBaseMultiply: number[][];
  BaseBodyPartStat: number[][];
  LevelStatMultiply: number[][];
  RarityPowerRange: number[];
  FusionTreeRefill: number[][];
  UnlockAvatarLevel: number[][];
  claimableStorageRate: number;
  currentFusionSeason?: any
  legendaryMongenFusioned: number;
  MongenName: string[][];
  MAX_MUTATE_PRICE_INCREASE: number;
  Mutate_Mongen_Part: number[][];
} = {
  NftStakePoint: [],
  EvolutionLevel: [],
  MaxMongenLevel: 0,
  MaxPlotLevel: [],
  elixirPool: 0,
  mutatedgenPool: 0,
  BaseFormStat: [],
  FormRarityBaseMultiply: [],
  BodyPartRarityBaseMultiply: [],
  BaseBodyPartStat: [],
  LevelStatMultiply: [],
  RarityPowerRange: [],
  FusionTreeRefill: [],
  UnlockAvatarLevel: [],
  claimableStorageRate: 0,
  currentFusionSeason: null,
  legendaryMongenFusioned: 0,
  MongenName: [],
  MAX_MUTATE_PRICE_INCREASE: 0,
  Mutate_Mongen_Part: []
};
async function loadConfig() {
  let rs = await api.getConfig();
  data = rs;
  return rs;
}
function getGameConfig() {
  return data;
}
function getItemInfo(
  type: NFTType,
  plot_type?: PlotType
): NftPoint | undefined {
  return data.NftStakePoint.find((i: NftPoint) => {
    if (i.type !== type) return false;
    if (plot_type !== undefined && plot_type !== i.plot_type) {
      return false;
    }
    return true;
  });
}

const MainnetUnsupportedChain = [LoginType.Terra];
const AntiWalletAuth = [
  LoginType.Email,
  LoginType.Facebook,
  LoginType.Google,
  LoginType.Apple,
];

const EtherChain = [Chain.BSC, Chain. Okex, Chain.Avalanche];
const CosmosChain = [Chain.Aura];

export const SSO_META = () => {
  let SSO_META: SSO_TYPE = store.getState().ssometaSlice.meta;
  return SSO_META;
};
export function getSSOMeta() {
  return SSO_META;
}

export const SSO_CONTRACT = () => store.getState().ssometaSlice.contract;
export const SSO_VERSION = () => store.getState().ssometaSlice.version;

let gameConfig = {
  loadConfig,
  getGameConfig,
  getItemInfo,
  MainnetUnsupportedChain,
  AntiWalletAuth,
  EtherChain,
  CosmosChain
};
export default gameConfig;
