import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./slices/user.slice";
import uiSlice from "./slices/ui.slice";
import inventorySlice from "./slices/inventory.slice";
import ssometaSlice from "./slices/ssometa.slice";
export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  reducer: { userSlice, uiSlice, inventorySlice, ssometaSlice },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
