/* eslint-disable react/jsx-no-target-blank */
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import utils from "services/utils";
import { RootState } from "store";

export default function Balance() {
  const { t } = useTranslation();
  const { customerInfo } = useSelector((state: RootState) => state.userSlice);
  return (
    <div className="flex gap-2">
      <a
        className="ui-blank_mutate_gen_plus block"
        href="https://dao.monsterra.io"
        target="_blank"
      >
        <div className="w-full flex justify-end pt-[26px] items-center pr-3 uppercase">
          <p className="text-xl font-bold text-textblack">
            {t("home.mutate_gen")}
          </p>
          <p className="text-xl font-bold text-textbrown w-[120px] text-right">
            {utils.convertToInternationalCurrencySystem(
              customerInfo.mutated_gen
            )}
          </p>
        </div>
      </a>
      <a
        className="ui-blank_elixir_plus block ml-auto"
        href="https://dao.monsterra.io"
        target="_blank"
      >
        <div className="w-full flex justify-end pt-[26px] items-center pr-3 uppercase">
          <p className="text-xl font-bold text-textblack">{t("home.elixir")}</p>
          <p className="text-xl font-bold text-textbrown w-[120px] text-right">
            {utils.convertToInternationalCurrencySystem(customerInfo.elixir)}
          </p>
        </div>
      </a>
      <a
        className="ui-blank_MSTR_plus block ml-auto"
        href="https://dao.monsterra.io"
        target="_blank"
      >
        <div className="w-full flex justify-end pt-[26px] items-center pr-3 uppercase">
          <p className="text-xl font-bold text-textblack">{t("home.mstr")}</p>
          <p className="text-xl font-bold text-textbrown w-[120px] text-right">
            {utils.convertToInternationalCurrencySystem(customerInfo.mstr)}
          </p>
        </div>
      </a>
    </div>
  );
}
