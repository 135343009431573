import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { DialogInfo, removeDialog } from "../../slices/ui.slice";
import Button from "../button";

export default function DialogConfirm({ dialog }: { dialog: DialogInfo }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return (
    <div className="w-full h-full flex justify-center items-center">
      <div className="ui-popup_confirm_fusion relative">
        <p className="text-[#fdf3a3] font-bold text-3xl mt-5  text-center">
          {t("dialog.confirmation")}
        </p>
        <p className="mt-8 px-4 text-2xl w-full h-40 flex justify-center items-center">
          {t(dialog.content)}
        </p>
        <div className="absolute w-full bottom-10">
          <div className="w-3/4 m-auto flex gap-4 justify-between px-4">
            <Button
              text={t("dialog.cancel")}
              color="red"
              size="lg"
              onClick={() => {
                dispatch(removeDialog({ id: dialog.id }));

                dialog.callback(false);
              }}
            />
            <Button
              text={t("dialog.confirm")}
              color="green"
              size="lg"
              onClick={() => {
                dispatch(removeDialog({ id: dialog.id }));
                dialog.callback(true);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
