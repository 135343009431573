import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import CustomerEntity from "../config/customer.entity";
import api from "../services/api";
interface UserSlice {
  customerInfo: CustomerEntity;
  stake: {
    elixirRevenue: number;
    mutatedRevenue: number;
    totalNftPoints: number;
  };
  authInfos: any[];
  setting: Setting;
}
interface Setting {
  music: boolean;
  sfx: boolean;
}
function initSetting() {
  try {
    let setting = localStorage.getItem("setting");
    if (setting) {
      return JSON.parse(setting);
    }
  } catch (error) {
    return { music: true, sfx: true };
  }
  return { music: true, sfx: true };
}
export const getStake = createAsyncThunk("user/get_stake", () => {
  return api.getStakeInformation();
});
export const getAuthInfos = createAsyncThunk("user/get_auth", () => {
  return api.getAuth();
});
const initialState: UserSlice = {
  customerInfo: {
    prefix: "",
    id: 0,
    level: 1,
    avatar: 1,
    name: "",
    nft_points: 0,
    avatar_pool: [],
    max_token_capacity: {
      elixir: 0,
      mutated_gen: 0,
    },
    elixir: 0,
    mutated_gen: 0,
    staked_mag: 0,
    mstr: 0,
    mutating_mongen: null
  },
  stake: {
    elixirRevenue: 0,
    mutatedRevenue: 0,
    totalNftPoints: 0,
  },
  authInfos: [],
  setting: initSetting(),
};
export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setCustomerInfo: (state, action) => {
      state.customerInfo = action.payload;
    },
    setStake: (state, action) => {
      state.stake = action.payload;
    },
    setAuth: (state, action) => {
      state.authInfos = action.payload;
    },
    updateSetting: (state, action) => {
      localStorage.setItem("setting", JSON.stringify(action.payload));
      state.setting = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getStake.fulfilled, (state, action: any) => {
      state.stake = {
        elixirRevenue: Number(action.payload.elixirRevenue),
        mutatedRevenue: Number(action.payload.mutatedRevenue),
        totalNftPoints: Number(action.payload.totalNftPoints),
      };
    });
    builder.addCase(getAuthInfos.fulfilled, (state, action: any) => {
      state.authInfos = action.payload;
    });
  },
});

// Action creators are generated for each case reducer function
export const { setCustomerInfo, setStake, setAuth, updateSetting } =
  userSlice.actions;

export default userSlice.reducer;
