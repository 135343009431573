import gameConfig from "config/game-config";
import { ContractType, LoginType } from "config/interface";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../component/button";
import useCustomer from "../../hooks/useCustomer";
import useUI from "../../hooks/useUI";
import api from "../../services/api";
import utils from "../../services/utils";
import { createDialog } from "../../slices/ui.slice";
import { RootState } from "../../store";

export default function Toolbar() {
  const { t } = useTranslation();
  const nav = useNavigate();
  const ui = useUI();
  const { stake, customerInfo } = useSelector(
    (state: RootState) => state.userSlice
  );

  const customerService = useCustomer();
  useEffect(() => {
    customerService.refreshStake();
  }, []);
  function canClaim() {
    let elixir =
      gameConfig.getGameConfig().claimableStorageRate *
      customerInfo?.max_token_capacity?.elixir;
    let mutated =
      gameConfig.getGameConfig().claimableStorageRate *
      customerInfo?.max_token_capacity?.mutated_gen;
    if (elixir === 0) {
      return false;
    }
    return stake.elixirRevenue > elixir || stake.mutatedRevenue > mutated;
  }
  async function claim() {
    if (
      Number(stake.elixirRevenue) === 0 &&
      Number(stake.mutatedRevenue) === 0
    ) {
      return;
    }
    let customerInfo = await api.claimStake();
    customerService.updateCustomerInfo(customerInfo);
    customerService.refreshStake();
  }
  function onInventoryClick() {
    nav("/inventory/off");
  }
  function onSetupClick() {
    nav("/setup/0");
  }
  function onMutationClick() {
    nav("/mutation");
  }
  function onFusionClick() {
    nav("/fusion");
  }
  function onWorldTreeClick() {
    nav("/worldtree");
  }

  return (
    <div className="flex justify-between p-6 items-end">
      <div className="flex flex-col pointer-events-auto">
        <button
          className="ui-button_inventory interactive"
          onClick={onInventoryClick}
        ></button>
        <button
          className="ui-button_setup_land mt-2 interactive"
          onClick={onSetupClick}
        ></button>
      </div>
      <div>
        <div className="flex gap-4">
          <div className="ui-blank_mutate_gen_2 items-center flex justify-between px-1 pointer-events-auto">
            <p className="text-xl font-bold text-textblack uppercase">
              {t("toolbar.mutate_gen")}
            </p>
            <p className="text-xl font-bold text-textbrown ">
              {utils.convertToInternationalCurrencySystem(stake.mutatedRevenue)}
              /
              {utils.convertToInternationalCurrencySystem(
                customerInfo?.max_token_capacity?.mutated_gen || 0
              )}
            </p>
          </div>
          <div className="ui-blank_mutate_gen_2 items-center flex justify-between px-1 pointer-events-auto">
            <p className="text-xl font-bold text-textblack uppercase">
              {t("toolbar.elixir")}
            </p>
            <p className="text-xl font-bold text-textbrown">
              {utils.convertToInternationalCurrencySystem(stake.elixirRevenue)}/
              {utils.convertToInternationalCurrencySystem(
                customerInfo?.max_token_capacity?.elixir || 0
              )}
            </p>
          </div>
        </div>
        <button
          onClick={() => {
            claim();
          }}
          disabled={!canClaim()}
          className={`mt-4 pointer-events-auto mx-auto block ui-button_confirm_mutation hover:cursor-pointer ${!canClaim() && "grayscale"
            }`}
        >
          <p className="text-3xl">{t("toolbar.claim")}</p>
        </button>
      </div>
      <div className="flex flex-col pointer-events-auto">
        <button
          className="ui-button_fusion mt-2 interactive"
          onClick={onFusionClick}
        ></button>
        <button
          className="ui-button_mutation mt-2 interactive"
          onClick={onMutationClick}
        ></button>
      </div>
    </div>
  );
}
