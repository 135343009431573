import { MongenRace } from "config/interface";
import { useEffect, useState } from "react";
// import { initPlayer, queryImage, removeQuery } from "./player";
import startGame, { queryImage, removeQuery } from "gamefix/game";
function MongenImage({ dna, className }) {
  const [img, setImg] = useState(null);
  const [id, setId] = useState(0);

  useEffect(() => {
    startGame();
  }, []);

  useEffect(() => {
    if (id) {
      removeQuery(id);
    }
    let newId = queryImage(JSON.parse(dna), (img) => {
      setImg(img);
    });
    setId(newId);
    return () => {};
  }, [dna]);
  return img === null ? (
    <div>
      <p>Loading...</p>
    </div>
  ) : (
    <img
      className={`max-h-full mx-auto -translate-y-10 ` + className}
      src={img}
      alt="mongen"
    />
  );
}

export default function Mongen({ dna }) {
  if (!dna) {
    return <></>;
  }

  function renderEgg() {
    let race = MongenRace[dna[0][0]];
    let image = `${race.toLowerCase()}-${dna[1][0]}`;
    return (
      <img
        src={`/egg/${image}.webp`}
        className="w-full h-full object-contain"
      />
    );
  }
  if (dna.length <= 2) {
    return renderEgg();
  } else {
    return <MongenImage dna={JSON.stringify(dna)} />;
  }
}
