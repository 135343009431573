import { PlotSize, Rarity } from "../../config/interface";
import styled, { keyframes } from "styled-components";
import { flash as anim, pulse } from "react-animations";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useEffect, useState } from "react";
import utils from "../../services/utils";
const bounceAnimation = keyframes`${anim}`;
const AnimDiv = styled.div`
  animation: 2s ${bounceAnimation} infinite;
`;
export default function PlotStorage({
  rarity,
  onClick,
}: {
  rarity: Rarity;
  onClick?: Function;
}) {
  const { customerInfo, stake } = useSelector(
    (state: RootState) => state.userSlice
  );
  const [size, setSize] = useState<PlotSize>(PlotSize.Small);

  useEffect(() => {
    if (!customerInfo?.max_token_capacity?.elixir) {
      return;
    }
    setSize(
      utils.calculateSize(
        (stake.elixirRevenue / customerInfo.max_token_capacity.elixir) * 100
      )
    );
  }, [customerInfo, stake]);
  function renderContent() {
    switch (rarity) {
      case Rarity.Common:
        return PlotCommon(size);
      case Rarity.Uncommon:
        return PlotUncommon(size);
      case Rarity.Rare:
        return PlotRare(size);
      case Rarity.Epic:
        return PlotEpic(size);
      case Rarity.Legendary:
        return PlotLegend(size);
    }
  }
  return (
    <button
      className="interactive w-[200px] h-[200px] -top-16 -left-16 relative"
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      {renderContent()}
    </button>
  );
}
function PlotCommon(size: PlotSize) {
  return (
    <div className="map-island_storage_CO absolute left-[35px] bottom-0">
      {size === PlotSize.Medium && (
        <div className="map-token_half absolute bottom-[83px] left-[32px]">
          <AnimDiv>
            <div className="anim-glowing_light absolute left-[-38px] bottom-[-7px]"></div>
          </AnimDiv>
        </div>
      )}
      {size === PlotSize.Large && (
        <div className="map-token_full absolute bottom-[83px] left-[27px]">
          <AnimDiv>
            <div className="anim-glowing_light absolute left-[-32px] bottom-[23px]"></div>
          </AnimDiv>
        </div>
      )}
    </div>
  );
}
function PlotUncommon(size: PlotSize) {
  return (
    <div className="map-island_storage_UC absolute left-[45px] bottom-0">
      {size === PlotSize.Medium && (
        <div className="map-token_half absolute bottom-[108px] left-[32px]">
          <AnimDiv>
            <div className="anim-glowing_light absolute left-[-38px] bottom-[-7px]"></div>
          </AnimDiv>
        </div>
      )}
      {size === PlotSize.Large && (
        <div className="map-token_full absolute bottom-[106px] left-[27px]">
          <AnimDiv>
            <div className="anim-glowing_light absolute left-[-32px] bottom-[23px]"></div>
          </AnimDiv>
        </div>
      )}
    </div>
  );
}

function PlotRare(size: PlotSize) {
  return (
    <div className="map-island_storage_RA absolute left-[30px] bottom-0">
      {size === PlotSize.Medium && (
        <div className="map-token_half absolute bottom-[107px] left-[58px]">
          <AnimDiv>
            <div className="anim-glowing_light absolute left-[-38px] bottom-[-7px]"></div>
          </AnimDiv>
        </div>
      )}
      {size === PlotSize.Large && (
        <div className="map-token_full absolute bottom-[107px] left-[52px]">
          <AnimDiv>
            <div className="anim-glowing_light absolute left-[-32px] bottom-[23px]"></div>
          </AnimDiv>
        </div>
      )}
    </div>
  );
}

function PlotEpic(size: PlotSize) {
  return (
    <div className="map-island_storage_EP absolute left-[10px] bottom-[0]">
      {size === PlotSize.Medium && (
        <div className="map-token_half absolute bottom-[150px] left-[82px]">
          <AnimDiv>
            <div className="anim-glowing_light absolute left-[-38px] bottom-[-7px]"></div>
          </AnimDiv>
        </div>
      )}
      {size === PlotSize.Large && (
        <div className="map-token_full absolute bottom-[148px] left-[77px]">
          <AnimDiv>
            <div className="anim-glowing_light absolute left-[-32px] bottom-[23px]"></div>
          </AnimDiv>
        </div>
      )}
    </div>
  );
}

function PlotLegend(size: PlotSize) {
  return (
    <div className="absolute bottom-0 left-[-21px] pointer-events-none">
      {size === PlotSize.Medium && (
        <div className="map-token_half absolute bottom-[148px] left-[81px]">
          <AnimDiv>
            <div className="anim-glowing_light absolute left-[-38px] bottom-[-7px]"></div>
          </AnimDiv>
        </div>
      )}
      {size === PlotSize.Large && (
        <div className="map-token_full absolute bottom-[148px] left-[77px]">
          <AnimDiv>
            <div className="anim-glowing_light absolute left-[-32px] bottom-[23px]"></div>
          </AnimDiv>
        </div>
      )}
      <div className="map-island_storage_LE"></div>
    </div>
  );
}
