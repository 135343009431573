import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PlotImage from "../../component/thumbnail/plot-image";
import gameConfig from "../../config/game-config";
import { PlotType } from "../../config/interface";
import PlotEntity from "../../config/plot.entity";
import { RootState } from "../../store";

export default function WorldTree() {
  const { t } = useTranslation();
  const nav = useNavigate();
  function onBackClick() {
    nav(-1);
  }
  const { plotInfos } = useSelector((state: RootState) => state.inventorySlice);
  let tree: PlotEntity | undefined = plotInfos.find(
    (p: PlotEntity) => p.is_click2earn && p.plot_type === PlotType.Tree
  );
  function onFusionClick() {
    nav('/fusion');
  }
  function onChangeClick() {
    nav('/selecttree');
  }
  return (
    <div className="ui-background p-4">
      <div className="flex justify-between">
        <p className="text-texttitle text-[64px] font-bold">
          {t("worldtree.worldtree")}
        </p>
        <button
          className="interactive ui-button_back_on"
          onClick={() => {
            onBackClick();
          }}
        >
          <p className="text-white text-3xl">{t("global.back")}</p>
        </button>
      </div>
      <div className="flex justify-center">
        {tree ? (
          <div>
            <p>#{tree.id}</p>
            <div className="w-[600px] h-[600px] rounded-xl overflow-hidden">
              <PlotImage plotInfo={tree} cover />
            </div>
            <div className="flex justify-between text-2xl font-normal mt-4">
              <p>
                <span className="text-textnumberyellow">Energy:</span>{" "}
                {tree.energy}/
                {gameConfig.getGameConfig().FusionTreeRefill[tree.level][0]}
              </p>
              <p>
                <span className="text-textnumberyellow">Realtime:</span>{" "}
                123/12312
              </p>
            </div>
            <div className="flex justify-between mt-6">
              <button className="ui-button_treeworld_change interactive" onClick={() => { onChangeClick() }}>
                <p className="text-4xl">{t("worldtree.change")}</p>
              </button>
              <button className="ui-button_treeworld_Fusion interactive" onClick={() => { onFusionClick() }}>
                <p className="text-4xl">{t("worldtree.fusion")}</p>
              </button>
            </div>
          </div>
        ) : (
          <div className='mt-8'>
            <div className='ui-blank_world_tree_2 flex justify-center items-center'>
              <div className='ui-added_worldtree_1'></div>
            </div>
            <button className="ui-button_treeworld_Fusion interactive mx-auto block mt-8" onClick={() => { onChangeClick() }}>
              <p className="text-4xl">{t("worldtree.stack")}</p>
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
