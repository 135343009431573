import ReactFacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import api from "../../services/api";
import { useEffect, useState } from "react";
import { LoginType, Chain, WALLET_PROVIDER } from "../../config/interface";
import { toast } from "react-toastify";
import { signInWithApple, signInWithGoogle } from "../../services/firebase";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "../../services/useWindowSize";
import Config, { SSO_META } from "../../config/game-config";
import EmailConnector from "component/email-connector";
import { SSO_VERSION } from "../../config/game-config";
import keplrService from "services/keplr";

export default function Login({ showLogin, setShowLogin }: { showLogin: boolean, setShowLogin: any }) {
  const [isMetaMask, setIsMetaMask] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isEmailClick, setIsEmailClick] = useState(false);
  const { t } = useTranslation();
  const size: any = useWindowSize();

  useEffect(() => {
    let component = document.getElementById("auto-scale");
    if (!component) return;
    if (size.height < 800) {
      component.style.transform = `scale(${size.height / 800})`
    } else {
      component.style.transform = `scale(1)`
    }
    // @ts-ignore
  }, [size.height])

  useEffect(() => {
    if (
      //@ts-ignore
      typeof window.ethereum !== "undefined" ||
      //@ts-ignore
      typeof window.web3 !== "undefined"
    ) {
      setIsMetaMask(true);
    } else {
      setIsMetaMask(false);
    }
  }, []);

  async function onMetaMaskClick(type: LoginType) {
    if (!isMetaMask) {
      window.open(
        "https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en",
        "_blank"
      );
      return;
    }
    localStorage.setItem("current_login_type", LoginType[type]);
    await api.checkNetwork(type);
    try {
      setLoading(true);
      await api.login(type);
      setShowLogin(false)
      console.log("hide login");
    } catch (error: any) {
      if (error.message) {
        toast(t(error.message));
      }
    } finally {
      setLoading(false);
    }
  }

  async function onKeplrClick(type: LoginType) {
    // @ts-ignore
    if (!window.keplr) {
      window.open(
        "https://chrome.google.com/webstore/detail/keplr/dmkamcknogkgcdfhhbddcghachkejeap?hl=en",
        "_blank"
      );
      return;
    }
    sessionStorage.setItem("current_login_type", LoginType[type]);
    await keplrService.connect(WALLET_PROVIDER.KEPLR)
    console.log("start check network")
    await api.checkNetwork(type);
    try {
      setLoading(true);
      await api.loginCosmos(type);
      console.log("hide login");
      setShowLogin(false);
    } catch (error: any) {
      if (error.message) {
        toast(t(error.message));
      }
    } finally {
      setLoading(false);
    }
  }

  const onSocialClick = async (accessToken: string, loginType: LoginType) => {
    try {
      await api.loginBySocial(accessToken, loginType);
      setShowLogin(false)
    } catch (err: any) {
      if (err.message) {
        toast(t(err.message));
      }
    }
  };

  if (isEmailClick)
    return (
      <EmailConnector
        setShowLogin={() => { }}
        type={"signin"}
        onBack={() => {
          setIsEmailClick(false);
        }}
        onDone={() => {
          setShowLogin(false)
          console.log("hide login");
        }}
      />
    );

  return (
    <div className="bg bg-contain px-1 w-[680px] max-w-full font-cookie-run" id="auto-scale">
      <p className="text-3xl py-2 pt-5 text-outline w-full mx-auto">
        WELCOME!
      </p>
      <div className="bg-[#22253a] p-3 rounded-b-lg">
        {loading ? (
          <div className="min-h-[250px] flex">
            <p className="text-center mt-4 text-[#636a95] text-lg self-center justify-center text-center mx-auto">
              Loading ...
            </p>
          </div>
        ) : (
          <>
            <div className="text-white text-lg">
              Login using:
            </div>
            <div className="bg-[#15172A] rounded-lg flex-wrap w-[450px] mx-auto">
              <div className="flex gap-4 mx-center text-center justify-center mt-2 py-2">
                <div className="relative">
                  {Config.MainnetUnsupportedChain.includes(LoginType.BSC) && <div className="disabled-login-button-text">Coming soon</div>}
                  <div className={`button-logo-container ${Config.MainnetUnsupportedChain.includes(LoginType.BSC) && "disabled-login-button"}`}>
                    <div
                      className="button-logo"
                      onClick={async () => await onMetaMaskClick(LoginType.BSC)}
                    >
                      <img
                        src={require("./images/bsc.png")}
                        className="w-8 h-8 object-contain"
                        alt="bsc"
                      />
                    </div>
                    <div>BNB Chain</div>
                  </div>
                </div>

                <div className="relative">
                  {Config.MainnetUnsupportedChain.includes(LoginType.Avalanche) && <div className="disabled-login-button-text">Coming soon</div>}
                  <div className={`button-logo-container ${Config.MainnetUnsupportedChain.includes(LoginType.Avalanche) && "disabled-login-button"}`}>
                    <div
                      className="button-logo"
                      onClick={async () =>
                        await onMetaMaskClick(LoginType.Avalanche)
                      }
                    >
                      <img
                        src={require("./images/avalanche.png")}
                        className="w-8 h-8 object-contain"
                        alt="avalanche"
                      />
                    </div>
                    <div>AVAX Network</div>
                  </div>
                </div>

                <div className="relative">
                  {Config.MainnetUnsupportedChain.includes(LoginType.Okex) && <div className="disabled-login-button-text">Coming soon</div>}
                  <div className={`button-logo-container ${Config.MainnetUnsupportedChain.includes(LoginType.Okex) && "disabled-login-button"}`}>
                    <div className="button-logo"
                      onClick={async () =>
                        await onMetaMaskClick(LoginType.Okex)
                      }
                    >
                      <img
                        src={require("./images/okex.png")}
                        className="w-8 h-8 object-contain"
                        alt="okex"
                      />
                    </div>
                    <div>OKX Chain</div>
                  </div>
                </div>

                <div className="relative">
                    {Config.MainnetUnsupportedChain.includes(LoginType.Aura) && <div className="disabled-login-button-text">Coming soon</div>}
                    <div className={`button-logo-container ${Config.MainnetUnsupportedChain.includes(LoginType.Aura) && "disabled-login-button"}`}>
                      <div className="button-logo"
                        onClick={async () => {
                          await onKeplrClick(LoginType.Aura)
                        }}
                      >
                        <img
                          src={require("./images/aura.png")}
                          className="w-8 h-8 object-contain"
                          alt="okex"
                        />
                      </div>
                      <div>Aura Network</div>
                    </div>
                  </div>
              </div>
            </div>
            <div className="mt-3 mb-1 text-[#a8464f] text-sm">
              Extension of selected wallet will be installed
            </div>
            <div className="br"></div>
            <div className="bg-[#15172A] rounded-lg flex-wrap w-[450px] mx-auto">
              <div className="flex gap-4 mx-center text-center justify-center mt-4 py-2">
                <ReactFacebookLogin
                  cookie={true}
                  appId={SSO_META().FACEBOOK_APP_ID}
                  autoLoad={false}
                  fields="name,email,picture"
                  callback={async (res: any) => {
                    let accessToken = res.accessToken;
                    await onSocialClick(accessToken, LoginType.Facebook);
                    setLoading(false);
                  }}
                  onFailure={() => {
                    setLoading(false);
                  }}
                  render={(renderProps: any) => (
                    <div className="button-logo-container">
                      <div
                        className="button-logo"
                        onClick={() => {
                          setLoading(true);
                          renderProps.onClick();
                        }}
                      >
                        <img
                          src={require("./images/facebook.png")}
                          className="w-8 h-8 object-contain"
                          alt="facebook"
                        />
                      </div>
                      <div>
                        Facebook
                      </div>
                    </div>
                  )}
                />

                <div className="button-logo-container">
                  <div
                    className="button-logo"
                    onClick={async () => {
                      setLoading(true);
                      let res = await signInWithGoogle(setLoading);
                      let credential = res._tokenResponse;
                      let accessToken = credential.oauthAccessToken;
                      await onSocialClick(accessToken, LoginType.Google);
                      setLoading(false);
                    }}
                  >
                    <img
                      src={require("./images/google.png")}
                      className="w-8 h-8 object-contain"
                      alt="google"
                    />
                  </div>
                  <div>
                    Google
                  </div>
                </div>

                <div className="button-logo-container">
                  <div
                    className="button-logo"
                    onClick={async () => {
                      try {
                        setLoading(true);
                        let res: any = await signInWithApple(setLoading);
                        let idToken = res.idToken;
                        await onSocialClick(idToken, LoginType.Apple);
                        setLoading(false);
                      } catch (err) {
                        setLoading(false)
                      }

                    }}
                  >
                    <img
                      src={require("./images/apple.png")}
                      className="w-8 h-8 object-contain"
                      alt="apple"
                    />
                  </div>
                  <div>
                    Apple
                  </div>
                </div>

                <div className="relative">
                  {Config.MainnetUnsupportedChain.includes(LoginType.Email) && <div className="disabled-login-button-text">Coming soon</div>}
                  <div className={`button-logo-container ${Config.MainnetUnsupportedChain.includes(LoginType.Email) && "disabled-login-button"}`}>
                    <div
                      className="button-logo"
                      onClick={() => {
                        setIsEmailClick(true);
                      }}
                    >
                      <img
                        src={require("./images/mail.png")}
                        className="w-8 h-8 object-contain"
                        alt="mail"
                      />
                    </div>
                    <div>
                      Email
                      {/* Username */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <p className="mt-3 text-[#474c6f] text-sm font-cookie-run">
          By continuing, you agree with our {" "}
          <a
            className="underline"
            href="https://whitepaper.monsterra.io/policy/terms-of-use"
            target="_blank"
            rel="noreferrer"
            style={{
              color: "white",
            }}
          >
            Term of Service
          </a>
        </p>
      </div>
      <p className="py-3 relative h-[40px] text-[#21243C]">
        <div className="absolute right-[20px] top-[3px] text-sm font-cookie-run">
          Version: {SSO_VERSION()?.generalVersion}_{SSO_VERSION()?.backend || 0}_{SSO_VERSION()?.sso || 0}_{SSO_VERSION()?.web || 0}
        </div>
      </p>
    </div>
  );
}
