import RarityFilter from "component/rarity-filter";
import gameConfig from "config/game-config";
import useCustomer from "hooks/useCustomer";
import useInventory from "hooks/useInventory";
import useUI from "hooks/useUI";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import api from "services/api";
import utils from "services/utils";
import { MongenThumbnail } from "../../component/thumbnail/mongen.thumbnail";
import { PlotThumbnail } from "../../component/thumbnail/plot.thumbnail";
import {
  MapPlotType,
  MongenState,
  NFTType,
  PlotType,
  Rarity,
  SetupStage,
} from "../../config/interface";
import MongenEntity from "../../config/mongen.entity";
import PlotEntity from "../../config/plot.entity";
import inventorySlice, {
  activeMongen,
  activePlot,
} from "../../slices/inventory.slice";
import { RootState } from "../../store";
enum Order {}
export default function PlotPicker({
  stage,
  changeStage,
}: {
  stage: SetupStage;
  changeStage: Function;
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const ui = useUI();
  const nav = useNavigate();
  const cs = useCustomer();
  const inven = useInventory();
  const [rarity, setRarity] = useState<Rarity>(5);
  const [sortField, setSortField] = useState<"id" | "rarity" | "capacity">(
    "id"
  );
  const {
    mongenInfos,
    plotInfos,
    plotIds,
    mongenIds,
    totalMongen,
    requireMongen,
    mapItems,
  } = useSelector((state: RootState) => state.inventorySlice);
  const { stake } = useSelector((state: RootState) => state.userSlice);
  const [max, setMax] = useState<number[]>([0, 0]);
  function onRarityButtonClick(r: Rarity) {
    setRarity(r);
  }
  function isFull() {
    if ([SetupStage.PickLandcore, SetupStage.AttachStorage].includes(stage)) {
      return false;
    }
    return max[0] >= max[1];
  }
  async function onPlotClick(id: number, plotType: PlotType) {
    try {
      if (isFull() && !plotIds.includes(id)) {
        return;
      }
      let plotInfo = plotInfos.find((p) => p.id === id);
      switch (plotInfo.plot_type) {
        case PlotType.Storage:
          await checkStorage(plotInfo);
          break;
        case PlotType.LandCore:
          await checkLandcore(plotInfo);
          break;
        case PlotType.Pasture:
          await checkHabitat(plotInfo);
          break;
      }
      dispatch(activePlot({ id, plotType }));
    } catch (error) {
      console.error(error);
    }
  }

  async function checkHabitat(plot: PlotEntity) {
    let pastures = plotInfos.filter(
      (p) =>
        p.is_click2earn && p.plot_type === PlotType.Pasture && p.id !== plot.id
    );
    let old = plotInfos.find((p) => p.is_click2earn && p.id === plot.id);
    if (!old) {
      //add new
      return;
    }
    let maxMongen = pastures.reduce((a: number, p: PlotEntity) => {
      let config = gameConfig.getItemInfo(NFTType.Plot, PlotType.Pasture);
      return a + config.limit_mongen[p.rarity];
    }, 0);
    let mongenCount = mongenInfos.filter((m) => m.is_click2earn).length;
    if (mongenCount > maxMongen) {
      await ui.openConfirm(t("setup.confirm_remove_mongen"));
      autoRemove(plot.id, null, null);
    }
  }
  async function checkLandcore(plot: PlotEntity) {
    let currentPlot = plotInfos.find(
      (p) => p.plot_type === PlotType.LandCore && p.is_click2earn
    );

    if (!currentPlot) {
      //add new
      return;
    }
    if (currentPlot.id === plot.id) {
      //remove all
      await ui.openConfirm(t("setup.confirm_remove_landcore"));
      autoRemove(currentPlot.id, null, null);
    }
    let config = gameConfig.getItemInfo(NFTType.Plot, PlotType.LandCore);
    let max = config.limit_habitat[plot.rarity];
    let habitatCount = plotInfos.filter(
      (p) => p.plot_type === PlotType.Pasture && p.is_click2earn
    ).length;
    if (max < habitatCount) {
      await ui.openConfirm(t("setup.confirm_remove_landcore"));
      autoRemove(currentPlot.id, plot.id, null);
    }
  }
  async function checkStorage(plot: PlotEntity) {
    let currentStorage = plotInfos.find(
      (p) => p.plot_type === PlotType.Storage && p.is_click2earn
    );

    if (!currentStorage) {
      //add new
      return;
    }
    let maxMutate = gameConfig.getItemInfo(NFTType.Plot, PlotType.Storage)
      .max_mutatedgen[plot.rarity];
    let maxElixir = gameConfig.getItemInfo(NFTType.Plot, PlotType.Storage)
      .max_elixir[plot.rarity];
    if (currentStorage.id === plot.id) {
      //remove
      maxMutate = 0;
      maxElixir = 0;
    }
    // if (maxMutate < stake.mutatedRevenue || maxElixir < stake.elixirRevenue) {
    //   await claimToken();
    // }
  }
  async function claimToken() {
    await ui.openConfirm(t("setup.confirm_claim_token"));
    let customerInfo = await api.claimStake();
    cs.updateCustomerInfo(customerInfo);
    cs.refreshStake();
  }
  function autoRemove(
    removePlotId: number,
    addPlotId: number,
    removeMongenId: number
  ) {
    let newPlotInfos = _.cloneDeep(plotInfos).filter((p) => {
      if (addPlotId === p.id) return true;
      if (!p.is_click2earn) return false;
      if (removePlotId && p.id === removePlotId) {
        return false;
      }
      return true;
    });
    let newMongenInfos = _.cloneDeep(mongenInfos).filter((p) => {
      if (!p.is_click2earn) return false;
      if (removeMongenId && p.id === removeMongenId) {
        return false;
      }
      return true;
    });
    let pRemoveIds: number[] = [],
      mRemoveIds: number[] = [];
    let landCore = newPlotInfos.find((p) => p.plot_type === PlotType.LandCore);
    if (!landCore) {
      pRemoveIds = newPlotInfos.map((i) => i.id);
      mRemoveIds = newMongenInfos.map((i) => i.id);
      inven.removeItems({ plotIds: pRemoveIds, mongenIds: mRemoveIds });
      return;
    }
    const habitats = newPlotInfos
      .filter((p) => p.plot_type === PlotType.Pasture)
      .sort((a, b) => a.rarity - b.rarity);
    const others = newPlotInfos
      .filter(
        (p) =>
          ![PlotType.LandCore, PlotType.Pasture, PlotType.Storage].includes(
            p.plot_type
          )
      )
      .sort((a, b) => {
        let aConfig = gameConfig.getItemInfo(NFTType.Plot, a.plot_type);
        let bConfig = gameConfig.getItemInfo(NFTType.Plot, b.plot_type);
        return aConfig.points[a.rarity] - bConfig.points[b.rarity];
      });

    let config = gameConfig.getItemInfo(NFTType.Plot, PlotType.LandCore);

    if (habitats.length > config.limit_habitat[landCore.rarity]) {
      pRemoveIds = pRemoveIds.concat(
        habitats
          .splice(0, habitats.length - config.limit_habitat[landCore.rarity])
          .map((i) => i.id)
      );
    }
    let habitatConfig = gameConfig.getItemInfo(NFTType.Plot, PlotType.Pasture);
    let maxMongen = habitats.reduce(
      (a, m) => a + habitatConfig.limit_mongen[m.rarity],
      0
    );
    if (newMongenInfos.length > maxMongen) {
      mRemoveIds = mRemoveIds.concat(
        newMongenInfos
          .splice(0, newMongenInfos.length - maxMongen)
          .map((i) => i.id)
      );
    }
    if (others.length > newMongenInfos.length) {
      pRemoveIds = pRemoveIds.concat(
        others.splice(0, others.length - newMongenInfos.length).map((i) => i.id)
      );
    }
    inven.removeItems({ plotIds: pRemoveIds, mongenIds: mRemoveIds });
  }

  async function onMongenClick(id: number) {
    try {
      if (isFull() && !mongenIds.includes(id)) {
        return;
      }
      let mongen = mongenInfos.find((m) => m.id === id);
      await checkMongen(mongen);
      dispatch(activeMongen({ id }));
    } catch (error) {}
  }
  async function checkMongen(mongen: MongenEntity) {
    let mongens = mongenInfos.filter(
      (m) => m.is_click2earn && m.id !== mongen.id
    );
    let habitatCount = plotInfos.filter((p) => {
      if (!p.is_click2earn) return false;
      if (
        [PlotType.LandCore, PlotType.Storage, PlotType.Pasture].includes(
          p.plot_type
        )
      ) {
        return false;
      }
      return true;
    }).length;
    if (habitatCount > mongens.length) {
      await ui.openConfirm(t("setup.confirm_remove_plot"));
      autoRemove(null, null, mongen.id);
    }
  }
  useEffect(() => {
    switch (stage) {
      case SetupStage.PickLandcore:
        let landCores = mapItems[MapPlotType.Landcore]?.length || 0;
        setMax([landCores, 1]);
        break;
      case SetupStage.AttachStorage:
        let storages = mapItems[MapPlotType.Storage]?.length || 0;
        setMax([storages, 1]);
        break;
      case SetupStage.AttachHabitat:
        let habitats = mapItems[MapPlotType.Habitat]?.length || 0;
        let landCore = plotInfos.find(
          (p: PlotEntity) =>
            p.is_click2earn && p.plot_type === PlotType.LandCore
        );
        if (landCore) {
          let config = gameConfig.getItemInfo(NFTType.Plot, PlotType.LandCore);
          setMax([habitats, config.limit_habitat[landCore.rarity]]);
        }
        break;
      case SetupStage.AttachMongen:
        let mongenCountA = mongenInfos.filter(
          (m: MongenEntity) => m.is_click2earn
        ).length;
        setMax([mongenCountA, totalMongen]);
        break;
      case SetupStage.AttachPlot:
        let mongenCount = mongenInfos.filter(
          (m: MongenEntity) => m.is_click2earn
        ).length;
        let plotCount = plotInfos.filter((p: PlotEntity) => {
          if (!p.is_click2earn) {
            return false;
          }
          return ![
            PlotType.LandCore,
            PlotType.Pasture,
            PlotType.Storage,
          ].includes(p.plot_type);
        }).length;
        setMax([plotCount, mongenCount]);
        break;
    }
  }, [mongenInfos, plotInfos, mapItems, stage]);
  return (
    <>
      <div
        className={`absolute top-5 left-4 ${
          max[0] <= max[1] ? "text-white" : "text-red-500"
        }`}
      >
        <p className={`text-2xl font-bold  flex gap-3`}>
          <p>{t("setup.max_item")}:</p>
          <p>
            {max[0]}/{max[1]}
          </p>
        </p>
      </div>
      <div className="ui-blank_pick_landplot w-full p-4">
        <div className="flex items-center justify-center gap-8">
          {stage === SetupStage.PickLandcore && (
            <button className="ui-arrow_left_off"></button>
          )}
          {stage !== SetupStage.PickLandcore && (
            <button
              className="ui-arrow_left_on"
              onClick={() => {
                changeStage(stage - 1);
              }}
            ></button>
          )}

          <p className="text-3xl w-56 text-center">
            {t(`setup.${SetupStage[stage].toLowerCase()}`)}
          </p>
          {stage === SetupStage.AttachPlot && (
            <button className="ui-arrow_right_off"></button>
          )}
          {stage !== SetupStage.AttachPlot && (
            <button
              className="ui-arrow_right_on"
              onClick={() => {
                changeStage(stage + 1);
              }}
            ></button>
          )}
        </div>
        <div className="mt-4">
          <RarityFilter
            setSortField={(sort: "id" | "rarity" | "capacity") =>
              setSortField(sort)
            }
            setRarity={(r: Rarity) => setRarity(r)}
            rarity={rarity}
            sort={sortField}
          />
        </div>

        <div className="overflow-y-hidden overflow-x-auto w-full h-[290px] mt-4">
          <div className="flex">
            {stage !== SetupStage.AttachMongen &&
              _.cloneDeep(plotInfos)
                .filter((p: PlotEntity) => {
                  if (p.plot_type === PlotType.Tree) return false;
                  switch (stage) {
                    case SetupStage.PickLandcore:
                      if (p.plot_type !== PlotType.LandCore) return false;
                      break;
                    case SetupStage.AttachStorage:
                      if (p.plot_type !== PlotType.Storage) return false;
                      break;
                    case SetupStage.AttachPlot:
                      if (
                        p.plot_type === PlotType.LandCore ||
                        p.plot_type === PlotType.Storage ||
                        p.plot_type === PlotType.Pasture
                      )
                        return false;
                      break;
                    case SetupStage.AttachHabitat:
                      if (p.plot_type !== PlotType.Pasture) {
                        return false;
                      }
                      break;
                  }
                  if (rarity === Rarity.All) return true;
                  return p.rarity === rarity;
                })
                .sort((a: PlotEntity, b: PlotEntity) => {
                  // @ts-ignore
                  return b[sortField] - a[sortField];
                })
                .map((p: PlotEntity) => (
                  <div key={p.id}>
                    <PlotThumbnail
                      active={plotIds?.includes(p.id)}
                      plotInfo={p}
                      onClick={() => {
                        onPlotClick(p.id, p.plot_type);
                      }}
                    />
                  </div>
                ))}
            {stage === SetupStage.AttachMongen &&
              _.cloneDeep(mongenInfos)
                .filter((p: MongenEntity) => {
                  if (p.mongen_state === MongenState.Egg) return false;
                  if (rarity === Rarity.All) return true;
                  return utils.getDNARarity(p.dna) === rarity;
                })
                .sort((a: MongenEntity, b: MongenEntity) => {
                  // @ts-ignore
                  return b[sortField] - a[sortField];
                })

                .map((p: MongenEntity) => (
                  <div key={p.id}>
                    <MongenThumbnail
                      active={mongenIds.includes(p.id)}
                      mongenInfo={p}
                      onClick={() => {
                        onMongenClick(p.id);
                      }}
                    />
                  </div>
                ))}
            <div className="flex items-center">
              <div
                className="ui-button_start_fusion relative p-1 interactive"
                onClick={() => {
                  nav("/inventory/on");
                }}
              >
                <p className="text-4xl text-center mt-6">
                  {t("setup.import_nft")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
