import { useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import api from "services/api";
import { SignupByEmail } from "./signup-email";
export default function EmailConnector({
    onDone,
    onBack,
    setShowLogin,
    type,
}: {
    onDone: Function;
    onBack: Function;
    setShowLogin: Function;
    type: "signin" | "signup";
}) {
    const [input, setInput] = useState<any>({});
    const [showPassword, setShowPassword] = useState(false);
    const { t } = useTranslation();
    const [showSignup, setShowSignup] = useState("");

    function handleChange(name: string, value: any) {
        let tmp: any = { ...input };
        tmp[name] = value;
        setInput(tmp);
    }
    async function login() {
        console.log({ showPassword, input })
        if (showPassword) {
            try {
                if (!(input.username && input.password)) {
                    toast(t("required_username_and_password"));
                    return;
                }
                let { customerInfo, token, authInfos } = await api.post("/click2earn/login-customer", {
                    username: input.username,
                    password: input.password,
                    type: 2,
                });
                setShowLogin(false);
                sessionStorage.setItem("token", token);
                onDone();
            } catch (err: any) {
                if (err.message) {
                    toast(t(err.message));
                }
            }
        } else {
            if (!input.username) {
                toast(t("required_username_or_email"));
                return;
            }
            let rs = await api.post("/customer/check-username", {
                username: input.username,
                type: 2,
            });
            if (rs.exist) {
                setShowPassword(true);
            } else {
                // toast(t("customer_not_found"));

                // HANDLE EMAIL REGISTER
                if (!input.username.match(
                    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                )) {
                    toast(t("customer_not_found"));
                } else {
                    setShowSignup(input.username)
                }
            }
        }
    }
    const registerByEmail = async (email: string) => {
        try {
            await api.sendRegisterEmail(email);
            onBack();
            toast(t("success_send_email_request"));
        } catch (err: any) {
            if (err.message) {
                toast(t(err.message));
            }
        }
    };

    if (showSignup) {
        return <SignupByEmail username={showSignup} closeModal={() => setShowSignup("")} />
    }

    return (
        <div>
            <div
                className="bg bg-contain px-1 w-[500px] max-w-full font-cookie-run"
                style={{ paddingBottom: 25 }}
            >
                <p className="text-3xl py-2 pt-4 text-outline">{t("Email")}</p>
                <div className="bg-[#22253a] p-4">
                    <div className="">
                        <input
                            onChange={(evt: any) => {
                                handleChange("username", evt.target.value);
                            }}
                            placeholder={t("Email")}
                            type="text"
                            className="w-full text-2xl text-center p-2 bg-[#5b628a] rounded-md mt-2 text-white"
                        />
                    </div>
                    {showPassword && (
                        <div className="mt-2">
                            <input
                                onChange={(evt: any) => {
                                    handleChange("password", evt.target.value);
                                }}
                                placeholder={t("password")}
                                type="password"
                                className="w-full text-2xl text-center p-2 bg-[#5b628a] rounded-md mt-2 text-white"
                            />
                        </div>
                    )}
                    <div className="flex gap-4 text-lg">
                        <button
                            type="button"
                            className="p-2 large w-full mt-3 button-icon"
                            onClick={() => {
                                onBack();
                            }}
                        >
                            <p className="text-outline">{t("Back")}</p>
                        </button>
                        <button className="p-2 large w-full mt-3 button-icon"
                            onClick={async (evt) => {
                                evt.preventDefault();
                                if (type === "signin") {
                                    await login();
                                } else {
                                    await registerByEmail(input.username);
                                }
                            }}
                        >
                            <p className="text-outline">
                                {showPassword ? t("login") : t("Continue")}
                            </p>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
