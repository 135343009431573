import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Environment from "../../config/environment";
import {
  MapPlotType,
  PlotSize,
  PlotType,
  Rarity,
} from "../../config/interface";
import { RootState } from "../../store";
import PlotBase from "../plot";

export default function Map({
  focus,
  onPlotClick,
}: {
  focus?: MapPlotType;
  onPlotClick?: Function;
}) {
  const [rarity, setRarity] = useState<Rarity>(Rarity.Legendary);
  const mapItems = useSelector(
    (state: RootState) => state.inventorySlice.mapItems
  );
  const [pos, setPos] = useState<number[]>([0, 0]);
  useEffect(() => {
    if (focus === undefined) {
      return setPos([0, 0]);
    }
    setPos(focusPos[focus]);
  }, [focus]);
  const position: { [key in MapPlotType]: number[] } = {
    [MapPlotType.Landcore]: [346, 687, 1],
    [MapPlotType.Habitat]: [443, 840, 1],
    [MapPlotType.Workshop]: [640, 419, 1],
    [MapPlotType.Storage]: [160, 942, 1],
    [MapPlotType.Defense]: [173, 529, 1],
    [MapPlotType.Tree]: [173, 529, 1],
  };
  const focusPos: { [key in MapPlotType]: number[] } = {
    [MapPlotType.Landcore]: [-110, -69, 1.2],
    [MapPlotType.Habitat]: [-263, -616, 1.2],
    [MapPlotType.Workshop]: [-181, 143, 0.7],
    [MapPlotType.Storage]: [247, -380, 1.2],
    [MapPlotType.Defense]: [-110, -69, 1.2],
    [MapPlotType.Tree]: [-110, -69, 1.2],
  };
  function onClick(type: MapPlotType) {
    if (onPlotClick) {
      onPlotClick(type);
    }
  }
  const [foamScale, setFoamScale] = useState<number>(1);
  useEffect(() => {
    let time = setInterval(() => {
      setFoamScale((scale: number) => {
        if (scale === 1) {
          return 1.05;
        } else {
          return 1;
        }
      });
    }, 4000);
    return () => {
      clearInterval(time);
    };
  });
  return (
    <>
      {focus !== undefined && (
        <div className="ui-focus absolute top-0 left-0 z-10"></div>
      )}
      <div
        className="absolute duration-500"
        style={{
          top: pos[0],
          left: pos[1],
          transform: `scale(${pos[2] || 1})`,
        }}
      >
        <div className="map-ocean relative">
          <div
            className="map-ocean_foam duration-[4s]"
            style={{ transform: `scale(${foamScale})` }}
          ></div>
          <div className="z-[2] absolute top-0 left-0 w-full h-full flex justify-center items-center">
            <div className="map-land relative">
              {mapItems[MapPlotType.Landcore] && (
                <div
                  className="absolute"
                  style={{
                    top: position[MapPlotType.Landcore][0],
                    left: position[MapPlotType.Landcore][1],
                  }}
                >
                  <PlotBase
                    onClick={() => {
                      onClick(MapPlotType.Landcore);
                    }}
                    plot_type={MapPlotType.Landcore}
                    rarity={mapItems[MapPlotType.Landcore].rarity}
                    size={mapItems[MapPlotType.Landcore].size}
                  />
                </div>
              )}
              {mapItems[MapPlotType.Habitat] && (
                <div
                  className="absolute"
                  style={{
                    top: position[MapPlotType.Habitat][0],
                    left: position[MapPlotType.Habitat][1],
                  }}
                >
                  <PlotBase
                    onClick={() => {
                      onClick(MapPlotType.Habitat);
                    }}
                    plot_type={MapPlotType.Habitat}
                    rarity={
                      mapItems[MapPlotType.Landcore]?.rarity || Rarity.Common
                    }
                    size={mapItems[MapPlotType.Habitat].size}
                  />
                </div>
              )}
              {mapItems[MapPlotType.Workshop] && (
                <div
                  className="absolute"
                  style={{
                    top: position[MapPlotType.Workshop][0],
                    left: position[MapPlotType.Workshop][1],
                  }}
                >
                  <PlotBase
                    onClick={() => {
                      onClick(MapPlotType.Workshop);
                    }}
                    plot_type={MapPlotType.Workshop}
                    rarity={
                      mapItems[MapPlotType.Landcore]?.rarity || Rarity.Common
                    }
                    size={mapItems[MapPlotType.Workshop].size}
                  />
                </div>
              )}
              {mapItems[MapPlotType.Storage] && (
                <div
                  className="absolute"
                  style={{
                    top: position[MapPlotType.Storage][0],
                    left: position[MapPlotType.Storage][1],
                  }}
                >
                  <PlotBase
                    onClick={() => {
                      onClick(MapPlotType.Storage);
                    }}
                    plot_type={MapPlotType.Storage}
                    rarity={
                      mapItems[MapPlotType.Landcore]?.rarity || Rarity.Common
                    }
                    size={mapItems[MapPlotType.Storage].size}
                  />
                </div>
              )}
              {mapItems[MapPlotType.Defense] && (
                <div
                  className="absolute"
                  style={{
                    top: position[MapPlotType.Defense][0],
                    left: position[MapPlotType.Defense][1],
                  }}
                >
                  <PlotBase
                    onClick={() => {
                      onClick(MapPlotType.Defense);
                    }}
                    plot_type={MapPlotType.Defense}
                    rarity={
                      mapItems[MapPlotType.Landcore]?.rarity || Rarity.Common
                    }
                    size={mapItems[MapPlotType.Defense].size}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
