import { Rarity } from "config/interface";
import { useTranslation } from "react-i18next";

export default function RarityFilter({
  setSortField,
  rarity,
  setRarity,
  sort,
  disabledRarity = []
}: {
  setSortField: Function;
  setRarity: Function;
  rarity: number;
  sort: "id" | "rarity" | "capacity";
  disabledRarity?: Rarity[]
}) {
  const { t } = useTranslation();
  function onRarityButtonClick(r: Rarity) {
    if (setRarity) {
      setRarity(r);
    }
  }
  return (
    <div className="flex gap-2">
      <select
        className="text-black text-xl mr-10"
        onChange={(evt) => {
          setSortField(evt.target.value);
        }}
        value={sort}
      >
        <option value={"id"}>Order by id</option>
        <option value={"rarity"}>Order by rarity</option>
        <option value={"capacity"}>Order by capacity</option>
      </select>
      <button
        onClick={() => {
          onRarityButtonClick(Rarity.All);
        }}
        className={`interactive text-xl ${rarity === Rarity.All ? "ui-button_all_grey" : "ui-button_all_on"
          } text-black`}
      >
        {t("raritySort.all").toUpperCase()}
      </button>
      {!disabledRarity.includes(Rarity.Common) && <button
        onClick={() => {
          onRarityButtonClick(Rarity.Common);
        }}
        className={`interactive text-xl ${rarity === Rarity.Common ? "ui-button_CO_presed" : "ui-button_CO_on"
          } text-white`}
      >
        {t("raritySort.common").toUpperCase()}
      </button>
      }
      <button
        onClick={() => {
          onRarityButtonClick(Rarity.Uncommon);
        }}
        className={`interactive text-xl ${rarity === Rarity.Uncommon
          ? "ui-button_UC_pressed"
          : "ui-button_UC_on"
          } text-white`}
      >
        {t("raritySort.uncommon").toUpperCase()}
      </button>
      <button
        onClick={() => {
          onRarityButtonClick(Rarity.Rare);
        }}
        className={`interactive text-xl ${rarity === Rarity.Rare ? "ui-button_RA_pressed" : "ui-button_RA_on"
          } text-white`}
      >
        {t("raritySort.rare").toUpperCase()}
      </button>
      <button
        onClick={() => {
          onRarityButtonClick(Rarity.Epic);
        }}
        className={`interactive text-xl ${rarity === Rarity.Epic ? "ui-button_EP_pressed" : "ui-button_EP_on"
          } text-white`}
      >
        {t("raritySort.epic").toUpperCase()}
      </button>
      {!disabledRarity.includes(Rarity.Legendary) && <button
        onClick={() => {
          onRarityButtonClick(Rarity.Legendary);
        }}
        className={`interactive text-xl ${rarity === Rarity.Legendary
          ? "ui-button_LE_pressed"
          : "ui-button_LE_on"
          } text-white`}
      >
        {t("raritySort.legendary").toUpperCase()}
      </button>
      }
    </div>
  );
}
