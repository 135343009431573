import { LoginType } from "config/interface";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import api from "services/api";
import { removeDialog } from "slices/ui.slice";

export const Signup = ({ dialog, setShowLogin }: any) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const registerBySavedToken = async () => {
    try {
      await api.registerWithSavedToken(
        dialog.token,
        LoginType.SavedToken,
        setShowLogin,
        dialog.password,
        dialog.referral
      );
    } catch (err) {
      throw err;
    } finally {
      dispatch(removeDialog({ id: dialog.id }));
    }
  };

  return (
    <div className="absolute bg-popup w-full h-full top-0 left-0 font-cookie-run">
      <div
        className="absolute w-full h-full mx-auto"
        style={{
          top: "calc(50% - 200px)",
        }}
      >
        <div className="w-full">
          <div
            className="bg bg-contain px-1 w-[510px] max-w-full mx-auto text-center"
            style={{ paddingBottom: 25 }}
          >
            <p className="text-3xl py-4 text-white pt-6">
              {t("user_not_found_title")}
            </p>
            <div className="bg-[#F3E9DF] py-12 text-[#747594] text-[18px]">
              <div>{t("user_not_found_1")}</div>
              <div>{t("user_not_found_2")}</div>
            </div>
            <div className="mt-6 mx-14">
              <button
                className="h-[48px] w-[110px] red-art-button mr-8 text-white button-icon"
                color="red"
                onClick={() => {
                  dispatch(removeDialog({ id: dialog.id }));
                }}
                disabled={loading}
              >
                Cancel
              </button>
              <button
                className="h-[48px] w-[110px] green-art-button ml-8 text-white button-icon"
                color="yellow"
                disabled={loading}
                onClick={registerBySavedToken}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
