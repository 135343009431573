import { useTranslation } from "react-i18next";
import styled, { keyframes } from "styled-components";
import { rotateOutUpRight as anim, pulse } from "react-animations";
import { useSelector } from "react-redux";
import { RootState } from "store";
import MongenEntity from "config/mongen.entity";
import Mongen from "component/static-anim/mongen";
import { FusionThumbnail } from "component/thumbnail/fusion.thumbnail";
import PlotEntity from "config/plot.entity";
import { PlotThumbnail } from "component/thumbnail/plot.thumbnail";
import useInventory from "hooks/useInventory";
const animName = keyframes`${anim}`;
const AnimDiv = styled.div`
  animation: 4s ${animName} infinite;
`;
export default function DialogImport() {
  const { t } = useTranslation();
  const inven = useInventory();
  const { importedItems } = useSelector(
    (state: RootState) => state.inventorySlice
  );
  function onCloseClick() {
    inven.resetImportedItems();
  }
  return (
    (importedItems.mongenInfos.length > 0 ||
      importedItems.plotInfos.length > 0) && (
      <div className="absolute top-0 left-0 h-full w-full bg-black bg-opacity-90 z-50">
        <div className="flex relative w-full h-full items-center justify-center">
          <div className="ui-popup_import relative">
            <p className="text-[#fdf3a3] font-bold text-3xl mt-5 text-center">
              {t("import.import")}
            </p>
            <p className="mt-8 px-4 text-2xl w-full flex justify-center items-center">
              {t("import.items_ready")}
            </p>
            <div className="px-4 mt-4">
              <div className="w-full overflow-x-auto overflow-y-hidden h-[250px] relative">
                <div className="flex">
                  {importedItems.mongenInfos?.map((m: MongenEntity) => (
                    <div className="relative inline-block">
                      <FusionThumbnail mongenInfo={m} />
                    </div>
                  ))}
                  {importedItems.plotInfos?.map((m: PlotEntity) => (
                    <div className="relative inline-block">
                      <PlotThumbnail plotInfo={m} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <button
              className="ui-button_discard_fusion block mx-auto mt-4 interactive"
              onClick={() => {
                onCloseClick();
              }}
            >
              <p className="text-2xl">{t("import.close")}</p>
            </button>
          </div>
        </div>
      </div>
    )
  );
}
