import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
let id = 1;
function createId() {
  return id++;
}
export interface DialogInfo {
  id?: number;
  type:
    | "message"
    | "confirm"
    | "captcha"
    | "signup"
    | "setting"
    | "avatar"
    | "name"
    | "select_chain"
    | "leaderboard"
    | "community";
  content: string;
  meta?: any;
  callback: Function;
}
export interface UIInfo {
  dialogs: DialogInfo[];
  globalLoading: boolean;
}
const initialState: UIInfo = {
  dialogs: [],
  globalLoading: false,
};

export const uiSlice = createSlice({
  name: "dialog",
  initialState,
  reducers: {
    createDialog: (state, action) => {
      let payload = action.payload;
      let tmp = _.cloneDeep(state.dialogs);
      payload.id = createId();
      tmp.push(payload);
      state.dialogs = tmp;
    },
    removeDialog: (state, action) => {
      let { id } = action.payload;
      let tmp = _.cloneDeep(state.dialogs);
      tmp = tmp.filter((i: DialogInfo) => i.id !== id);
      state.dialogs = tmp;
    },
    updateGlobalLoading: (state, action) => {
      state.globalLoading = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { createDialog, removeDialog, updateGlobalLoading } =
  uiSlice.actions;

export default uiSlice.reducer;
