import { useTranslation } from "react-i18next";
import gameConfig from "../../config/game-config";
import {
  BarrackType,
  BombardType,
  CampType,
  MongenRace,
  NFTType,
  PlotType,
  Rarity,
  TowerType,
  TrapType,
} from "../../config/interface";
import PlotEntity from "../../config/plot.entity";
import Typo from "../typo";
import PlotImage from "./plot-image";

export function TreeThumbnail({
  plotInfo,
  active = false,
  onClick,
}: {
  onClick?: Function;
  plotInfo: PlotEntity;
  active?: boolean;
}) {
  const { t } = useTranslation();
  function onRefillClick() {
    console.log("refill");
  }
  function onThumbClick() {
    console.log("thumb click");
    if (onClick) {
      onClick();
    }
  }
  return (
    <div
      className="relative p-1 group interactive"
      onClick={() => {
        onThumbClick();
      }}
    >
      {active && (
        <div className="w-full h-full ui-border_hl_landplot_2 absolute top-0 left-0"></div>
      )}
      <div className="flex ui-blank_landplot gap-2 p-2 relative">
        <div className="w-1/2">
          <PlotImage plotInfo={plotInfo} />
        </div>
        <div className="w-1/2">
          <p>#{plotInfo.id}</p>
          <p>
            <Typo type="rarity" value={plotInfo.rarity} />
          </p>
          <p>
            <Typo type="plottype" value={plotInfo.plot_type} />
          </p>

          <button
            className="mt-2 ui-button_refill_avai"
            onClick={(e: any) => {
              e.stopPropagation();
              onRefillClick();
            }}
          >
            <p className="text-xl">{t("thumbnail.refill")}</p>
            <div className="w-full flex  items-center  gap-2 justify-center">
              <div className="ui-icon_mstr"></div>
              <p>
                {gameConfig.getGameConfig().FusionTreeRefill[plotInfo.level][1]}
              </p>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}
