import "./App.css";
import "./autogenerate/ui.css";
import "./autogenerate/map.css";
import "./autogenerate/anim.css";
import Layout from "./pages/Layout";
import { WelcomePage } from "pages/WelcomePage";
import { useEffect, useState } from "react";
import api from "services/api";
import { ToastContainer } from "react-toastify";
import DialogManager from "component/dialog";

function App() {
  const [showLogin, setShowLogin] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    api
      .getSSOMeta()
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {
        setTimeout(() => {
          setTrigger(!trigger);
        }, 2000);
      });
  }, [trigger]);

  if (isLoading) {
    return <></>
  }

  return (
    <div className="App">
      <ToastContainer hideProgressBar theme="dark" toastStyle={{
        fontSize: "12px",
        backgroundColor: "#505780"
      }} />
      {showLogin && <DialogManager
        showLogin={showLogin}
        setShowLogin={setShowLogin}
      />
      }
      {showLogin ? <WelcomePage
        showLogin={showLogin}
        setShowLogin={setShowLogin}
      /> :
        <Layout />
      }
    </div>
  );
}

export default App;
