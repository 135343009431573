import {
  BodyPart,
  Chain,
  ContractType,
  ContractTypeMap,
  LoginType,
  NFTType,
  NFTTypeMap,
  WALLET_PROVIDER,
} from "../config/interface";
import { toast } from "react-toastify";
import Environment from "../config/environment";
import { store } from "store";
import { clearSSOMeta, setSSOMeta } from "slices/ssometa.slice";
import gameConfig, { SSO_META } from "config/game-config";
import { createDialog } from "slices/ui.slice";
import {
  FTContract,
  getContract,
  NFTContract,
  NFTContractABI,
} from "./contract";
import { t } from "i18next";
import keplrService from "./keplr";

//@ts-ignore
const Web3 = window.Web3;
//@ts-ignore
const ethereum = window.ethereum;
var web3 = new Web3(Web3.givenProvider);

async function get(url: string): Promise<any> {
  let rs = await fetch(`${Environment.API_HOST}${url}`, {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  });
  switch (rs.status) {
    case 200:
      let tmp = await rs.json();
      return tmp;
    case 403:
    case 405:
      localStorage.clear();
      sessionStorage.clear();
      throw new Error("forbidden");
    default:
      let err = await rs.json();
      throw err;
  }
}

async function post(url: string, data: any): Promise<any> {
  let rs = await fetch(`${Environment.API_HOST}${url}`, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
    body: JSON.stringify(data),
  });
  switch (rs.status) {
    case 200:
      let tmp = await rs.json();
      return tmp;
    case 403:
    case 405:
      localStorage.clear();
      sessionStorage.clear();
      throw new Error("forbidden");
    case 423:
      try {
        let tmp = await rs.json();
        const token = tmp.token;
        const type = tmp.type;
        if (type === LoginType.Email) {
          throw new Error(tmp.code);
          // let password = data.password;
          // ui.showSignup(token, type, password);
        } else {
          store.dispatch(
            createDialog({
              type: "signup",
              token,
              loginType: type,
            })
          );
        }
        throw new Error();
      } catch (error) {
        throw error;
      }
    default:
      let err = await rs.json();
      throw err;
  }
}
async function getAuth() {
  return post("/click2earn/get-auth", {});
}
async function getItems() {
  return post("/click2earn/get-items", {});
}
async function getLeaderboard() {
  return post("/click2earn/get-leaderboard", {});
}
async function editLand(mongenIds: number[], plotIds: number[]) {
  return post("/click2earn/edit-land", { plotIds, mongenIds });
}
async function getConfig() {
  return post("/click2earn/get-config", {});
}
async function getStakeInformation() {
  return post("/click2earn/get-stake-information", {});
}
async function updateCustomerProfile(input: {
  name?: string;
  avatar_id?: number;
}) {
  return post("/click2earn/update-customer-profile", input);
}
let count = 0;
async function getEvents(): Promise<any> {
  return post("/click2earn/get-game-events", { time: new Date().getTime() });
}
async function fusion(
  mainId: number,
  sacrificeIds: number[],
  nft_type: number
) {
  return post("/click2earn/fusion", { mainId, sacrificeIds, nft_type });
}
async function claimStake() {
  return post("/click2earn/claim-stake", {});
}
async function sendRegisterEmail(email: string) {
  let data = post("/customer/send-register-email", { email });
  return data;
}
async function getNonce() {
  let rs = await post("/nonce/create-nonce", {});
  return rs.nonce;
}

function convertLoginTypeToChain(type: number) {
  const Chain = {
    BSC: 0,
    Terra: 1,
    Avalanche: 2,
    Okex: 3,
  };
  let map = {
    [LoginType.BSC]: Chain.BSC,
    [LoginType.Terra]: Chain.Terra,
    [LoginType.Avalanche]: Chain.Avalanche,
    [LoginType.Okex]: Chain.Okex,
  };
  // @ts-ignore
  return map[type] + "";
}

async function login(type: LoginType) {
  window.localStorage.setItem("LoginType", convertLoginTypeToChain(type));
  try {
    let nonce = await getNonce();
    const account = await ethereum.request({ method: "eth_requestAccounts" });
    let sign = await web3.eth.personal.sign(
      nonce,
      account[0],
      type,
      "publicpassword"
    );
    let { customerInfo, token, authInfos } = await post(
      "/click2earn/login-customer",
      {
        sign,
        nonce,
        type,
      }
    );
    sessionStorage.setItem("token", token);
    return { customerInfo, token };
  } catch (error: any) {
    if (error.message === "customer_is_block") {
      toast(`You have been banned following reason below: ${error.message}`);
      throw new Error();
    }
    throw error;
  }
}

async function loginCosmos(type: LoginType) {
  window.sessionStorage.setItem("LoginType", type + "");
  try {
    let nonce = await getNonce();
    let accountAddress = await keplrService.connect(WALLET_PROVIDER.KEPLR);
    console.log({ accountAddress });
    if (!accountAddress) {
      throw new Error("Not connect keplr");
    }
    let signature = await keplrService.signMessage(accountAddress, nonce);
    let { customerInfo, token } = await post("/click2earn/login-customer", {
      sign: JSON.stringify(signature),
      nonce,
      type,
    });
    sessionStorage.setItem("token", token);
    return { customerInfo, token };
  } catch (error: any) {
    if (error.message === "customer_is_block") {
      toast(`You have been banned following reason below: ${error.message}`);
      throw new Error();
    }
    throw error;
  }
}


async function loginBySocial(social_access_token: string, type: LoginType) {
  try {
    let { customerInfo, token, authInfos } = await post(
      "/click2earn/login-customer",
      {
        social_access_token,
        type,
      }
    );
    sessionStorage.setItem("token", token);
    return { customerInfo, token };
  } catch (error: any) {
    if (error.message === "customer_is_block") {
      toast(`You have been banned following reason below: ${error.message}`);
      throw new Error();
    }
    throw error;
  }
}
async function getSSOMeta() {
  if (!sessionStorage.getItem("reload_metamask")) {
    console.log("reload")
    sessionStorage.setItem("reload_metamask", "true");
    window.location.reload();
  }
  const ssoMeta = await get("/customer/get-SSO-meta");
  store.dispatch(clearSSOMeta());
  store.dispatch(setSSOMeta(ssoMeta));
}

function getChainId(type: LoginType) {
  switch (type) {
    case LoginType.BSC:
    case LoginType.Avalanche:
    case LoginType.Okex:
      return SSO_META().network[LoginType[type]].chainId;
    case LoginType.Terra:
      return "pisco-1";
  }
}

async function changeNetwork(type: LoginType) {
  let chainData = SSO_META().network[LoginType[type]];
  // @ts-ignore
  if (!window.ethereum) {
    return;
  }
  try {
    console.log(chainData?.chainId);
    // @ts-ignore
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: chainData?.chainId }],
    });
  } catch (switchError: any) {
    console.log({ switchError });
    // This error code indicates that the chain has not been added to MetaMask.
    if (switchError.code === 4902) {
      console.log("Add net work");
      // @ts-ignore
      await window.ethereum
        .request({
          method: "wallet_addEthereumChain",
          params: [chainData],
        })
        .catch((error: any) => {
          console.log(error);
          throw error;
        });
    }
  }
}


async function checkNetwork(type: LoginType, src?: string) {
  console.log({ src, loginType: LoginType[type] });
  switch (type) {
    case LoginType.BSC:
    case LoginType.Avalanche:
    case LoginType.Okex:
      // @ts-ignore
      const currentNetwork = await window.ethereum.request({
        method: "eth_chainId",
      });
      if (getChainId(type) !== currentNetwork) {
        await changeNetwork(type);
        // @ts-ignore
        const changedNetwork = await window.ethereum.request({
          method: "eth_chainId",
        });
        if (getChainId(type) !== changedNetwork) {
          throw new Error("failed to switch network");
        }
      }
      break;
    case LoginType.Aura:
      await keplrService.keplrSuggestChain(type);
      await delay(500);
      break;
  }
}

const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

async function getNFTSignature(ids: number[], type: NFTType, chain: Chain) {
  return new Promise(async (resolve, reject) => {
    // @ts-ignore
    await checkNetwork(LoginType[Chain[chain]]);
    setTimeout(() => {
      return reject();
    }, 120000);
    try {
      // @ts-ignore
      await checkNetwork(LoginType[Chain[chain]], "deposit ft");

      let { signResult } = await post("/convert/get-signature", {
        ids,
        type,
        chain,
      });
      let { message, signature } = signResult;
      const { nonce, timestamp, ids: itemIds } = message;

      let contractType: ContractType, rs;
      switch (type) {
        case NFTType.Mongen:
          contractType = ContractType.Mongen;
          break;
        case NFTType.Plot:
          contractType = ContractType.Plot;
          break;
        case NFTType.SoulCore:
          contractType = ContractType.SoulCore;
          break;
        default:
          throw new Error("invalid_nft_type");
      }

      if (gameConfig.EtherChain.includes(chain)) {
        const account = await ethereum.request({
          method: "eth_requestAccounts",
        });
        let connectedWallet = message.user_address;
        if (
          connectedWallet?.toString().toLowerCase() !==
          account[0]?.toString().toLowerCase()
        ) {
          throw new Error("metamask_wallet_not_match");
        }

        NFTContract.options.address = getContract(contractType, chain);

        rs = await NFTContract.methods
          .mintBatch(
            itemIds,
            itemIds.map((i: any) => ""),
            itemIds.map((id: any) => NFTTypeMap[type]),
            nonce,
            timestamp,
            signature
          )
          .send({ from: message.user_address });
      } else if (gameConfig.CosmosChain.includes(chain)) {
        const keplrAddress = await keplrService.connect(WALLET_PROVIDER.KEPLR);
        let auraNFTContract = getContract(
          contractType,
          chain
        );
        rs = await keplrService.execute(keplrAddress, auraNFTContract, {
          mint_batch_with_signature: {
            msg: {
              token_ids: ids.map((id) => id.toString()),
              timestamp: timestamp.toString(),
              nonce,
            },
            signature
          },
        });
      }
      return resolve(rs);
    } catch (error: any) {
      store.dispatch(
        createDialog({
          type: "message",
          content: t(error?.message ?? error),
          callback: () => {
            resolve(null);
          },
        })
      );
      return reject(error);
    }
  });
}

async function depositNFT(
  ids: number[],
  type: NFTType,
  chain: Chain,
  authAdress: string
) {
  return new Promise(async (resolve, reject) => {
    setTimeout(() => {
      return reject();
    }, 120000);
    try {
      // @ts-ignore
      await checkNetwork(LoginType[Chain[chain]]);
      let contractType: ContractType;
      switch (type) {
        case NFTType.Plot:
          contractType = ContractType.Plot;
          break;
        case NFTType.Mongen:
          contractType = ContractType.Mongen;
          break;
        case NFTType.SoulCore:
          contractType = ContractType.SoulCore;
          break;
        default:
          throw new Error("invalid_nft_type");
      }

      // @ts-ignore
      await checkNetwork(LoginType[Chain[chain]], "deposit ft");
      let address = getContract(contractType, chain);

      if (gameConfig.EtherChain.includes(chain)) {
        const account = await ethereum.request({
          method: "eth_requestAccounts",
        });
        let connectedWallet = authAdress;
        if (
          connectedWallet?.toString().toLowerCase() !==
          account[0]?.toString().toLowerCase()
        ) {
          throw new Error("metamask_wallet_not_match");
        }
        if (!address) {
          throw new Error("contract_not_found");
        }
        NFTContract.options.address = address;
        await NFTContract.methods.stakeBatch(ids).send({ from: account[0] });
        resolve(null);
      } else if (gameConfig.CosmosChain.includes(chain)) {
        const keplrAddress = await keplrService.connect(WALLET_PROVIDER.KEPLR);
        let auraNFTContract = getContract(
          contractType,
          chain
        );
        await keplrService.execute(keplrAddress, auraNFTContract, {
          stake_batch: {
            token_ids: ids.map((id) => id.toString()),
          },
        });
        resolve(null);
      }
    } catch (error) {
      console.log("throw ");
      return reject(error);
    }
  });
}

async function getFtSignature(
  amount: number,
  type: NFTType,
  chain: Chain,
  contractType: ContractType
) {
  let { signResult } = await post("/click2earn/get-ft-signature", {
    amount,
    type,
    chain,
  });
  let { message, signature } = signResult;

  FTContract.options.address = getContract(contractType, chain);
  return await FTContract.methods
    .mint(message.amount, message.nonce, message.timestamp, signature)
    .send({ from: message.user_address });
}

async function depositFT(
  amount: string,
  chain: Chain,
  contractType: ContractType,
  authAdress: string
) {
  try {
    switch (chain) {
      case Chain.BSC:
      case Chain.Avalanche:
      case Chain.Okex:
        // @ts-ignore
        await checkNetwork(LoginType[Chain[chain]], "deposit ft");
        const account = await ethereum.request({
          method: "eth_requestAccounts",
        });
        let connectedWallet = authAdress;
        if (
          connectedWallet?.toString().toLowerCase() !==
          account[0]?.toString().toLowerCase()
        ) {
          throw new Error("metamask_wallet_not_match");
        }
        let address = getContract(contractType, chain);
        if (!address) {
          throw new Error("contract_not_found");
        }
        FTContract.options.address = address;
        await FTContract.methods.burn(amount).send({ from: account[0] });
        break;
      default:
        throw new Error("only_in_bsc_chain");
    }
  } catch (error) {
    throw error;
  }
}

async function sendRegisterEmailPw(
  email: string,
  password: string,
  setShowLogin?: any
) {
  let data;
  store.dispatch(
    createDialog({
      type: "captcha",
      callback: async (captcha_id: string, captcha_nonce: number) => {
        try {
          data = await post("/dao/send-email-register", {
            email,
            password,
            captcha_id,
            captcha_nonce,
          });
          setShowLogin && setShowLogin(false);
        } catch (err: any) {
          if (err.message) {
            toast(err.message);
          }
        }
      },
    })
  );
  return data;
}

async function getImage(url: string): Promise<any> {
  let token = sessionStorage.getItem("token");
  let rs = await fetch(`${Environment.API_HOST}${url}`, {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${token}`,
    },
  });
  switch (rs.status) {
    case 200:
      let tmp = await rs.blob();
      return tmp;
    case 403:
    case 405:
      console.log("222");
      sessionStorage.clear();
      throw new Error("forbidden");
    default:
      let err = await rs.blob();
      throw err;
  }
}

async function getCaptcha(id: string) {
  return getImage(`/captcha/get-image?id=${id}`);
}

async function getItemsOnchain(plotIds: number[], mongenIds: number[]) {
  return await api.post("/click2earn/get-items-onchain", {
    plotIds,
    mongenIds,
  });
}

function getRandomRpc(chain: Chain) {
  let network = SSO_META().network;
  let chainData = network[getKeyByValue(Chain, chain)];
  let rpcUrls = chainData?.rpcUrls ?? [];
  let randomRpc = rpcUrls[Math.floor(Math.random() * rpcUrls.length)];
  if ([Chain.BSC, Chain.Avalanche, Chain.Okex].includes(chain)) {
    let newWeb3 = new Web3(new Web3.providers.HttpProvider(randomRpc));
    return newWeb3;
  }
  return web3;
}

function getKeyByValue(object: any, value: any) {
  return Object.keys(object).find((key) => object[key] === value);
}

async function GetNftBalance(
  contractType: ContractType,
  chain = Chain.BSC,
  provider = web3,
  authAddress: string
) {
  let address = getContract(contractType, chain);
  if (gameConfig.EtherChain.includes(chain)) {
    const contract = new provider.eth.Contract(NFTContractABI, address);
    let data = await contract.methods.getOwnedToken(authAddress).call();
    console.log({ data, chain, contractType });
    return data.map((nft: any) => {
      const tokenId = nft.tokenId;
      const metadata = nft.nftData;
      return {
        tokenId: tokenId,
        type: metadata.nftType,
        chain_id: chain,
      };
    });
  } else if (gameConfig.CosmosChain.includes(chain)) {
    let data = await keplrService.getNFTBalance(authAddress, address);
    console.log({ data, chain, contractType })
    return data.map((tokenId: string) => {
      return {
        tokenId: Number(tokenId),
        // @ts-ignore
        type: ContractTypeMap[contractType],
        chain_id: chain,
      };
    })
  }
}

async function GetNfts(chain: Chain, authAddress: string) {
  // @ts-ignore
  await checkNetwork(LoginType[Chain[chain]]);
  let nfts: any = [];
  const getNfts = async (callback: any) => {
    const result = await callback;
    nfts = [...nfts, ...result];
  };

  // @ts-ignore
  await checkNetwork(LoginType[Chain[chain]], "get_nfts");

  try {
    let newProvider = getRandomRpc(chain);
    await Promise.allSettled([
      getNfts(
        GetNftBalance(ContractType.Mongen, chain, newProvider, authAddress)
      ),
      getNfts(
        GetNftBalance(ContractType.Plot, chain, newProvider, authAddress)
      ),
      getNfts(
        GetNftBalance(ContractType.SoulCore, chain, newProvider, authAddress)
      ),
    ]);
  } catch (err) {
    console.log(err);
  }
  let plotIds = nfts
    .filter((nft: any) => Number(nft.type) === NFTType.Plot)
    .map((nft: any) => Number(nft.tokenId));

  let mongenIds = nfts
    .filter((nft: any) =>
      [NFTType.Mongen, NFTType.SoulCore].includes(Number(nft.type))
    )
    .map((nft: any) => Number(nft.tokenId));

  let res = await getItemsOnchain(plotIds, mongenIds);
  return res;
}

async function registerWithSavedToken(
  token: string,
  type: LoginType,
  setShowLogin?: any,
  password?: string,
  referral?: string
) {
  store.dispatch(
    createDialog({
      type: "captcha",
      callback: async (captcha_id: string, captcha_nonce: number) => {
        try {
          let user_src = "",
            referral = "";
          if (window.location.href) {
            try {
              let search = window.location.href.split("?")[1];
              let query = new URLSearchParams(search);
              user_src = query.get("src");
              referral = query.get("code");
            } catch (error) { }
          }
          let geocountry = "unknown";
          try {
            // @ts-ignore
            let device = JSON.parse(await getDeviceInfo());
            geocountry = device.geocountry;
          } catch (error) {
            console.error("cannot get geo country");
          }
          let { clickToEarnToken, authInfos } = await api.post(
            "/dao/register",
            {
              type,
              token,
              captcha_id,
              captcha_nonce,
              password,
              user_src,
              geocountry,
              referral,
            }
          );
          sessionStorage.setItem("token", clickToEarnToken);
          setShowLogin && setShowLogin(false);
        } catch (err: any) {
          if (err.message) {
            toast(err.message);
          }
        }
      },
    })
  );
}

async function configMutatation(mongen_id: number, body_part: BodyPart) {
  return await api.post("/click2earn/config-mutated-part", {
    mongen_id,
    body_part,
  });
}

async function rerollMutation(mongen_id: number, body_part: BodyPart) {
  return await api.post("/click2earn/reroll-mutated-part", {
    mongen_id,
    body_part,
  });
}

async function discardMutation(mongen_id: number) {
  return await api.post("/click2earn/discard-mutated-part", {
    mongen_id,
  });
}

async function applyMutation(mongen_id: number) {
  return await api.post("/click2earn/apply-mutated-part", {
    mongen_id,
  });
}

export const CHAIN_MAP: { [key in LoginType]?: Chain } = {
  [LoginType.BSC]: Chain.BSC,
  [LoginType.Avalanche]: Chain.Avalanche,
  [LoginType.Okex]: Chain.Okex,
  [LoginType.Aura]: Chain.Aura,
};
const api = {
  getLeaderboard,
  updateCustomerProfile,
  getEvents,
  getAuth,
  registerWithSavedToken,
  getCaptcha,
  getItems,
  post,
  getConfig,
  editLand,
  getStakeInformation,
  fusion,
  claimStake,
  sendRegisterEmail,
  login,
  loginBySocial,
  getSSOMeta,
  checkNetwork,
  changeNetwork,
  getFtSignature,
  depositFT,
  getNFTSignature,
  depositNFT,
  sendRegisterEmailPw,
  GetNfts,
  configMutatation,
  applyMutation,
  discardMutation,
  rerollMutation,
  loginCosmos
};
export default api;
