import { useTranslation } from "react-i18next";
import styled, { keyframes } from "styled-components";
import { rotateOutUpRight as anim, pulse } from "react-animations";
import { useEffect, useState } from "react";
import api from "services/api";
import useUI from "hooks/useUI";
import { DialogInfo } from "slices/ui.slice";
import utils from "services/utils";
import { useSelector } from "react-redux";
import { RootState } from "store";
const animName = keyframes`${anim}`;
const AnimDiv = styled.div`
  animation: 4s ${animName} infinite;
`;
export default function DialogLeaderboard({ dialog }: { dialog: DialogInfo }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<any>([]);
  const { customerInfo } = useSelector((state: RootState) => state.userSlice);
  const ui = useUI();
  useEffect(() => {
    async function loadData() {
      setLoading(true);
      let rs = await api.getLeaderboard();
      rs.ownerData.name = `${customerInfo.prefix || ""}${customerInfo.name}`;
      rs.ownerData.avatar = customerInfo.avatar;
      setData(rs);
      setLoading(false);
    }
    loadData();
  }, []);
  function onClose() {
    ui.removeDialog(dialog.id);
  }
  function renderPrefix(prefix: string) {
    if (!prefix) return null;
    let clanName = prefix.substring(
      prefix.indexOf("["),
      prefix.indexOf("]") + 1
    );
    let color = prefix.substring(prefix.indexOf("#"), prefix.indexOf("#") + 7);
    return <span style={{ color }}>{clanName}</span>;
  }
  function renderName(name: string, id: number) {
    let pos = name?.indexOf("</color>");
    if (pos === -1)
      return (
        <p className="w-[250px] overflow-hidden text-ellipsis whitespace-nowrap">
          {name || `User #${id}`}
        </p>
      );
    return (
      <p className="w-[250px] overflow-hidden text-ellipsis whitespace-nowrap">
        {renderPrefix(name?.substring(0, pos + 8))}
        {name?.substring(pos + 8, name.length)}
      </p>
    );
  }
  function renderRank(rank: number) {
    if (rank === null) {
      return <p className="w-16 text-center text-xl">-</p>;
    }
    if (rank < 3) {
      return (
        <img
          src={`leaderboard/${rank + 1}.png`}
          className="w-16 h-16 object-contain"
        />
      );
    }
    return <p className="w-16 text-center text-xl">{rank + 1}</p>;
  }
  function renderItem(item: any, index: number) {
    return (
      <div className="flex gap-2 justify-between items-center">
        <div className="flex gap-2 items-center">
          {renderRank(index)}
          <img
            src={`avatar/avatar_${((item.avatar || 1) + "").padStart(
              3,
              "0"
            )}.webp`}
            className="w-14 h-14 rounded-md overflow-hidden interactive"
          />
          {renderName(item.name, item.customer_id)}
        </div>
        <p>
          {utils.convertToInternationalCurrencySystem(item.nftPoints)}{" "}
          {t("global.pts")}
        </p>
      </div>
    );
  }
  return (
    <div className="absolute top-0 left-0 h-full w-full  z-[90000]">
      <div className="flex relative w-full h-full items-center justify-center">
        <div className="ui-popup-leaderboard relative">
          <img
            alt="close"
            onClick={() => {
              onClose();
            }}
            src="close.png"
            className="absolute top-1 right-1 w-16 h-16 interactive"
          />
          <p className="text-[#fdf3a3] font-bold text-3xl mt-5 text-center">
            {t("global.leaderboard")}
          </p>
          {loading ? (
            <div className="flex justify-center mt-4 transform scale-50">
              <div className="ui-loading rotate"></div>
            </div>
          ) : (
            <div>
              <div className="w-[630px] mx-auto mt-8 h-[450px] overflow-y-auto overflow-x-hidden pr-6">
                {data.leaderboardData.map((item: any, index: number) => {
                  return <div className="mt-4">{renderItem(item, index)}</div>;
                })}
              </div>
              <div className="absolute left-0 bottom-4 w-full py-2">
                <div className="w-[620px] p-[5px] mx-auto pr-6 bg-[#3c3835] rounded-md">
                  {renderItem(data.ownerData, data.ownerData.position)}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
