import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FusionPlotThumbnail } from "../../component/thumbnail/fusion-plot.thumbnail";
import PlotImage from "../../component/thumbnail/plot-image";
import { TreeThumbnail } from "../../component/thumbnail/tree.thumbnail";
import gameConfig from "../../config/game-config";
import { PlotType } from "../../config/interface";
import PlotEntity from "../../config/plot.entity";
import useCustomer from "../../hooks/useCustomer";
import useInventory from "../../hooks/useInventory";
import useUI from "../../hooks/useUI";
import api from "../../services/api";
import utils from "../../services/utils";
import { RootState } from "../../store";

export default function SelectTree() {
  const { t } = useTranslation();
  const nav = useNavigate();
  const inventory = useInventory();
  const cs = useCustomer();
  const ui = useUI();
  const { mongenIds, plotIds } = useSelector(
    (state: RootState) => state.inventorySlice
  );
  function onBackClick() {
    nav(-1);
  }
  const { plotInfos } = useSelector((state: RootState) => state.inventorySlice);
  const { customerInfo } = useSelector((state: RootState) => state.userSlice);

  const trees = plotInfos.filter(
    (p: PlotEntity) => p.plot_type === PlotType.Tree
  );
  const activeTree = plotInfos.find(
    (p: PlotEntity) => p.plot_type === PlotType.Tree && p.is_click2earn
  );
  async function onSaveClick() {
    await ui.openConfirm(t("setup.confirmsave"));
    try {
      ui.showLoading();
      let { customerInfo } = await api.editLand(mongenIds, plotIds);
      cs.updateCustomerInfo(customerInfo);
      ui.openMessage("Save land success");
      nav(-1);
    } catch (error: any) {
      ui.openMessage(t(error.message));
    } finally {
      ui.hideLoading();
    }
  }
  return (
    <div className="ui-background p-4 absolute top-0 left-0 w-full h-full z-20">
      <div className="flex justify-between">
        <p className="text-texttitle text-[64px] font-bold">
          {t("worldtree.worldtree")}
        </p>
        <div>
          <div className="h-24 w-full flex justify-end gap-4 items-center">
            <div className="ui-blank_MSTR text-textnumberyellow font-bold flex items-center">
              <p className="text-3xl pl-20 mt-1">
                {utils.convertToInternationalCurrencySystem(customerInfo.mstr)}
              </p>
            </div>
            <div className="ui-blank_MAG text-textnumberyellow font-bold flex items-center">
              <p className="text-3xl pl-20 mt-1">
                {utils.convertToInternationalCurrencySystem(
                  customerInfo.staked_mag
                )}
              </p>
            </div>
            <button
              className="interactive ui-button_back_on"
              onClick={() => {
                onBackClick();
              }}
            >
              <p className="text-white text-3xl">{t("global.back")}</p>
            </button>
          </div>
        </div>
      </div>
      <div className="flex justify-between">
        <div className="w-[1200px] overflow-x-hidden overflow-y-auto h-[900px]">
          <div>
            {trees.map((t: PlotEntity) => (
              <div className="w-1/2 inline-block p-3" key={t.id}>
                <TreeThumbnail
                  plotInfo={t}
                  onClick={() => {
                    inventory.activeInventoryPlot(t.id, t.plot_type);
                  }}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="w-[650px]">
          <div className="w-full flex justify-center h-[600px] items-center">
            {activeTree ? (
              <div>
                <TreeThumbnail plotInfo={activeTree} />
              </div>
            ) : (
              <div className="ui-blank_world_tree_2 flex justify-center items-center">
                <div className="ui-added_worldtree_2"></div>
              </div>
            )}
          </div>
          <button
            className="ui-button_treeworld_Fusion interactive mx-auto block mt-8"
            onClick={() => {
              onSaveClick();
            }}
          >
            <p className="text-4xl">{t("worldtree.save")}</p>
          </button>
        </div>
      </div>
    </div>
  );
}
