import { useTranslation } from "react-i18next";
import gameConfig from "../../config/game-config";
import { NFTType, PlotType } from "../../config/interface";
import PlotEntity from "../../config/plot.entity";
import Typo from "../typo";
import PlotImage from "./plot-image";

export function PlotThumbnail({
  plotInfo,
  active = false,
  onClick,
  showInUse,
}: {
  showInUse?: boolean;
  onClick?: Function;
  plotInfo: PlotEntity;
  active?: boolean;
}) {
  const { t } = useTranslation();
  const nftPoints = gameConfig.getItemInfo(NFTType.Plot, plotInfo.plot_type);

  return (
    <div
      className="relative p-1 group interactive"
      onClick={() => {
        if (onClick) {
          if (showInUse && plotInfo.is_click2earn) {
            return;
          }
          onClick();
        }
      }}
    >
      {active && (
        <div className="w-full h-full ui-border_hl_mongen absolute top-[5px] left-[5px] z-10"></div>
      )}
      <div className="flex ui-blank_fusion_mongen gap-2 p-2 relative">
        {showInUse && plotInfo.is_click2earn && (
          <div className="absolute top-0 left-0 w-full h-full z-10 bg-black bg-opacity-75 rounded-[13px]">
            <div className="flex items-center justify-center w-full h-full">
              <p className="text-3xl">{t("inventory.in_use")}</p>
            </div>
          </div>
        )}
        <div
          className={`w-full ${
            [PlotType.Storage].includes(plotInfo.plot_type) ? "p-12" : "p-8"
          }`}
        >
          <PlotImage plotInfo={plotInfo} />
        </div>
        <div className="absolute top-0 left-0 w-full">
          <div className="flex w-full justify-between px-2 mt-1">
            <p>
              <Typo type="plottype" value={plotInfo.plot_type} />
            </p>
            <Typo type="rarity" value={plotInfo.rarity} />
          </div>
          <div className="flex w-full justify-between px-2 mt-1 text-sm">
            <p>#{plotInfo.id}</p>
            <p>Lv. {plotInfo.level}</p>
          </div>
        </div>
        <div className="absolute bottom-0 left-0 w-full">
          <div className="flex justify-between w-full text-sm px-2">
            <p>
              {t("thumbnail.mintvalue")}: {nftPoints?.points[plotInfo.rarity]}
            </p>
          </div>
          {plotInfo.plot_type === PlotType.LandCore && (
            <div className="flex justify-between w-full text-sm px-2">
              <p>
                {t("general_habitat_cap")}:{" "}
                {nftPoints?.limit_habitat?.[plotInfo.rarity]}
              </p>
            </div>
          )}
          {plotInfo.plot_type === PlotType.Storage && (
            <div className="flex justify-between w-full text-sm px-2">
              <p>
                {t("general_elixir_cap")}:{" "}
                {nftPoints?.max_elixir?.[plotInfo.rarity]}
              </p>
            </div>
          )}
          {plotInfo.plot_type === PlotType.Storage && (
            <div className="flex justify-between w-full text-sm px-2">
              <p>
                {t("general_mutated_gen_cap")}:{" "}
                {nftPoints?.max_mutatedgen?.[plotInfo.rarity]}
              </p>
            </div>
          )}
          {plotInfo.plot_type === PlotType.Pasture && (
            <div className="flex justify-between w-full text-sm px-2">
              <p>
                {t("general_mongen_cap")}:{" "}
                {nftPoints?.limit_mongen?.[plotInfo.rarity]}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
