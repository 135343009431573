import gameConfig from "config/game-config";
import useCustomer from "hooks/useCustomer";
import useUI from "hooks/useUI";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import api from "services/api";
import { RootState } from "store";
import { DialogInfo, removeDialog } from "../../slices/ui.slice";
import Button from "../button";
export default function DialogCommunity({ dialog }: { dialog: DialogInfo }) {
  const { t } = useTranslation();
  const cs = useCustomer();
  const ui = useUI();
  const { customerInfo } = useSelector((state: RootState) => state.userSlice);
  const avatars: number[] = Array.from({ length: 44 }, (v, i) => i + 1);
  const [ids, setIds] = useState<number[]>([]);
  const [selected, setSelected] = useState<number>(customerInfo.avatar);
  const social = [
    {
      name: t("community.twitter"),
      url: "https://twitter.com/Monsterra_P2E",
      img: "Twitter.webp",
    },
    {
      name: t("community.telegram"),
      url: "https://t.me/monsterra_official",
      img: "Telegram.webp",
    },
    {
      name: t("community.facebook"),
      url: "https://www.facebook.com/MonsterraNFTGame/",
      img: "Facebook.webp",
    },
    {
      name: t("community.medium"),
      url: "https://medium.com/@monsterra.io",
      img: "md.webp",
    },
    {
      name: t("community.youtube"),
      url: "https://www.youtube.com/MonsterraNFTGame",
      img: "ytb.webp",
    },
    {
      name: t("community.tiktok"),
      url: "https://www.tiktok.com/@monsterra_nftgame",
      img: "Tiktok.webp",
    },
  ];
  useEffect(() => {
    if (!customerInfo) return;
    let unlock = _.cloneDeep(
      gameConfig.getGameConfig().UnlockAvatarLevel[customerInfo.level]
    ).concat(customerInfo.avatar_pool);
    setIds(unlock);
  }, [customerInfo]);
  async function onAvatarClick(avatar_id: number) {
    if (!ids.includes(avatar_id)) {
      return;
    }
    setSelected(avatar_id);
  }
  async function save() {
    if (!selected) {
      return;
    }
    ui.showLoading();
    try {
      await api.updateCustomerProfile({ avatar_id: selected });
      let tmp = _.cloneDeep(customerInfo);
      tmp.avatar = Number(selected);
      cs.updateCustomerInfo(tmp);
      onClose();
    } catch (error) {
    } finally {
      ui.hideLoading();
    }
  }
  function onClose() {
    ui.removeDialog(dialog.id);
  }
  return (
    <div className="w-full h-full flex justify-center items-center">
      <div className="ui-popup_import relative p-4">
        <img
          onClick={() => {
            onClose();
          }}
          src="close.png"
          className="absolute top-1 right-1 w-16 h-16 interactive"
        />
        <p className="text-[#fdf3a3] font-bold text-3xl mt-2 text-center">
          {t("setting.community")}
        </p>
        <div className="mt-8 p-4">
          <div>
            {social.map((i: any) => (
              <div className="w-1/2 inline-block">
                <a
                  className="flex items-center gap-2"
                  href={i.url}
                  target="_blank"
                >
                  <img src={`social/${i.img}`} className="w-12 h-12" />
                  <p className="text-xl">{i.name}</p>
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
