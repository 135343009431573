import { useSelector } from "react-redux";
import { RootState } from "store";

//<color=#E8EA1B>[MSTR]</color>
export default function CustomerName() {
  const { customerInfo } = useSelector((state: RootState) => state.userSlice);
  function prefix() {
    let prefix = customerInfo.prefix;
    if (!prefix) return null;
    let clanName = prefix.substring(
      prefix.indexOf("["),
      prefix.indexOf("]") + 1
    );
    let color = prefix.substring(prefix.indexOf("#"), prefix.indexOf("#") + 7);
    return <span style={{ color }}>{clanName}</span>;
  }
  return (
    <span className="text-ellipsis whitespace-nowrap overflow-hidden text-left w-auto inline-block mx-auto">
      {prefix()}
      <span>{customerInfo.name || `User #${customerInfo.id}`}</span>
    </span>
  );
}
