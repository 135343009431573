import { useTranslation } from "react-i18next";
import gameConfig from "../../config/game-config";
import {
  BarrackType,
  BombardType,
  CampType,
  MongenRace,
  NFTType,
  PlotType,
  Rarity,
  TowerType,
  TrapType,
} from "../../config/interface";
import PlotEntity from "../../config/plot.entity";

export default function PlotImage({ plotInfo, cover }: { plotInfo: PlotEntity, cover?: boolean }) {
  function getImage() {
    switch (plotInfo.plot_type) {
      case PlotType.Tree:
        return `plots/worldtree.webp`;
      case PlotType.Pasture:
        return `plots/${PlotType[
          plotInfo.plot_type
        ].toLowerCase()}-${MongenRace[plotInfo.plot_race].toLowerCase()}-${plotInfo.rarity}.webp`;
      case PlotType.Tower:
        if (plotInfo.sub_plot_type) {
          return `plots/${TowerType[
            plotInfo.sub_plot_type
          ].toLowerCase()}-${PlotType[plotInfo.plot_type].toLowerCase()}-${plotInfo.rarity
            }.webp`;
        } else {
          return `plots/${PlotType[plotInfo.plot_type].toLowerCase()}-${plotInfo.rarity
            }.webp`;
        }
      case PlotType.Trap:
        if (plotInfo.sub_plot_type) {
          return `plots/${TrapType[
            plotInfo.sub_plot_type
          ].toLowerCase()}-${PlotType[plotInfo.plot_type].toLowerCase()}-${plotInfo.rarity
            }.webp`;
        } else {
          return `plots/${PlotType[plotInfo.plot_type].toLowerCase()}-${plotInfo.rarity
            }.webp`;
        }
      case PlotType.Bombard:
        if (plotInfo.sub_plot_type) {
          return `plots/${BombardType[
            plotInfo.sub_plot_type
          ].toLowerCase()}-${PlotType[plotInfo.plot_type].toLowerCase()}-${plotInfo.rarity
            }.webp`;
        } else {
          return `plots/${PlotType[plotInfo.plot_type].toLowerCase()}-${plotInfo.rarity
            }.webp`;
        }
      case PlotType.Barracks:
        if (plotInfo.sub_plot_type) {
          return `plots/${BarrackType[
            plotInfo.sub_plot_type
          ].toLowerCase()}-${PlotType[plotInfo.plot_type].toLowerCase()}-${plotInfo.rarity
            }.webp`;
        } else {
          return `plots/${PlotType[plotInfo.plot_type].toLowerCase()}-${plotInfo.rarity
            }.webp`;
        }
      case PlotType.Camp:
        if (plotInfo.sub_plot_type) {
          return `plots/${CampType[
            plotInfo.sub_plot_type
          ].toLowerCase()}-${PlotType[plotInfo.plot_type].toLowerCase()}-${plotInfo.rarity
            }.webp`;
        } else {
          return `plots/${PlotType[plotInfo.plot_type].toLowerCase()}-${plotInfo.rarity
            }.webp`;
        }
      default:
        return `plots/${PlotType[plotInfo.plot_type].toLowerCase()}-${plotInfo.rarity
          }.webp`;
    }
  }
  return <img src={getImage()} className={`w-full h-full ${cover ? 'object-cover' : 'object-contain'}`} />
}