import { useTranslation } from "react-i18next";
import styled, { keyframes } from "styled-components";
import { rotateOutUpRight as anim, pulse } from "react-animations";
const animName = keyframes`${anim}`;
const AnimDiv = styled.div`
  animation: 4s ${animName} infinite;
`;
export default function Loading() {
  const { t } = useTranslation();
  return (
    <div className="absolute top-0 left-0 h-full w-full bg-black bg-opacity-90 z-[90000]">
      <div className="flex relative w-full h-full items-center justify-center">
        <div className="ui-popup_confirm_fusion relative">
          <p className="text-[#fdf3a3] font-bold text-3xl mt-5 text-center">
            {t("global.loading")}
          </p>
          <p className="mt-8 px-4 text-2xl w-full flex justify-center items-center">
            {t("global.pleasewait")}
          </p>
          <div className="flex justify-center mt-4 transform scale-50">
            <div className="ui-loading rotate"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
