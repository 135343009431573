import { MouseEventHandler } from "react";

export default function Button(props: {
  disabled?: boolean;
  text: string;
  color?: "green" | "red" | "grey" | "silver";
  size?: "xs" | "sm" | "md" | "lg" | "xl";
  className?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}) {
  let { text, disabled, color = "green", size = "md" } = props;
  let imageMap = {
    green: "ui-button_save_on",
    red: "ui-button_cancel_on",
    grey: "ui-button_all_grey",
    silver: "ui-button_all_grey",
  };
  let sizeMap = {
    xs: "",
    sm: "",
    md: "text-xl",
    lg: "text-2xl scale-125",
    xl: "text-2xl scale-150",
  };
  return (
    <button
      {...props}
      className={`${imageMap[color]} ${sizeMap[size]} ${props.className} interactive`}
    >
      {text}
    </button>
  );
}
