import { useTranslation } from "react-i18next";
import gameConfig from "../../config/game-config";
import {
  BarrackType,
  BodyPart,
  BombardType,
  CampType,
  MongenRace,
  NFTType,
  PlotType,
  Rarity,
  TowerType,
  TrapType,
} from "../../config/interface";
import MongenEntity from "../../config/mongen.entity";
import PlotEntity from "../../config/plot.entity";
import Mongen from "../static-anim/mongen";
import Typo from "../typo";
import Evol from "./evol";

export function MongenThumbnail({
  mongenInfo,
  active = false,
  onClick,
  showInUse,
}: {
  showInUse?: boolean;
  onClick?: Function;
  mongenInfo: MongenEntity;
  active?: boolean;
}) {
  const { t } = useTranslation();
  const nftPoints = gameConfig.getItemInfo(NFTType.Mongen);

  return (
    <div
      className="relative p-1 group interactive"
      onClick={() => {
        if (showInUse && mongenInfo.is_click2earn) return;
        if (onClick) {
          onClick();
        }
      }}
    >
      {active && (
        <div className="w-full h-full ui-border_hl_mongen absolute top-[5px] left-[5px] z-10"></div>
      )}

      <div className="flex ui-blank_fusion_mongen gap-2 p-2 relative">
        {showInUse && mongenInfo.is_click2earn && (
          <div className="absolute top-0 left-0 w-full h-full z-10 bg-black bg-opacity-75 rounded-[13px]">
            <div className="flex items-center justify-center w-full h-full">
              <p className="text-3xl">{t("inventory.in_use")}</p>
            </div>
          </div>
        )}
        <div className="w-full p-5">
          <Mongen dna={mongenInfo.dna} />
        </div>
        <div className="absolute top-0 left-0 p-2">
          <Evol evol={mongenInfo.evolution} direction="vertical" />
        </div>
        <div className="absolute top-0 right-0 p-2">
          <div className="flex gap-2">
            {mongenInfo.dna.length === 2 ? (
              <Typo type="rarity" value={mongenInfo.dna[1][0]} />
            ) : (
              <Typo type="rarity" value={mongenInfo.dna[BodyPart.Aura][0]} />
            )}
            <div
              className={`ui-icon_${MongenRace[
                mongenInfo.dna[0][0]
              ].toLowerCase()}`}
            ></div>
          </div>
        </div>
        <div className="absolute bottom-0 left-0 w-full p-1">
          <div className="flex justify-between w-full text-sm">
            <p>#{mongenInfo.id}</p>
            <p>Lv. {mongenInfo.level}</p>
          </div>
          {mongenInfo.dna.length > 2 && (
            <div className="flex justify-between w-full text-sm">
              <p>
                {t("thumbnail.mintvalue")}:{" "}
                {nftPoints.points[mongenInfo.dna[BodyPart.Aura][0]]}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
