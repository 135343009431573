import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Map from "../../component/map";
import { MapPlotType, NFTType, PlotType, SetupStage } from "../../config/interface";
import PlotEntity from "../../config/plot.entity";
import useCustomer from "../../hooks/useCustomer";
import useUI from "../../hooks/useUI";
import api from "../../services/api";
import { createBackup, restoreBackup } from "../../slices/inventory.slice";
import { RootState } from "../../store";
import PlotPicker from "./plot-picker";
import gameConfig from "config/game-config";

export default function Setup() {
  const params = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const nav = useNavigate();
  const cs = useCustomer();
  const ui = useUI();
  const { stake } = useSelector((state: RootState) => state.userSlice);
  const focusMap: { [key in SetupStage]: MapPlotType } = {
    [SetupStage.PickLandcore]: MapPlotType.Landcore,
    [SetupStage.AttachHabitat]: MapPlotType.Habitat,
    [SetupStage.AttachMongen]: MapPlotType.Habitat,
    [SetupStage.AttachStorage]: MapPlotType.Storage,
    [SetupStage.AttachPlot]: MapPlotType.Workshop,
  };
  const [focus, setFocus] = useState<MapPlotType>(
    focusMap[Number(params.stage) as SetupStage.PickLandcore]
  );
  const [stage, setStage] = useState<SetupStage>(
    Number(params.stage) as SetupStage.PickLandcore
  );

  const { plotInfos, requireMongen, totalMongen, mongenIds, plotIds } =
    useSelector((state: RootState) => state.inventorySlice);
  useEffect(() => {
    dispatch(createBackup());
  }, []);
  async function onCancelClick() {
    await ui.openConfirm(t("setup.confirmcancel"));
    dispatch(restoreBackup());
    nav(-1);
  }
  async function onSaveClick() {
    let currentStorage = plotInfos.find(
      (p) => plotIds.includes(p.id) && p.plot_type === PlotType.Storage
    );

    let maxMutate = 0,
      maxElixir = 0;

    if (currentStorage) {
      maxMutate = gameConfig.getItemInfo(NFTType.Plot, PlotType.Storage)
        .max_mutatedgen[currentStorage.rarity];
      maxElixir = gameConfig.getItemInfo(NFTType.Plot, PlotType.Storage)
        .max_elixir[currentStorage.rarity];
    }

    if (maxMutate < stake.mutatedRevenue) {
      await ui.openConfirm(t("mutated_gen_out_of_capacity"));
    } else if (maxElixir < stake.elixirRevenue) {
      await ui.openConfirm(t("elixir_out_of_capacity"));
    } else {
      await ui.openConfirm(t("setup.confirmsave"));
    }

    try {
      ui.showLoading();
      let { customerInfo } = await api.editLand(mongenIds, plotIds);
      cs.updateCustomerInfo(customerInfo);
      ui.openMessage("Save land success");
      nav(-1);
    } catch (error: any) {
      if (error.message === "Query was empty") {
        ui.openMessage(t("setup.nothing_changed"));
      } else {
        ui.openMessage(t(error.message));
      }
    } finally {
      ui.hideLoading();
    }
  }

  return (
    <div>
      <div className="absolute top-10 right-10 z-30">
        <button
          className="ui-button_save_on block interactive text-2xl"
          onClick={() => {
            onSaveClick();
          }}
        >
          {t("setup.save")}
        </button>
        <button
          className="ui-button_cancel_on block interactive mt-2 text-2xl"
          onClick={() => {
            onCancelClick();
          }}
        >
          {t("setup.cancel")}
        </button>
      </div>
      <Map focus={focus} />
      <div className="absolute bottom-0 left-0 w-full h-[460px] z-30">
        <PlotPicker
          stage={stage}
          changeStage={(newStage: SetupStage) => {
            switch (stage) {
              case SetupStage.PickLandcore:
                let landCore = plotInfos.find(
                  (p: PlotEntity) =>
                    p.plot_type === PlotType.LandCore && p.is_click2earn
                );
                if (!landCore) {
                  return ui.openMessage("No landcore selected");
                }
                break;
            }
            setStage(newStage);
            setFocus(focusMap[newStage as SetupStage]);
          }}
        />
      </div>
    </div>
  );
}
