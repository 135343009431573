import gameConfig from "config/game-config";
import useCustomer from "hooks/useCustomer";
import useUI from "hooks/useUI";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import api from "services/api";
import { RootState } from "store";
import { DialogInfo, removeDialog } from "../../slices/ui.slice";
import Button from "../button";
export default function DialogAvatar({ dialog }: { dialog: DialogInfo }) {
  const { t } = useTranslation();
  const cs = useCustomer();
  const ui = useUI();
  const { customerInfo } = useSelector((state: RootState) => state.userSlice);
  const avatars: number[] = Array.from({ length: 44 }, (v, i) => i + 1);
  const [ids, setIds] = useState<number[]>([]);
  const [selected, setSelected] = useState<number>(customerInfo.avatar);
  useEffect(() => {
    if (!customerInfo) return;
    let unlock = _.cloneDeep(
      gameConfig.getGameConfig().UnlockAvatarLevel[customerInfo.level]
    ).concat(customerInfo.avatar_pool);
    setIds(unlock);
  }, [customerInfo]);
  async function onAvatarClick(avatar_id: number) {
    if (!ids.includes(avatar_id)) {
      return;
    }
    setSelected(avatar_id);
  }
  async function save() {
    if (!selected) {
      return;
    }
    ui.showLoading();
    try {
      await api.updateCustomerProfile({ avatar_id: selected });
      let tmp = _.cloneDeep(customerInfo);
      tmp.avatar = Number(selected);
      cs.updateCustomerInfo(tmp);
      onClose();
    } catch (error) {
    } finally {
      ui.hideLoading();
    }
  }
  function onClose() {
    ui.removeDialog(dialog.id);
  }
  return (
    <div className="w-full h-full flex justify-center items-center">
      <div className="ui-popup_import relative p-4">
        <img
          onClick={() => {
            onClose();
          }}
          src="close.png"
          className="absolute top-1 right-1 w-16 h-16 interactive"
        />
        <p className="text-[#fdf3a3] font-bold text-3xl mt-2 text-center">
          {t("profile.avatar")}
        </p>
        <div className="h-[340px] overflow-y-auto overflow-x-hidden mt-8 p-2">
          <div className="flex flex-wrap ">
            {avatars.map((id: number) => (
              <div
                onClick={() => {
                  onAvatarClick(id);
                }}
                className={`w-1/6 p-2 border-4 rounded-md
                ${!ids.includes(id) && "grayscale"}
                ${
                  id === selected ? "border-yellow-300" : "border-transparent"
                } `}
              >
                <img
                  src={`avatar/avatar_${(id + "").padStart(3, "0")}.webp`}
                  className="w-full h-full rounded-full inline-block"
                />
              </div>
            ))}
          </div>
        </div>
        <button
          className="ui-button_save_on block mx-auto mt-4"
          onClick={() => {
            save();
          }}
        >
          <p className="text-2xl">{t("profile.change")}</p>
        </button>
      </div>
    </div>
  );
}
