import { Menu, Modal, Popup } from "semantic-ui-react";
import { Popover } from "@headlessui/react";
import "./page-component.style.css";
import { useState } from "react";

export const PageComponent = ({ SSO_META }: { SSO_META: any }) => {
  let siteUrl = window.location.origin;

  const pageData = [
    {
      icon: "game",
      title: "Main - game",
      url: SSO_META.GameLink,
      description: "Main gameplay",
    },
    {
      icon: "marketplace",
      title: "Marketplace",
      url: SSO_META.MarketLink,
      description: "Buy and sell your game asset",
    },
    {
      icon: "dao",
      title: "DAO",
      url: SSO_META.DAOLink,
      description: "Manage your account and join the governance",
    },
    {
      icon: "portal",
      title: "Minigame",
      url: SSO_META.PortalLink,
      description: "Play minigames with your assets",
    },
    {
      icon: "guild",
      title: "Guild",
      url: SSO_META.GuildLink,
      description: "Make higher benefits with your friends and fellows",
    },
    {
      icon: "bridge",
      title: "Bridge",
      url: SSO_META.BridgeLink,
      description: "Transfer your tokens between supported chains",
    },
    {
      icon: "monstv",
      title: "MonsTV",
      url: SSO_META.MonstvLink,
      description: "Watch and share",
    },
    {
      icon: "event",
      title: "Events",
      url: SSO_META.EventLink,
      description: "Join to earn",
    },
    {
      icon: "monsterraverse",
      title: "Monsterraverse",
      url: SSO_META.MonsterraverseLink,
      description: "The next journey...",
    },
  ];

  const bottomPageData = [
    {
      icon: "support",
      title: "Support Center",
      url: SSO_META.SupportLink,
      description: "Share your feedback or track your support ticket",
    },
    {
      icon: "guideline",
      title: "Guideline",
      url: SSO_META.GuideLinkLegend,
      description: "Detailed instructions",
    },
    {
      icon: "home",
      title: "Homepage",
      url: SSO_META.LandingPage,
      description: "Landing page",
    },
  ];

  const openLink = (link: string) => {
    if (window.location.origin === link) {
      return;
    }
    if (link && link !== "#") window.open(link, "_blank");
  };

  const isHidden = (link: string) => {
    return !link || link === "" || link === "#";
  };

  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Popover className="mt-4 pagecomponent pagecomponentgame font-semantic font-bold">
        {({ open }) => (
          <>
            <Popover.Button>
              <Menu.Item
                header
                className="menu-icon"
                key="pagecomponent"
                position="right"
              >
                <div className="hidden lg:flex">
                  <img
                    src={require("./assets/game-menu.png")}
                    className="h-6"
                    alt="logo"
                  />
                  {/* <img src={require("./assets/dropdown-icon.png")} alt="logo" id="menu-dropdown" className="self-center" /> */}
                </div>
                <div
                  className="lg:hidden flex"
                  onClick={() => {
                    setIsOpen(true);
                  }}
                >
                  <img
                    src={require("./assets/game-menu.png")}
                    className="h-6"
                    alt="logo"
                  />
                  {/* <img src={require("./assets/dropdown-icon.png")} alt="logo" id="menu-dropdown" className="self-center" /> */}
                </div>
              </Menu.Item>
            </Popover.Button>

            {open && (
              <Popover.Panel
                className="w-[916px]"
                style={{
                  transform: "translateX(calc(-100% + 53px))",
                  zoom: 0.8,
                }}
              >
                <div
                  className="absolute w-[916px] max-w-full py-4 bg-black-primary px-6 z-[20] hidden lg:block page-component-game"
                  style={{
                    border: "0.1px solid #0F1011",
                    transform: "scale(1)",
                  }}
                >
                  <div className="grid grid-cols-3">
                    {pageData.map((e) => (
                      <div
                        onClick={() => openLink(e.url)}
                        key={e.title}
                        className={`flex px-6 py-2.5 my-2 max-w-[320px] cursor-pointer page-component ${
                          siteUrl === e.url && "page-active"
                        } ${isHidden(e.url) && "hidden-page"}`}
                      >
                        <img
                          src={require(`./assets/${e.icon}.png`)}
                          alt={e.title}
                          className="w-8 h-8"
                        />
                        <div className="ml-4">
                          <div className="text-white text-header text-[15px]">
                            {e.title}
                          </div>
                          <div className="text-gray text-[13px] mt-0.5">
                            {isHidden(e.url) ? "Coming soon" : e.description}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="br mx-auto"></div>

                  <div className="grid grid-cols-3">
                    {bottomPageData.map((e) => (
                      <div
                        onClick={() => openLink(e.url)}
                        key={e.title}
                        className={`flex px-6 py-2.5 my-2 max-w-[320px] cursor-pointer page-component ${
                          siteUrl === e.url && "page-active"
                        } ${isHidden(e.url) && "hidden-page"}`}
                      >
                        <img
                          src={require(`./assets/${e.icon}.png`)}
                          alt={e.title}
                          className="w-8 h-8"
                        />
                        <div className="ml-4">
                          <div className="text-white text-header text-[15px]">
                            {e.title}
                          </div>
                          <div className="text-gray text-[13px] mt-0.5">
                            {isHidden(e.url) ? "Coming soon" : e.description}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </Popover.Panel>
            )}
          </>
        )}
      </Popover>
      <div className="lg:hidden block">
        <Modal
          open={isOpen}
          onClose={() => setIsOpen(false)}
          className="pagecomponent lg:hidden block w-full"
        >
          <div
            className="w-full max-h-full max-w-full bg-black-primary px-6 z-[20] py-3 w-full page-component-game"
            style={{ border: "0.1px solid #0F1011", transform: "scale(1)" }}
          >
            <div
              className="text-red cursor-pointer float-right translate-x-10 -translate-y-5"
              onClick={() => setIsOpen(false)}
            >
              <img
                src="/assets/images/close-icon.svg"
                alt="close"
                className=""
              />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
              {pageData.map((e) => (
                <div
                  onClick={() => openLink(e.url)}
                  key={e.title}
                  className={`flex px-6 py-2.5 my-2 max-w-[320px] cursor-pointer page-component ${
                    isHidden(e.url) && "hidden-page"
                  }`}
                >
                  <img
                    src={require(`./assets/${e.icon}.png`)}
                    alt={e.title}
                    className="w-8 h-8"
                  />
                  <div className="ml-4">
                    <div className="text-white text-header text-[15px]">
                      {e.title}
                    </div>
                    <div className="text-gray text-[13px] mt-1">
                      {e.description}
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="br w-full"></div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
              {bottomPageData.map((e) => (
                <div
                  onClick={() => openLink(e.url)}
                  key={e.title}
                  className={`flex px-6 py-2.5 my-2 max-w-[320px] cursor-pointer page-component ${
                    isHidden(e.url) && "hidden-page"
                  }`}
                >
                  <img
                    src={require(`./assets/${e.icon}.png`)}
                    alt={e.title}
                    className="w-8 h-8"
                  />
                  <div className="ml-4">
                    <div className="text-white text-header text-[15px]">
                      {e.title}
                    </div>
                    <div className="text-gray text-[13px] mt-1">
                      {e.description}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};
