import { Decimal } from "@cosmjs/math";
import { SigningCosmWasmClient } from "@cosmjs/cosmwasm-stargate";
import { BasicAllowance } from "cosmjs-types/cosmos/feegrant/v1beta1/feegrant";
import { MsgGrantAllowance } from "cosmjs-types/cosmos/feegrant/v1beta1/tx";
import { MsgExecuteContract } from "cosmjs-types/cosmwasm/wasm/v1/tx";
import { EncodeObject, OfflineSigner, Registry } from "@cosmjs/proto-signing";
import { SSO_META } from "config/game-config";
import {
  KEPLR_ERRORS,
  LoginType,
  TRANSACTION_TYPE_ENUM,
  WALLET_PROVIDER,
} from "../config/interface";
import auraInfo from "./abi/aura-chain.json";
import { ChainInfo, Keplr, StdSignature } from "@keplr-wallet/types";

const chainInfo = SSO_META().AuraChainInfo ?? auraInfo.testnet;
//@ts-ignore
const Web3 = window.Web3;
const web3 = new Web3(Web3.givenProvider);

async function getKeplr(): Promise<Keplr | undefined> {
  // @ts-ignore
  if (window.keplr) {
    // @ts-ignore
    return window.keplr;
  }

  if (document.readyState === "complete") {
    // @ts-ignore
    return window.keplr;
  }

  return new Promise((resolve) => {
    const documentStateChange = (event: Event) => {
      if (
        event.target &&
        (event.target as Document).readyState === "complete"
      ) {
        // @ts-ignore
        resolve(window.keplr);
        document.removeEventListener("readystatechange", documentStateChange);
      }
    };

    document.addEventListener("readystatechange", documentStateChange);
  });
}

async function getSigner() {
  let keplr = await getKeplr();
  if (!keplr) {
    return null;
  }
  // @ts-ignore
  let offlineSigner = window.getOfflineSignerOnlyAmino(chainInfo.chainId);
  // let offlineSigner = keplr.getOfflineSigner(chainInfo.chainId);
  let gasStep = chainInfo?.gasPriceStep?.average || 0.0025;

  //convert gasPrice to Decimal
  let pow = 1;
  while (!Number.isInteger(gasStep)) {
    gasStep = gasStep * Math.pow(10, pow);
    pow++;
  }

  try {
    const registry = new Registry();
    registry.register(
      TRANSACTION_TYPE_ENUM.ExecuteContract,
      MsgExecuteContract
    );
    registry.register(
      TRANSACTION_TYPE_ENUM.MsgGrantAllowance,
      MsgGrantAllowance
    );
    // registry.register(
    //   TRANSACTION_TYPE_ENUM.AuraExecuteContract,
    //   MsgExecuteContract
    // );

    const client = await SigningCosmWasmClient.connectWithSigner(
      chainInfo.rpc,
      offlineSigner,
      {
        registry: registry as any,
        gasPrice: {
          amount: Decimal.fromAtomics(gasStep.toString(), pow) as any,
          denom: chainInfo.feeCurrencies[0].coinMinimalDenom,
        },
      }
    );
    return client;
  } catch (err) {
    console.log(err);
    return null;
  }
}

async function keplrSuggestChain(loginType: LoginType): Promise<any> {
  let chainSuggest;

  switch (loginType) {
    case LoginType.Aura:
      chainSuggest = chainInfo;
  }

  if (chainSuggest) {
    return (await getKeplr())
      .experimentalSuggestChain(chainSuggest)
      .catch((e: Error) => {
        console.log({ e });
        throw e;
      });
  }

  return KEPLR_ERRORS.NoChainInfo;
}

function getKeplrError(err: any): KEPLR_ERRORS {
  if (err.toUpperCase().includes(KEPLR_ERRORS.NoChainInfo)) {
    return KEPLR_ERRORS.NoChainInfo;
  } else if (err.toUpperCase().includes(KEPLR_ERRORS.NOT_EXIST)) {
    return KEPLR_ERRORS.NOT_EXIST;
  } else if (err.toUpperCase().includes(KEPLR_ERRORS.RequestRejected)) {
    return KEPLR_ERRORS.RequestRejected;
  }

  return KEPLR_ERRORS.Failed;
}

async function handleErrors(err: any): Promise<string> {
  const error = getKeplrError(err.message);
  switch (error) {
    case KEPLR_ERRORS.NoChainInfo:
      // this.keplrSuggestChain(chainId);
      return null;
    case KEPLR_ERRORS.NOT_EXIST:
      window.open(
        "https://chrome.google.com/webstore/detail/keplr/dmkamcknogkgcdfhhbddcghachkejeap?hl=en"
      );
      return null;
    default:
      return err.message || err;
  }
}

async function connect(provider: WALLET_PROVIDER) {
  switch (provider) {
    case WALLET_PROVIDER.KEPLR:
      return await connectKeplr(chainInfo);
  }
}

const connectKeplr = async (chainInfo: ChainInfo) => {
  console.log(chainInfo);
  const checkWallet = async () => {
    try {
      const keplr = await getKeplr();

      if (keplr) {
        await suggestChain(keplr);
        await keplr.enable(chainInfo.chainId);
        const account = await keplr.getKey(chainInfo.chainId);

        if (account) {
          return account.bech32Address;
        }
      } else {
        window.open(
          "https://chrome.google.com/webstore/detail/keplr/dmkamcknogkgcdfhhbddcghachkejeap?hl=en"
        );
        return null;
      }
    } catch (e: any) {
      console.log({ e });
      catchErrors(e);
    }
  };
  return checkWallet();
};

const suggestChain = (w: Keplr) => {
  return w.experimentalSuggestChain(chainInfo);
};

const catchErrors = async (e: any) => {
  handleErrors(e).then((msg) => {
    if (msg) {
      console.log({ msg });
      // ui.alertFailed(msg, "");
    }
  });
};

const signMessage = async (signer: string, message: any) => {
  let keplr = await getKeplr();
  console.log({ chainId: chainInfo.chainId, signer, message });
  let rs = await keplr.signArbitrary(chainInfo.chainId, signer, message);
  return rs;
};

const verifySignature = async (
  signer: string,
  message: any,
  signature: StdSignature
) => {
  let keplr = await getKeplr();
  let rs = await keplr.verifyArbitrary(
    chainInfo.chainId,
    signer,
    message,
    signature
  );
  return rs;
};

const getBalance = async (address: string) => {
  let client = await getSigner();
  if (client) {
    let balance = await client.getBalance(
      address,
      chainInfo.stakeCurrency.coinMinimalDenom
    );
    return balance.amount;
  }
};

const getTokenBalance = async (address: string, contractAddress: string) => {
  let client = await getSigner();
  if (client) {
    let balance = await client.queryContractSmart(contractAddress, {
      balance: { address: address },
    });
    return balance?.balance;
  }
};

const getNFTBalance = async (address: string, contractAddress: string) => {
  let client = await getSigner();
  if (client) {
    let balance = await client.queryContractSmart(contractAddress, {
      tokens: { owner: address },
    });
    return balance?.tokens;
  }
};

const getTokenAllowance = async (
  contractAddress: string,
  owner: string,
  spender: string
) => {
  let client = await getSigner();
  console.log({ client });
  if (client) {
    let balance = await client.queryContractSmart(contractAddress, {
      allowance: { owner: owner, spender: spender },
    });
    return balance?.allowance;
  }
};

const executeContract = async (
  signerAddress: string,
  messages: EncodeObject[]
) => {
  let client = await getSigner();
  if (client) {
    const estimateGas = await keplrService.simulate(signerAddress, messages);
    console.log({ estimateGas });

    const fee = {
      amount: [
        {
          denom: chainInfo.stakeCurrency.coinMinimalDenom,
          amount: (
            chainInfo.gasPriceStep.average *
            Math.pow(10, chainInfo.stakeCurrency.coinDecimals)
          ).toString(),
        },
      ],
      gas: String(estimateGas * 1.5),
    };

    let tx = await client.signAndBroadcast(signerAddress, messages, fee);
    return tx;
  }
};

const execute = async (
  userAddress: string,
  contract_address: string,
  msg: any,
  feeGas: any = null
) => {
  let client = await getSigner();
  let rs = await client.execute(
    userAddress,
    contract_address,
    msg,
    feeGas || "auto",
    "",
    []
  );
  return rs;
};

const simulate = async (signerAddress: string, messages: EncodeObject[]) => {
  let client = await getSigner();
  if (client) {
    let gas = await client.simulate(signerAddress, messages, "");
    return gas;
  }
  return 100000;
};

const setBasicAllowance = (spendLimit: string) => {
  const allowanceValue: BasicAllowance = {
    spendLimit: spendLimit
      ? [
          {
            denom: chainInfo.stakeCurrency.coinMinimalDenom,
            amount: spendLimit,
          },
        ]
      : [],
    expiration: undefined,
  };

  const basicAllowance = {
    typeUrl: TRANSACTION_TYPE_ENUM.BasicAllowance,
    value: Uint8Array.from(
      BasicAllowance.encode(allowanceValue as any).finish()
    ),
  };

  return basicAllowance;
};

const approveAllowance = async ({
  contract,
  granter,
  grantee,
  amount,
}: {
  contract: string;
  granter: string;
  grantee: string;
  amount: string | number;
}) => {
  let msgGrantAllowance;
  let client = await getSigner();

  if (!client) {
    return;
  }

  try {
    amount = web3.utils.toWei(amount, "ether");
    const basicAllowance = keplrService.setBasicAllowance(amount as string);
    msgGrantAllowance = MsgGrantAllowance.fromPartial({
      allowance: basicAllowance,
      grantee,
      granter,
    });
  } catch (e) {}

  let approveAminoMsg = {
    typeUrl: TRANSACTION_TYPE_ENUM.MsgGrantAllowance,
    value: msgGrantAllowance || null,
  };

  const estimateGas = await keplrService.simulate(grantee, [approveAminoMsg]);
  const fee = {
    amount: [
      {
        denom: chainInfo.stakeCurrency.coinMinimalDenom,
        amount: (
          chainInfo.gasPriceStep.average *
          Math.pow(10, chainInfo.stakeCurrency.coinDecimals)
        ).toString(),
      },
    ],
    gas: String(estimateGas * 1.5),
  };

  let response = await client.signAndBroadcast(grantee, [approveAminoMsg], fee);
  return response;
};

const keplrService = {
  getBalance,
  getKeplr,
  keplrSuggestChain,
  getKeplrError,
  handleErrors,
  connect,
  connectKeplr,
  suggestChain,
  catchErrors,
  signMessage,
  verifySignature,
  getTokenBalance,
  executeContract,
  simulate,
  setBasicAllowance,
  getSigner,
  approveAllowance,
  execute,
  getTokenAllowance,
  getNFTBalance
};

export default keplrService;
