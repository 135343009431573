import { Rarity } from "../../config/interface";
import styled, { keyframes } from "styled-components";
import { bounce, pulse } from "react-animations";
const bounceAnimation = keyframes`${pulse}`;
const BouncyDiv = styled.div`
  animation: 2s ${bounceAnimation} infinite;
`;

export default function PlotLandcore({
  rarity,
  onClick,
}: {
  rarity: Rarity;
  onClick?: Function;
}) {
  function renderContent() {
    switch (rarity) {
      case Rarity.Common:
        return PlotLandcoreCommon();
      case Rarity.Uncommon:
        return PlotLandcoreUncommon();
      case Rarity.Rare:
        return PlotLandcoreRare();
      case Rarity.Epic:
        return PlotLandcoreEpic();
      case Rarity.Legendary:
        return PlotLandcoreLegend();
    }
  }
  return (
    <button
      className="interactive w-[200px] h-[250px] -top-16 -left-16 relative"
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      {renderContent()}
    </button>
  );
}
function PlotLandcoreCommon() {
  return (
    <div className="map-island_landcore_CO absolute left-[35px] bottom-0">
      <BouncyDiv>
        <div className="map-core1_2 absolute bottom-[-58px] left-[14px]"></div>
      </BouncyDiv>
    </div>
  );
}
function PlotLandcoreUncommon() {
  return (
    <div className="map-island_landcore_UC absolute left-[45px] bottom-0">
      <BouncyDiv>
        <div className="map-core1_2 absolute bottom-[-57px] left-[-11px]"></div>
      </BouncyDiv>
    </div>
  );
}

function PlotLandcoreRare() {
  return (
    <div className="map-island_landcore_RA absolute left-[30px] bottom-0">
      <BouncyDiv>
        <div className="map-core3 absolute bottom-[-59px] left-[18px]"></div>
      </BouncyDiv>
    </div>
  );
}

function PlotLandcoreEpic() {
  return (
    <div className="map-island_landcore_EP absolute left-[10px] bottom-[0]">
      <BouncyDiv>
        <div className="map-core_4 absolute bottom-[-52px] left-[30px]"></div>
      </BouncyDiv>
    </div>
  );
}

function PlotLandcoreLegend() {
  return (
    <div className="absolute bottom-0 left-[-21px] pointer-events-none">
      <div className="map-island_landcore_LE"></div>
      <BouncyDiv>
        <div className="map-core_5 absolute bottom-[186px] left-[42px]"></div>
      </BouncyDiv>
    </div>
  );
}
