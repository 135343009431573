import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { PlotType, Rarity } from "../../config/interface";

export default function Typo(props: {
  type: "rarity" | "plottype";
  value: number;
}) {
  switch (props.type) {
    case "rarity":
      return <TypoRarity {...props} />;
    case "plottype":
      return <TypoPlotType {...props} />;
    default:
      return <span>{props.value}</span>;
  }
}
function TypoPlotType(props: { value: number }) {
  return <span>{t(`plottype.${PlotType[props.value].toLowerCase()}`)}</span>;
}
function TypoRarity(props: { value: number }) {
  const { t } = useTranslation();
  const color = ["#6b9795", "#56af57", "#548faf", "#b97cd4", "#ea5a21"];
  return (
    <span {...props} style={{ color: color[props.value] }}>
      {t(`rarity.${Rarity[props.value].toLowerCase()}`)}
    </span>
  );
}
