import MSTR from "./abi/mstr.json";
import MAG from "./abi/mag.json";
import StakeTokenABI from "./abi/MonsterraStaking.json";
import ItemABI from "./abi/monsterra-item.json";
import NFTABI from "./abi/monsterra-nft.json";
import FusionABI from "./abi/Fusion.json";
import utils from "./utils";
import { Chain, ContractType } from "config/interface";
import { SSO_CONTRACT, SSO_META } from "config/game-config";

//@ts-ignore
const Web3 = window.Web3;
//@ts-ignore
const web3 = new Web3(Web3.givenProvider);

export const MSTRContract = new web3.eth.Contract(MSTR);
export const MAGContract = new web3.eth.Contract(MAG);
export const FTContract = new web3.eth.Contract(ItemABI);
export const StakeTokenContract = new web3.eth.Contract(StakeTokenABI);
export const NFTContract = new web3.eth.Contract(NFTABI);
export const FusionContract = new web3.eth.Contract(FusionABI);

export const NFTContractABI = NFTABI;

export function getContract(type: ContractType, chain: Chain) {
  let ct = SSO_CONTRACT().find(
    (i: any) => i.chain === chain && i.name === type
  );
  if (!ct) {
    console.error("contract not found ", type, chain);
    return "";
  }
  return ct.value;
}

export function getExplorer(chain: Chain) {
  let ct = SSO_META().network[Chain[chain]];
  let urls = ct?.blockExplorerUrls;
  if (!urls || urls?.length <= 0) {
    return "";
  }
  let url = urls[0];
  return utils.formatHostUrl(url);
}
