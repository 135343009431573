import Typo from "component/typo";
import { useTranslation } from "react-i18next";
import utils from "services/utils";
import gameConfig from "../../config/game-config";
import { MongenRace } from "../../config/interface";
import MongenEntity from "../../config/mongen.entity";
import Mongen from "../static-anim/mongen";
import Evol from "./evol";

export function FusionThumbnail({
  mongenInfo,
  active = false,
  onClick,
}: {
  onClick?: Function;
  mongenInfo: MongenEntity;
  active?: boolean;
}) {
  const { t } = useTranslation();
  let config = gameConfig.getGameConfig().EvolutionLevel;
  return (
    <div
      className="relative p-1 group interactive"
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      {active && (
        <div className="w-full h-full ui-border_hl_mongen absolute top-[5px] left-[5px] z-10"></div>
      )}
      <div className="flex ui-blank_fusion_mongen gap-2 p-2 relative">
        <div className="absolute top-0 left-0 p-2">
          <Evol evol={mongenInfo.evolution} direction="vertical" />
        </div>
        <div className="absolute top-2 right-2">
          <div className="flex gap-1">
            <Typo type="rarity" value={utils.getDNARarity(mongenInfo.dna)} />
            <div
              className={`ui-icon_${MongenRace[
                mongenInfo.dna[0][0]
              ].toLowerCase()}`}
            ></div>
          </div>
        </div>
        <div className="absolute bottom-0 left-0 p-2">
          <p className="text-sm">
            Lv.{" "}
            <span className="text-textnumberyellow">{mongenInfo.level}</span> |{" "}
            {config[mongenInfo.evolution - 1]}
          </p>

        </div>
        <div className="absolute bottom-0 right-0 p-2">
          <div className="text-sm">
            #{mongenInfo.id}
          </div>
        </div>
        <div className="ml-4">
          <Mongen dna={mongenInfo.dna} />
        </div>
      </div>
    </div>
  );
}
