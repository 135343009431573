export default function Evol({
  evol,
  direction = "vertical",
}: {
  evol: number;
  direction?: "horizontal" | "vertical";
}) {
  const stars = [5, 4, 3, 2, 1];
  return (
    <div>
      <div>
        {direction === "vertical" &&
          stars.map((s: number) =>
            s > evol ? (
              <div className="ui-star_black" key={s}></div>
            ) : (
              <div className="ui-star" key={s}></div>
            )
          )}
      </div>
      <div className="flex gap-2">
        {direction === "horizontal" &&
          stars.map((s: number, index: number) =>
            s < evol ? (
              <div className="ui-star_black" key={s}></div>
            ) : (
              <div className="ui-star" key={s}></div>
            )
          )}
      </div>
    </div>
  );
}
