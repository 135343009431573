import { useSelector } from "react-redux";
import { DialogInfo } from "../../slices/ui.slice";
import { RootState } from "../../store";
import DialogMessage from "./dialog-message";
import DialogConfirm from "./dialog-confirm";
import Loading from "./dialog-loading";
import { CaptchaV2 } from "./captcha";
import { Signup } from "./signup";
import DialogSettings from "./dialog-settings";
import DialogImport from "./dialog-import";
import DialogAvatar from "./dialog-avatar";
import DialogChangeName from "./dialog-change-name";
import DialogSelectChain from "./dialog-select-chain";
import DialogCommunity from "./dialog-community";
import DialogLeaderboard from "./dialog-leaderboard";

export default function DialogManager({
  showLogin,
  setShowLogin,
}: {
  showLogin?: boolean;
  setShowLogin?: any;
}) {
  const { dialogs, globalLoading } = useSelector(
    (state: RootState) => state.uiSlice
  );
  function renderContent(d: DialogInfo, index: number) {
    switch (d.type) {
      case "message":
        return (
          <div
            className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-80"
            style={{ zIndex: 100 + index }}
            key={index}
          >
            <DialogMessage dialog={d} />
          </div>
        );
      case "confirm":
        return (
          <div
            style={{ zIndex: 100 + index }}
            className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-80"
            key={index}
          >
            <DialogConfirm dialog={d} />
          </div>
        );
      case "captcha":
        return (
          <div
            style={{ zIndex: 100 + index }}
            className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-80"
            key={index}
          >
            <CaptchaV2 dialog={d} />
          </div>
        );
      case "signup":
        return (
          <div
            style={{ zIndex: 100 + index }}
            className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-80"
            key={index}
          >
            <Signup dialog={d} setShowLogin={setShowLogin} />
          </div>
        );
      case "setting":
        return (
          <div
            style={{ zIndex: 100 + index }}
            className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-80"
            key={index}
          >
            <DialogSettings dialog={d} />
          </div>
        );
      case "avatar":
        return (
          <div
            style={{ zIndex: 100 + index }}
            className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-80"
            key={index}
          >
            <DialogAvatar dialog={d} />
          </div>
        );
      case "name":
        return (
          <div
            style={{ zIndex: 100 + index }}
            className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-80"
            key={index}
          >
            <DialogChangeName dialog={d} />
          </div>
        );
      case "select_chain":
        return (
          <div
            style={{ zIndex: 100 + index }}
            className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-80"
            key={index}
          >
            <DialogSelectChain dialog={d} />
          </div>
        );
      case "community":
        return (
          <div
            style={{ zIndex: 100 + index }}
            className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-80"
            key={index}
          >
            <DialogCommunity dialog={d} />
          </div>
        );
      case "leaderboard":
        return (
          <div
            style={{ zIndex: 100 + index }}
            className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-80"
            key={index}
          >
            <DialogLeaderboard dialog={d} />
          </div>
        );
      default:
        return null;
    }
  }
  return (
    <>
      <DialogImport />
      {globalLoading && <Loading />}
      <div>
        {dialogs.map((d: DialogInfo, index: number) => renderContent(d, index))}
      </div>
    </>
  );
}
