import SelectChain from "component/select-chain";
import { MongenThumbnail } from "component/thumbnail/mongen.thumbnail";
import { PlotThumbnail } from "component/thumbnail/plot.thumbnail";
import AuthEntity from "config/auth.entity";
import MongenEntity from "config/mongen.entity";
import PlotEntity from "config/plot.entity";
import useUI from "hooks/useUI";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { CHAIN_MAP } from "services/api";
import { RootState } from "store";
import { DialogInfo, removeDialog } from "../../slices/ui.slice";
import Button from "../button";

export default function DialogSelectChain({ dialog }: { dialog: DialogInfo }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const ui = useUI();
  const [auth, setAuth] = useState<AuthEntity>(null);
  const { mongenInfos, plotInfos } = useSelector(
    (state: RootState) => state.inventorySlice
  );
  function onConfirmClick() {
    if (dialog.callback) {
      dialog.callback({ error: null, data: CHAIN_MAP[auth.type] });
    }
    ui.removeDialog(dialog.id);
  }
  function onCancelClick() {
    if (dialog.callback) {
      dialog.callback({ error: "user_cancel" });
    }
    ui.removeDialog(dialog.id);
  }
  return (
    <div className="w-full h-full flex justify-center items-center">
      <div className="ui-popup_select_chain relative">
        <p className="text-[#fdf3a3] font-bold text-3xl mt-2  text-center">
          {t("convert.convert_to_nft")}
        </p>
        <p className="text-[#fdf3a3] font-bold text-xl text-center">
          {t("convert.convert_confirm")}
        </p>
        <div className="px-4 mt-12">
          <div className="w-full overflow-x-auto overflow-y-hidden h-[270px] relative">
            <div className="flex">
              {(dialog.meta.type === "mongen" ||
                dialog.meta.type === "soulcore") &&
                mongenInfos
                  .filter((m: MongenEntity) => {
                    return dialog.meta.ids.includes(m.id);
                  })
                  .map((m: MongenEntity) => (
                    <div className="relative inline-block" key={m.id}>
                      <MongenThumbnail mongenInfo={m} />
                    </div>
                  ))}
              {dialog.meta.type === "plot" &&
                plotInfos
                  .filter((m: PlotEntity) => {
                    return dialog.meta.ids.includes(m.id);
                  })
                  .map((m: PlotEntity) => (
                    <div className="relative inline-block" key={m.id}>
                      <PlotThumbnail plotInfo={m} />
                    </div>
                  ))}
            </div>
          </div>
          <p className="text-red-600 mt-4">{t("convert.warning")}</p>
          <div className="flex gap-2 items-center w-full mt-4 px-6">
            <p className="text-2xl w-full">{t("convert.select_chain")}</p>
            <SelectChain onAuthChange={setAuth} />
          </div>
        </div>
        <div className="absolute w-full bottom-7">
          <div className="w-3/4 m-auto flex gap-4 justify-between px-4">
            <Button
              text={t("dialog.cancel")}
              color="red"
              size="lg"
              onClick={() => {
                onCancelClick();
              }}
            />
            <Button
              text={t("dialog.confirm")}
              color="green"
              size="lg"
              onClick={() => {
                onConfirmClick();
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
