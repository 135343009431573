import gameConfig from "config/game-config";
import MongenEntity from "config/mongen.entity";
import Environment from "../config/environment";
import { BodyPart, MongenStatType, PlotSize, Rarity } from "../config/interface";

function toFixed(x: any) {
  if (Math.abs(x) < 1.0) {
    var e = parseInt(x.toString().split("e-")[1]);
    if (e) {
      x *= Math.pow(10, e - 1);
      x = "0." + new Array(e).join("0") + x.toString().substring(2);
    }
  } else {
    var e = parseInt(x.toString().split("+")[1]);
    if (e > 20) {
      e -= 20;
      x /= Math.pow(10, e);
      x += new Array(e + 1).join("0");
    }
  }
  return x;
}
function convertToInternationalCurrencySystem(labelValue: number, digits = 2) {
  if (!labelValue && labelValue != 0) return "0";
  let value = Math.abs(Number(Number(labelValue).toFixed(13)));

  let digitValue = 100;
  switch (digits) {
    case 2:
      digitValue = 1.0e2;
      break;
    case 3:
      digitValue = 1.0e3;
      break;
    case 4:
      digitValue = 1.0e4;
      break;
    case 5:
      digitValue = 1.0e5;
      break;
    case 6:
      digitValue = 1.0e6;
      break;
    case 7:
      digitValue = 1.0e7;
      break;
    case 8:
      digitValue = 1.0e8;
      break;
  }

  if (value >= 1.0e9) {
    value = value / 1.0e9;
    value = Math.floor(Number(value * digitValue)) / digitValue;
    value = toFixed(value);
    return value + "B";
  } else if (value >= 1.0e6) {
    value = value / 1.0e6;
    value = Math.floor(Number(value * digitValue)) / digitValue;
    value = toFixed(value);
    return value + "M";
  } else if (value >= 1.0e3) {
    value = value / 1.0e3;
    value = Math.floor(Number(value * digitValue)) / digitValue;
    value = toFixed(value);
    return value + "K";
  } else {
    value = value / 1;
    value = Math.floor(Number(value * digitValue)) / digitValue;
    value = toFixed(value);
    return value;
  }
}
// // Nine Zeroes for Billions
// return Math.abs(Number(labelValue)) >= 1.0e9
//   ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(2) + "B"
//   : // Six Zeroes for Millions
//   Math.abs(Number(labelValue)) >= 1.0e6
//   ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2) + "M"
//   : // Three Zeroes for Thousands
//   Math.abs(Number(labelValue)) >= 1.0e3
//   ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(2) + "K"
//   : Math.abs(Number(labelValue)).toFixed(2);
function calculateSize(percent: number): PlotSize {
  if (percent < Environment.SIZE[0]) {
    return PlotSize.Small;
  }
  if (percent < Environment.SIZE[1]) {
    return PlotSize.Medium;
  }
  return PlotSize.Large;
}

const isValidPassword = (password: string) => {
  if (!/^(?=.*[A-Z])[a-zA-Z\d]{8,16}$/.test(password)) {
    return false;
  }
  return true;
};

function formatHostUrl(hostapi: string) {
  if (hostapi[hostapi.length - 1] === "/") {
    return hostapi.slice(0, -1);
  }
  return hostapi;
}

function create_UUID() {
  var dt = new Date().getTime();
  var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
    /[xy]/g,
    function (c) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
    }
  );
  return uuid;
}
function blurAddress(address: string) {
  if (!address) {
    return "...";
  }
  return `${address.substring(0, 4)} ... ${address.slice(-4)}`;
}
function getDNARarity(dna: number[][]) {
  if (dna.length === 2) {
    return dna[1][0];
  }
  return dna[9][0];
}
function getDnaName(dna: number[]): string {
  let mongenName = gameConfig.getGameConfig().MongenName;
  if (!mongenName) {
    return ""
  }
  if (dna.length === 1) {
    return Rarity[dna[0]];
  }
  return mongenName[dna[2] - 1][dna[0]][dna[1]];
}

function getMongenStat(
  dna: number[][],
  level: number,
  evolution: number,
  morale_eff_value = 1,
  mutated_part_value?: any
): {
  base: number[];
  max: number[];
  ultimateMax: number[];
  rarity: number;
  basePower: number;
  currentPower: number;
  maxPower: number;
} {
  let GameConstant = gameConfig.getGameConfig();

  const bodyPartsStat: number[][] = [
    GameConstant.BaseFormStat.map(
      (i: number[], statIndex: number) =>
        i[dna[BodyPart.Form][0]] *
        GameConstant.FormRarityBaseMultiply[statIndex][dna[BodyPart.Form][1]]
    ),
    GameConstant.BaseBodyPartStat.map(
      (i: number[], statIndex: number) =>
        i[dna[BodyPart.Head][0]] *
        GameConstant.BodyPartRarityBaseMultiply[statIndex][
        dna[BodyPart.Head][1]
        ] +
        (mutated_part_value?.[statIndex]?.[BodyPart.Head] ?? 0)
    ),
    GameConstant.BaseBodyPartStat.map(
      (i: number[], statIndex: number) =>
        i[dna[BodyPart.Eyes][0]] *
        GameConstant.BodyPartRarityBaseMultiply[statIndex][
        dna[BodyPart.Eyes][1]
        ] +
        (mutated_part_value?.[statIndex]?.[BodyPart.Eyes] ?? 0)
    ),
    GameConstant.BaseBodyPartStat.map(
      (i: number[], statIndex: number) =>
        i[dna[BodyPart.Horns][0]] *
        GameConstant.BodyPartRarityBaseMultiply[statIndex][
        dna[BodyPart.Horns][1]
        ] +
        (mutated_part_value?.[statIndex]?.[BodyPart.Horns] ?? 0)
    ),
    GameConstant.BaseBodyPartStat.map(
      (i: number[], statIndex: number) =>
        i[dna[BodyPart.Tail][0]] *
        GameConstant.BodyPartRarityBaseMultiply[statIndex][
        dna[BodyPart.Tail][1]
        ] +
        (mutated_part_value?.[statIndex]?.[BodyPart.Tail] ?? 0)
    ),
    GameConstant.BaseBodyPartStat.map(
      (i: number[], statIndex: number) =>
        i[dna[BodyPart.Back][0]] *
        GameConstant.BodyPartRarityBaseMultiply[statIndex][
        dna[BodyPart.Back][1]
        ] +
        (mutated_part_value?.[statIndex]?.[BodyPart.Back] ?? 0)
    ),
  ];

  let baseStat = bodyPartsStat.reduce((a: number[], b: number[]) => {
    let tmp = [];
    for (var i = 0; i < b.length; i++) {
      tmp.push((a[i] || 0) + b[i]);
    }
    return tmp;
  }, []);

  //calculate max stat

  // baseStat = baseStat.map((i) => Math.round(i));
  let race = dna[BodyPart.Form][0];
  let currentStat: number[] = [
    //health
    baseStat[MongenStatType.Health] * 5 +
    150 +
    20 *
    baseStat[MongenStatType.Health] *
    (GameConstant.LevelStatMultiply[MongenStatType.Health][race] / 100) *
    (level + evolution - 2) *
    10,
    //sturdiness
    baseStat[MongenStatType.Sturdiness] *
    (1 +
      4 *
      (GameConstant.LevelStatMultiply[MongenStatType.Sturdiness][race] /
        100) *
      (level + evolution - 2)),
    //magic
    baseStat[MongenStatType.Magic] *
    (1 +
      6 *
      (GameConstant.LevelStatMultiply[MongenStatType.Magic][race] / 100) *
      (level + evolution - 2)),
    //aggresion
    baseStat[MongenStatType.Aggresion] *
    (1 +
      3 *
      (GameConstant.LevelStatMultiply[MongenStatType.Aggresion][race] /
        100) *
      (level + evolution - 2)),
  ];

  currentStat = currentStat.map((stat) => stat * morale_eff_value);

  let MAX_LEVEL = 100;
  let MAX_EVOL = 5;
  let ultimateMaxStat: number[] = [
    //health
    baseStat[MongenStatType.Health] * 5 +
    150 +
    20 *
    baseStat[MongenStatType.Health] *
    (GameConstant.LevelStatMultiply[MongenStatType.Health][race] / 100) *
    (MAX_LEVEL + MAX_EVOL - 2) *
    10,
    //sturdiness
    baseStat[MongenStatType.Sturdiness] *
    (1 +
      4 *
      (GameConstant.LevelStatMultiply[MongenStatType.Sturdiness][race] /
        100) *
      (MAX_LEVEL + MAX_EVOL - 2)),
    //magic
    baseStat[MongenStatType.Magic] *
    (1 +
      6 *
      (GameConstant.LevelStatMultiply[MongenStatType.Magic][race] / 100) *
      (MAX_LEVEL + MAX_EVOL - 2)),
    //aggresion
    baseStat[MongenStatType.Aggresion] *
    (1 +
      3 *
      (GameConstant.LevelStatMultiply[MongenStatType.Aggresion][race] /
        100) *
      (MAX_LEVEL + MAX_EVOL - 2)),
  ];
  //calculate power
  let basePower = Math.round(
    (Math.pow(baseStat[0], 0.525) *
      Math.pow(baseStat[1], 0.5) *
      Math.pow(baseStat[2], 0.42) *
      Math.pow(baseStat[3], 0.5)) /
    5
  );
  let currentPower = Math.round(
    (Math.pow(currentStat[0] / 10, 0.525) *
      Math.pow(currentStat[1], 0.5) *
      Math.pow(currentStat[2], 0.42) *
      Math.pow(currentStat[3], 0.5)) /
    10
  );

  let maxPower = Math.round(
    (Math.pow(ultimateMaxStat[0] / 10, 0.525) *
      Math.pow(ultimateMaxStat[1], 0.5) *
      Math.pow(ultimateMaxStat[2], 0.42) *
      Math.pow(ultimateMaxStat[3], 0.5)) /
    10
  );
  let rarity = 0;
  for (var i = GameConstant.RarityPowerRange.length - 1; i >= 0; i--) {
    if (basePower > GameConstant.RarityPowerRange[i]) {
      rarity = i;
      break;
    }
  }
  baseStat = baseStat.map((i: number) => Math.round(i));
  currentStat = currentStat.map((i: number) => Math.round(i));
  ultimateMaxStat = ultimateMaxStat.map((i: number) => Math.round(i));
  return {
    base: baseStat,
    max: currentStat,
    ultimateMax: ultimateMaxStat,
    rarity,
    basePower,
    currentPower,
    maxPower,
  };
}

function getStatFromBase(
  dna: number[][],
  level: number,
  evolution: number,
  baseStat: number[],
  morale_eff_value?: number
) {
  let GameConstant = gameConfig.getGameConfig();

  // baseStat = baseStat.map((i) => Math.round(i));
  let race = dna[BodyPart.Form][0];
  let currentStat: number[] = [
    //health
    baseStat[MongenStatType.Health] * 5 +
    150 +
    20 *
    baseStat[MongenStatType.Health] *
    (GameConstant.LevelStatMultiply[MongenStatType.Health][race] / 100) *
    (level + evolution - 2) *
    10,
    //sturdiness
    baseStat[MongenStatType.Sturdiness] *
    (1 +
      4 *
      (GameConstant.LevelStatMultiply[MongenStatType.Sturdiness][race] /
        100) *
      (level + evolution - 2)),
    //magic
    baseStat[MongenStatType.Magic] *
    (1 +
      6 *
      (GameConstant.LevelStatMultiply[MongenStatType.Magic][race] / 100) *
      (level + evolution - 2)),
    //aggresion
    baseStat[MongenStatType.Aggresion] *
    (1 +
      3 *
      (GameConstant.LevelStatMultiply[MongenStatType.Aggresion][race] /
        100) *
      (level + evolution - 2)),
  ];

  currentStat = currentStat.map((stat) => stat * (morale_eff_value ?? 1));
  baseStat = baseStat.map((i: number) => Math.round(i));
  currentStat = currentStat.map((i: number) => Math.round(i));
  return currentStat;
}

const getMongenBaseStat = (mongenInfo: MongenEntity, mutateValue: any, temp_part_value?: any) => {
  let dna = mongenInfo.dna;
  let GameConstant = gameConfig.getGameConfig();

  let body_part = temp_part_value?.body_part
  let mutated_part_value = mutateValue ? { ...mutateValue } : null;

  temp_part_value = temp_part_value?.value;

  if (!mutated_part_value) {
    mutated_part_value = {
      [MongenStatType.Health]: [0, 0, 0, 0, 0, 0],
      [MongenStatType.Sturdiness]: [0, 0, 0, 0, 0, 0],
      [MongenStatType.Magic]: [0, 0, 0, 0, 0, 0],
      [MongenStatType.Aggresion]: [0, 0, 0, 0, 0, 0],
    };
  }


  if (temp_part_value) {
    Object.keys(mutated_part_value).map((stat) => {
      let change_value = temp_part_value[stat] ?? 0;
      let tmp = mutated_part_value[stat] ? [...mutated_part_value[stat]] : []
      tmp[body_part] = change_value;
      mutated_part_value[stat] = [...tmp];
    });
  }

  const bodyPartsStat: number[][] = [
    GameConstant.BaseFormStat.map(
      (i: number[], statIndex: number) =>
        i[dna[BodyPart.Form][0]] *
        GameConstant.FormRarityBaseMultiply[statIndex][dna[BodyPart.Form][1]]
    ),
    GameConstant.BaseBodyPartStat.map(
      (i: number[], statIndex: number) =>
        i[dna[BodyPart.Head][0]] *
        GameConstant.BodyPartRarityBaseMultiply[statIndex][
        dna[BodyPart.Head][1]
        ] +
        (mutated_part_value?.[statIndex]?.[BodyPart.Head] ?? 0)
    ),
    GameConstant.BaseBodyPartStat.map(
      (i: number[], statIndex: number) =>
        i[dna[BodyPart.Eyes][0]] *
        GameConstant.BodyPartRarityBaseMultiply[statIndex][
        dna[BodyPart.Eyes][1]
        ] +
        (mutated_part_value?.[statIndex]?.[BodyPart.Eyes] ?? 0)
    ),
    GameConstant.BaseBodyPartStat.map(
      (i: number[], statIndex: number) =>
        i[dna[BodyPart.Horns][0]] *
        GameConstant.BodyPartRarityBaseMultiply[statIndex][
        dna[BodyPart.Horns][1]
        ] +
        (mutated_part_value?.[statIndex]?.[BodyPart.Horns] ?? 0)
    ),
    GameConstant.BaseBodyPartStat.map(
      (i: number[], statIndex: number) =>
        i[dna[BodyPart.Tail][0]] *
        GameConstant.BodyPartRarityBaseMultiply[statIndex][
        dna[BodyPart.Tail][1]
        ] +
        (mutated_part_value?.[statIndex]?.[BodyPart.Tail] ?? 0)
    ),
    GameConstant.BaseBodyPartStat.map(
      (i: number[], statIndex: number) =>
        i[dna[BodyPart.Back][0]] *
        GameConstant.BodyPartRarityBaseMultiply[statIndex][
        dna[BodyPart.Back][1]
        ] +
        (mutated_part_value?.[statIndex]?.[BodyPart.Back] ?? 0)
    ),
  ];

  const origin: number[][] = [
    GameConstant.BaseFormStat.map(
      (i: number[], statIndex: number) =>
        i[dna[BodyPart.Form][0]] *
        GameConstant.FormRarityBaseMultiply[statIndex][dna[BodyPart.Form][1]]
    ),
    GameConstant.BaseBodyPartStat.map(
      (i: number[], statIndex: number) =>
        i[dna[BodyPart.Head][0]] *
        GameConstant.BodyPartRarityBaseMultiply[statIndex][dna[BodyPart.Head][1]]
    ),
    GameConstant.BaseBodyPartStat.map(
      (i: number[], statIndex: number) =>
        i[dna[BodyPart.Eyes][0]] *
        GameConstant.BodyPartRarityBaseMultiply[statIndex][dna[BodyPart.Eyes][1]]
    ),
    GameConstant.BaseBodyPartStat.map(
      (i: number[], statIndex: number) =>
        i[dna[BodyPart.Horns][0]] *
        GameConstant.BodyPartRarityBaseMultiply[statIndex][dna[BodyPart.Horns][1]]
    ),
    GameConstant.BaseBodyPartStat.map(
      (i: number[], statIndex: number) =>
        i[dna[BodyPart.Tail][0]] *
        GameConstant.BodyPartRarityBaseMultiply[statIndex][dna[BodyPart.Tail][1]]
    ),
    GameConstant.BaseBodyPartStat.map(
      (i: number[], statIndex: number) =>
        i[dna[BodyPart.Back][0]] *
        GameConstant.BodyPartRarityBaseMultiply[statIndex][dna[BodyPart.Back][1]]
    ),
  ];

  let baseStat = bodyPartsStat.reduce((a: number[], b: number[]) => {
    let tmp = [];
    for (var i = 0; i < b.length; i++) {
      tmp.push((a[i] || 0) + b[i]);
    }
    return tmp;
  }, []);
  let originStat = origin.reduce((a: number[], b: number[]) => {
    let tmp = [];
    for (var i = 0; i < b.length; i++) {
      tmp.push((a[i] || 0) + b[i]);
    }
    return tmp;
  }, []);

  let changed = baseStat.map((v, idx) => Number((v - originStat[idx]).toFixed(2)))

  let currentPower = getStatFromBase(mongenInfo.dna, mongenInfo.level, mongenInfo.evolution, baseStat)
  let originPower = getStatFromBase(mongenInfo.dna, mongenInfo.level, mongenInfo.evolution, originStat)
  let changePower = currentPower.map((v, idx) => Number((v - originPower[idx]).toFixed(2)))

  return { baseStat, originStat, changed, currentPower, originPower, changePower };
}

const getMongenBaseStatConfig = (dna: number[][], mutated_part_value: any, temp_part_value?: any) => {
  let GameConstant = gameConfig.getGameConfig();

  if (!mutated_part_value) {
    mutated_part_value = {
      [MongenStatType.Health]: [],
      [MongenStatType.Sturdiness]: [],
      [MongenStatType.Magic]: [],
      [MongenStatType.Aggresion]: [],
    };
  }

  if (temp_part_value?.value) {
    Object.keys(temp_part_value.value).map((statIndex) => {
      let arr = [0, 0, 0, 0, 0, 0];
      arr[temp_part_value.body_part] = temp_part_value.value[statIndex];
      mutated_part_value[statIndex] = arr;
    })
  }

  const bodyPartsStat: number[][] = [
    GameConstant.BaseFormStat.map(
      (i: number[], statIndex: number) =>
        i[dna[BodyPart.Form][0]] *
        GameConstant.FormRarityBaseMultiply[statIndex][dna[BodyPart.Form][1]]
    ),
    GameConstant.BaseBodyPartStat.map(
      (i: number[], statIndex: number) =>
        i[dna[BodyPart.Head][0]] *
        GameConstant.BodyPartRarityBaseMultiply[statIndex][
        dna[BodyPart.Head][1]
        ] +
        (mutated_part_value?.[statIndex]?.[BodyPart.Head] ?? 0)
    ),
    GameConstant.BaseBodyPartStat.map(
      (i: number[], statIndex: number) =>
        i[dna[BodyPart.Eyes][0]] *
        GameConstant.BodyPartRarityBaseMultiply[statIndex][
        dna[BodyPart.Eyes][1]
        ] +
        (mutated_part_value?.[statIndex]?.[BodyPart.Eyes] ?? 0)
    ),
    GameConstant.BaseBodyPartStat.map(
      (i: number[], statIndex: number) =>
        i[dna[BodyPart.Horns][0]] *
        GameConstant.BodyPartRarityBaseMultiply[statIndex][
        dna[BodyPart.Horns][1]
        ] +
        (mutated_part_value?.[statIndex]?.[BodyPart.Horns] ?? 0)
    ),
    GameConstant.BaseBodyPartStat.map(
      (i: number[], statIndex: number) =>
        i[dna[BodyPart.Tail][0]] *
        GameConstant.BodyPartRarityBaseMultiply[statIndex][
        dna[BodyPart.Tail][1]
        ] +
        (mutated_part_value?.[statIndex]?.[BodyPart.Tail] ?? 0)
    ),
    GameConstant.BaseBodyPartStat.map(
      (i: number[], statIndex: number) =>
        i[dna[BodyPart.Back][0]] *
        GameConstant.BodyPartRarityBaseMultiply[statIndex][
        dna[BodyPart.Back][1]
        ] +
        (mutated_part_value?.[statIndex]?.[BodyPart.Back] ?? 0)
    ),
  ];

  const origin: number[][] = [
    GameConstant.BaseFormStat.map(
      (i: number[], statIndex: number) =>
        i[dna[BodyPart.Form][0]] *
        GameConstant.FormRarityBaseMultiply[statIndex][dna[BodyPart.Form][1]]
    ),
    GameConstant.BaseBodyPartStat.map(
      (i: number[], statIndex: number) =>
        i[dna[BodyPart.Head][0]] *
        GameConstant.BodyPartRarityBaseMultiply[statIndex][dna[BodyPart.Head][1]]
    ),
    GameConstant.BaseBodyPartStat.map(
      (i: number[], statIndex: number) =>
        i[dna[BodyPart.Eyes][0]] *
        GameConstant.BodyPartRarityBaseMultiply[statIndex][dna[BodyPart.Eyes][1]]
    ),
    GameConstant.BaseBodyPartStat.map(
      (i: number[], statIndex: number) =>
        i[dna[BodyPart.Horns][0]] *
        GameConstant.BodyPartRarityBaseMultiply[statIndex][dna[BodyPart.Horns][1]]
    ),
    GameConstant.BaseBodyPartStat.map(
      (i: number[], statIndex: number) =>
        i[dna[BodyPart.Tail][0]] *
        GameConstant.BodyPartRarityBaseMultiply[statIndex][dna[BodyPart.Tail][1]]
    ),
    GameConstant.BaseBodyPartStat.map(
      (i: number[], statIndex: number) =>
        i[dna[BodyPart.Back][0]] *
        GameConstant.BodyPartRarityBaseMultiply[statIndex][dna[BodyPart.Back][1]]
    ),
  ];

  let changed = bodyPartsStat.map((arr, i) => {
    return arr.map((v, j) => Number((v - origin[i][j]).toFixed(2)))
  })

  return { bodyPartsStat, changed, origin };
}

const getMutatedPrice = (mongenInfo: MongenEntity, body_part: BodyPart) => {
  let GameConstant = gameConfig.getGameConfig();

  let times = mongenInfo.temp_mutated_part_value?.times ?? 0;
  let increasePrice = Math.min(
    times * 20,
    GameConstant.MAX_MUTATE_PRICE_INCREASE
  );

  let rarity = mongenInfo.dna[body_part][1];
  let mutatedGenCost = GameConstant.Mutate_Mongen_Part[rarity][1];
  let mstrCost = GameConstant.Mutate_Mongen_Part[rarity][2];

  mutatedGenCost = Math.ceil((mutatedGenCost * (100 + increasePrice)) / 100);
  mstrCost = Math.ceil((mstrCost * (100 + increasePrice)) / 100);

  return {
    mutated_gen: mutatedGenCost,
    mstr: mstrCost,
  };
}

const utils = {
  getDNARarity,
  blurAddress,
  convertToInternationalCurrencySystem,
  isValidPassword,
  calculateSize,
  formatHostUrl,
  create_UUID,
  getDnaName,
  getMongenStat,
  getMongenBaseStat,
  getMutatedPrice,
  getMongenBaseStatConfig,
  getStatFromBase
};

export default utils;
