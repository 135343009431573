import useCustomer from "hooks/useCustomer";
import useUI from "hooks/useUI";
import _ from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import api from "services/api";
import { RootState } from "store";
import { DialogInfo, removeDialog } from "../../slices/ui.slice";
import Button from "../button";
export default function DialogChangeName({ dialog }: { dialog: DialogInfo }) {
  const { t } = useTranslation();
  const { customerInfo } = useSelector((state: RootState) => state.userSlice);
  const [name, setName] = useState<string>(customerInfo.name || "");
  const ui = useUI();
  const cs = useCustomer();
  async function submit() {
    if (!isNameValid()) {
      return;
    }
    ui.showLoading();
    try {
      let rs = await api.updateCustomerProfile({ name });
      let tmp = _.cloneDeep(customerInfo);
      tmp.name = name;
      cs.updateCustomerInfo(tmp);
      onClose();
    } catch (error) {
    } finally {
      ui.hideLoading();
    }
  }
  function onClose() {
    ui.removeDialog(dialog.id);
  }
  function isNameValid() {
    return name.length >= 4 && name.length <= 16;
  }
  return (
    <div className="w-full h-full flex justify-center items-center">
      <div className="ui-popup_confirm_fusion relative p-6">
        <img
          alt="close"
          onClick={() => {
            onClose();
          }}
          src="close.png"
          className="absolute top-1 right-1 w-16 h-16 interactive"
        />
        <p className="text-[#fdf3a3] font-bold text-3xl text-center">
          {t("profile.change_name")}
        </p>
        <div className="mt-8">
          <p className={`text-right ${!isNameValid() && "text-red-600"}`}>
            <span>{name.length}</span>/16 {t("profile.characters")}
          </p>
          <div className="flex gap-4 items-center mt-4">
            <p>{t("profile.name")}</p>
            <input
              placeholder={t("profile.name_condition")}
              value={name}
              maxLength={16}
              type="text"
              className="w-full text-black text-center p-2 text-md rounded-xl"
              onChange={(e: any) => {
                setName(e.target.value);
              }}
            />
          </div>
          <p className="mt-8">{t("profile.name_description")}</p>
        </div>
        <button
          className="ui-button_save_on mx-auto block mt-12"
          onClick={() => {
            submit();
          }}
        >
          <p className="text-2xl">{t("profile.change")}</p>
        </button>
      </div>
    </div>
  );
}
