import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FusionPlotThumbnail } from "../../component/thumbnail/fusion-plot.thumbnail";
import { FusionThumbnail } from "../../component/thumbnail/fusion.thumbnail";
import { PlotThumbnail } from "../../component/thumbnail/plot.thumbnail";
import MongenEntity from "../../config/mongen.entity";
import PlotEntity from "../../config/plot.entity";
import { RootState } from "../../store";
import { headShake as anim, pulse } from "react-animations";
import styled, { keyframes } from "styled-components";
import _ from "lodash";
import Mongen from "../../component/static-anim/mongen";
import useUI from "../../hooks/useUI";
import api from "../../services/api";
import { MongenState, NFTType, Rarity } from "../../config/interface";
import PlotImage from "../../component/thumbnail/plot-image";
import gameConfig from "../../config/game-config";
import utils from "../../services/utils";
import useCustomer from "../../hooks/useCustomer";
import FusionResult from "./result";
import Balance from "component/balance";
import RarityFilter from "component/rarity-filter";
const AnimDiv = styled.div`
  .animate {
    animation: 1s ${keyframes`${anim}`} infinite;
  }
`;
export default function Fusion() {
  const { t } = useTranslation();
  const nav = useNavigate();
  const ui = useUI();
  const customerService = useCustomer();
  const { mongenInfos, plotInfos } = useSelector(
    (state: RootState) => state.inventorySlice
  );
  const [type, setType] = useState<"mongen" | "plot">("mongen");
  const [newMongen, setNewMongen] = useState<MongenEntity | null>(null);
  const [newPlot, setNewPlot] = useState<PlotEntity | null>(null);
  const [fee, setFee] = useState<{ mstr: number; elixir: number } | null>(null);

  const [showResult, setShowResult] = useState<boolean>(false);
  const [demo, setDemo] = useState<any>(null);
  const [slot, setSlot] = useState<number>(0);
  const [attach, setAttach] = useState<number[]>([]);
  const [rarity, setRarity] = useState<Rarity>(Rarity.All);
  const [sortField, setSortField] = useState<"id" | "rarity" | "capacity">(
    "id"
  );
  function onBackClick() {
    nav(-1);
  }
  function onSlotClick(index: number) {
    if (index !== 1 && !attach[1]) {
      return ui.openMessage(t("fusion.main_mongen_not_set"));
    }
    setSlot(index);
  }
  function onInventoryClick(id: number) {
    if (!slot) {
      return autoAddSlot(id);
    }
    let tmpAttach = _.cloneDeep(attach);
    if (tmpAttach.includes(id) && tmpAttach[slot] !== id) {
      return;
    }
    tmpAttach[slot] = tmpAttach[slot] === id ? 0 : id;
    if (slot === 1) {
      tmpAttach = [0, tmpAttach[1]];
    }
    setAttach(tmpAttach);
  }
  function autoAddSlot(id: number) {
    let tmp = _.cloneDeep(attach);
    if (tmp.includes(id)) {
      tmp[tmp.indexOf(id)] = 0;
      return setAttach(tmp);
    }
    let index = 1;
    for (var i = 1; i < 5; i++) {
      if (!attach[i]) {
        index = i;
        break;
      }
    }
    tmp[index] = id;
    console.log(tmp);
    setAttach(tmp);
  }
  function fullCondition() {
    return attach[1] > 0 && attach[2] > 0 && attach[3] > 0 && attach[4] > 0;
  }
  useEffect(() => {
    switch (type) {
      case "mongen":
        if (!attach[1]) {
          return setFee(null);
        }
        let mainMongen = mongenInfos.find(
          (m: MongenEntity) => m.id === attach[1]
        );
        if (!mainMongen) return;
        let config = gameConfig.getItemInfo(NFTType.Mongen);
        if (!config) return;
        setFee({
          mstr: config.fusionMstrCost[mainMongen.dna[9][0]],
          elixir: config.fusionElixirCost[mainMongen.dna[9][0]],
        });
        if (fullCondition()) {
          let tmpMongen = _.cloneDeep(mainMongen);
          tmpMongen.dna = [
            [tmpMongen.dna[0][0], tmpMongen.dna[0][1] + 1, tmpMongen.dna[0][2]],
            [tmpMongen.dna[9][0]+1],
          ];
          setDemo(tmpMongen);
        } else {
          setDemo(null);
        }
        break;
      case "plot":
        if (!attach[1]) {
          return setFee(null);
        }
        let mainPlot = plotInfos.find((m: PlotEntity) => m.id === attach[1]);
        if (!mainPlot) return;
        let plotConfig = gameConfig.getItemInfo(
          NFTType.Plot,
          mainPlot.plot_type
        );
        if (!plotConfig) return;
        setFee({
          mstr: plotConfig.fusionMstrCost[mainPlot.rarity],
          elixir: plotConfig.fusionElixirCost[mainPlot.rarity],
        });
        if (fullCondition()) {
          let tmpPlot = _.cloneDeep(mainPlot);
          tmpPlot.rarity++;
          setDemo(tmpPlot);
        } else {
          setDemo(null);
        }
        break;
    }
  }, [attach]);
  async function onFusionClick() {
    if (type === "mongen") {
      if (newMongen) {
        return;
      }
      for (var i = 1; i <= 4; i++) {
        if (!attach[i]) {
          return ui.openMessage(t("fusion.not_enough_mongen"));
        }
      }
      await ui.openConfirm(t("fusion.confirm_fusion"));
      try {
        ui.showLoading();
        let { newItemEntity, customerInfo, treePlotInfo } = await api.fusion(
          attach[1],
          [attach[2], attach[3], attach[4]],
          NFTType.Mongen
        );
        ui.openMessage(t("fusion.fusion_success"));
        setNewMongen(newItemEntity);
        setShowResult(true);
        setAttach([]);
        customerService.updateCustomerInfo(customerInfo);
        customerService.refreshInventory();
      } catch (error: any) {
        ui.openMessage(t(error.message));
      } finally {
        ui.hideLoading();
        await gameConfig.loadConfig();
      }
    }
    if (type === "plot") {
      if (newPlot) {
        return;
      }
      for (var i = 1; i <= 4; i++) {
        if (!attach[i]) {
          return ui.openMessage(t("fusion.not_enough_plot"));
        }
      }
      await ui.openConfirm(t("fusion.confirm_fusion"));
      try {
        ui.showLoading();
        let { newItemEntity, customerInfo, treePlotInfo } = await api.fusion(
          attach[1],
          [attach[2], attach[3], attach[4]],
          NFTType.Plot
        );
        ui.openMessage(t("fusion.fusion_success"));
        setShowResult(true);
        setAttach([]);
        customerService.updateCustomerInfo(customerInfo);
        setNewPlot(newItemEntity);
        customerService.refreshInventory();
      } catch (error: any) {
        ui.openMessage(t(error.message));
      } finally {
        ui.hideLoading();
      }
    }
  }
  function onTypeClick(_type: "mongen" | "plot") {
    if (_type === type) {
      return;
    }
    setNewMongen(null);
    setDemo(null);
    setNewPlot(null);
    setAttach([]);
    setSlot(0);
    setType(_type);
  }
  function slotButton(index: number) {
    return (
      <div
        className={`duration-150 ui-blank_fusion_slot mx-auto flex items-center justify-center interactive ${slot === index && "scale-125"
          } ${slot !== index && !attach[index] && "grayscale"}`}
        onClick={() => {
          onSlotClick(index);
        }}
      >
        {!attach[index] ? (
          <div className="ui-added_fusion"></div>
        ) : (
          <div>
            {type === "mongen" && (
              <Mongen
                dna={
                  mongenInfos.find((m: MongenEntity) => m.id === attach[index])
                    ?.dna
                }
              />
            )}
            {type === "plot" &&
              plotInfos
                .filter((m: PlotEntity) => m.id === attach[index])
                .map((p: PlotEntity) => <PlotImage plotInfo={p} />)}
          </div>
        )}
      </div>
    );
  }
  return (
    <>
      {showResult && (newMongen || newPlot) && (
        <FusionResult
          mongenInfo={newMongen}
          plotInfo={newPlot}
          onClose={() => {
            setNewMongen(null);
            setNewPlot(null);
            setShowResult(false);
            setDemo(null);
          }}
        />
      )}
      <div className="ui-background p-4">
        <div className="flex justify-between w-full items-center">
          <p className="text-[90px] font-bold text-texttitle h-24">
            {t("fusion.fusion")}
          </p>
          <div className="mb-4">
            <Balance />
          </div>
          <button
            className="ui-button_back_on interactive text-3xl font-bold"
            onClick={() => {
              onBackClick();
            }}
          >
            {t("global.back")}
          </button>
        </div>
        <div className="flex gap-8">
          <div className="w-5/12 overflow-hidden">
            <div className="overflow-y-auto overflow-x-hidden h-[800px] ui-blank_fusion_list">
              {type === "mongen" &&
                mongenInfos
                  .filter((m: MongenEntity) => {
                    if (m.mongen_state === MongenState.Egg) return false;
                    if (
                      m.dna[9][0] === Rarity.Legendary ||
                      m.is_click2earn ||
                      utils.getDNARarity(m.dna) === Rarity.Common
                    ) {
                      return false;
                    }
                    if (rarity !== Rarity.All && m.dna[9][0] !== rarity) {
                      return false;
                    }
                    if (!attach[1] || slot === 1) {
                      return (
                        m.level >= gameConfig.getGameConfig().MaxMongenLevel
                      );
                    }
                    let main = mongenInfos.find(
                      (i: MongenEntity) => i.id === attach[1]
                    );
                    if (main) {
                      return m.dna[9][0] === main.dna[9][0];
                    }
                  })
                  .sort((a: MongenEntity, b: MongenEntity) => {
                    switch (sortField) {
                      case "id":
                        return b.id - a.id;
                      case "rarity":
                        return b.dna[9][0] - a.dna[9][0];
                    }
                    return b.id - a.id;
                  })
                  .map((m: MongenEntity) => (
                    <div className="w-1/3 p-1 inline-block" key={m.id}>
                      <FusionThumbnail
                        mongenInfo={m}
                        onClick={() => {
                          onInventoryClick(m.id);
                        }}
                        active={attach.includes(m.id)}
                      />
                    </div>
                  ))}
              {type === "plot" &&
                plotInfos
                  .filter((m: PlotEntity) => {
                    if (
                      m.rarity === Rarity.Legendary ||
                      m.is_click2earn ||
                      m.rarity === Rarity.Common
                    )
                      return false;
                    if (rarity !== Rarity.All && m.rarity !== rarity) {
                      return false;
                    }
                    if (!attach[1] || slot === 1) {
                      let maxLevel =
                        gameConfig.getGameConfig().MaxPlotLevel[m.plot_type];
                      return m.level >= maxLevel;
                    }
                    let main = plotInfos.find(
                      (i: PlotEntity) => i.id === attach[1]
                    );
                    if (main) {
                      return (
                        m.plot_type === main.plot_type &&
                        m.rarity === main.rarity
                      );
                    }
                  })
                  .sort((a: PlotEntity, b: PlotEntity) => {
                    switch (sortField) {
                      case "id":
                        return b.id - a.id;
                      case "rarity":
                        return b.rarity - a.rarity;
                    }
                    return b.id - a.id;
                  })
                  .map((m: PlotEntity) => (
                    <div className="w-1/3 p-1 inline-block" key={m.id}>
                      <FusionPlotThumbnail
                        plotInfo={m}
                        onClick={() => {
                          onInventoryClick(m.id);
                        }}
                        active={attach.includes(m.id)}
                      />
                    </div>
                  ))}
            </div>
          </div>
          <div className="w-5/12">
            <div className="ui-blank_fusion p-4">
              <div className="ui-blank_fusion_slot block mx-auto">
                {type === "mongen" && demo && <Mongen dna={demo?.dna} />}
                {type === "plot" && demo && <PlotImage plotInfo={demo} />}
              </div>
              <div className="flex justify-center items-center mt-[99px] h-[438px]">
                {slotButton(2)}
                <div className="h-full flex justify-between flex-col">
                  {slotButton(1)}
                  {slotButton(3)}
                </div>
                {slotButton(4)}
              </div>
            </div>
          </div>
          <div className="text-right w-[200px] flex flex-col justify-between">
            <div>
              <button
                onClick={() => {
                  onTypeClick("mongen");
                }}
                className={`ui-button_mongen_on interactive text-3xl font-bold ${type !== "mongen" && "grayscale"
                  }`}
              >
                {t("global.mongen")}
              </button>
              <button
                onClick={() => {
                  onTypeClick("plot");
                }}
                className={`ui-button_mongen_on interactive text-3xl font-bold mt-4 ${type !== "plot" && "grayscale"
                  }`}
              >
                {t("global.plot")}
              </button>
            </div>
          </div>
        </div>
        <div className="w-full absolute bottom-0 left-0 p-4">
          <div className="relative w-full">
            <div>
              <RarityFilter
                rarity={rarity}
                sort={sortField}
                setRarity={(r: Rarity) => {
                  setRarity(r);
                }}
                setSortField={setSortField}
                disabledRarity={[Rarity.Common, Rarity.Legendary]}
              />
            </div>
            <div className="bottom-0 right-0 absolute">
              {type === "mongen" && <div className="text-[#d33c3c] text-center mb-3">
                Legendary
                <br />
                Mongen left:
                <br />
                {gameConfig.getGameConfig().currentFusionSeason ? (gameConfig.getGameConfig().currentFusionSeason.limit_mongen - gameConfig.getGameConfig().legendaryMongenFusioned) : 0}
              </div>}
              <button
                onClick={onFusionClick}
                className={`ui-button_start_fusion interactive text-3xl font-bold ${newMongen !== null && "grayscale"
                  }`}
              >
                <p>{t("fusion.fusion")}</p>
                <div className="flex gap-2 items-center justify-center w-full flex-col">
                  <div className="flex items-center">
                    <div className="ui-icon_mstr mr-1"></div>
                    <span className="text-textnumberyellow">
                      {utils.convertToInternationalCurrencySystem(
                        fee?.mstr || 0
                      )}
                    </span>
                  </div>
                  <div className="flex items-center">
                    <div className="ui-elixir_small mr-1"></div>
                    <span className="text-textnumberyellow">
                      {utils.convertToInternationalCurrencySystem(
                        fee?.elixir || 0
                      )}
                    </span>
                  </div>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
