import { useTranslation } from "react-i18next";
import styled, { keyframes } from "styled-components";
import { rotateOutUpRight as anim, pulse } from "react-animations";
import { useDispatch, useSelector } from "react-redux";
import useUI from "hooks/useUI";
import { RootState } from "store";
import userSlice from "slices/user.slice";
import _ from "lodash";
import useCustomer from "hooks/useCustomer";
import { getSSOMeta, SSO_META, SSO_VERSION } from "config/game-config";
import SelectChain from "component/select-chain";
import { useState } from "react";
import AuthEntity from "config/auth.entity";
import Environment from "config/environment";
const animName = keyframes`${anim}`;
const AnimDiv = styled.div`
  animation: 4s ${animName} infinite;
`;
export default function DialogSettings({
  dialog: dialogInfo,
}: {
  dialog: any;
}) {
  const { t } = useTranslation();
  const dialog = useUI();
  const cs = useCustomer();
  const [auth, setAuth] = useState<AuthEntity>(null);
  const { setting, customerInfo } = useSelector(
    (state: RootState) => state.userSlice
  );
  function onToggleClick(type: "music" | "sfx", value: boolean) {
    let tmp = _.cloneDeep(setting);
    tmp[type] = value;
    cs.updateSetting(tmp);
  }
  async function onLogoutClick() {
    await dialog.openConfirm(t("settings.confirm_logout"));
    sessionStorage.clear();
    localStorage.clear();
    dialog.removeDialog(dialogInfo.id);
    window.location.reload();
  }
  function onClose() {
    dialog.removeDialog(dialogInfo.id);
  }

  return (
    <div className="absolute top-0 left-0 h-full w-full z-50">
      <div className="flex relative w-full h-full items-center justify-center">
        <div className="ui-popup_select_chain relative">
          <img
            onClick={() => {
              onClose();
            }}
            src="close.png"
            className="absolute top-1 right-1 w-16 h-16 interactive"
          />
          <p className="text-[#fdf3a3] font-bold text-3xl mt-5 text-center">
            {t("settings.settings")}
          </p>
          <div className="mt-10 p-6">
            <div className="flex justify-between items-center">
              <p className="text-3xl">{t("settings.music")}</p>
              <div className="flex gap-2">
                <button
                  className={`ui-checkbox interactive text-3xl font-bold ${
                    !setting.music && "grayscale"
                  }`}
                  onClick={() => {
                    onToggleClick("music", true);
                  }}
                >
                  {t("settings.on")}
                </button>
                <button
                  className={`ui-checkbox interactive text-3xl font-bold ${
                    setting.music && "grayscale"
                  }`}
                  onClick={() => {
                    onToggleClick("music", false);
                  }}
                >
                  {t("settings.off")}
                </button>
              </div>
            </div>

            <div className="flex justify-between items-center mt-4">
              <p className="text-3xl">{t("settings.sfx")}</p>
              <div className="flex  gap-2">
                <button
                  className={`ui-checkbox interactive text-3xl font-bold ${
                    !setting.sfx && "grayscale"
                  }`}
                  onClick={() => {
                    onToggleClick("sfx", true);
                  }}
                >
                  {t("settings.on")}
                </button>
                <button
                  className={`ui-checkbox interactive text-3xl font-bold ${
                    setting.sfx && "grayscale"
                  }`}
                  onClick={() => {
                    onToggleClick("sfx", false);
                  }}
                >
                  {t("settings.off")}
                </button>
              </div>
            </div>
            <div className="flex justify-between items-center mt-4 gap-4">
              <p className="text-3xl">{t("setting.wallet")}</p>
              <div
                className="w-[260px]"
                // href="https://dao.monsterra.io"
                // target="_blank"
              >
                <SelectChain onAuthChange={setAuth} />
              </div>
            </div>
            <div className="flex gap-2 flex-col mt-2 px-10 text-xl">
              <a
                className="text-textnumberyellow text-center"
                target="_blank"
                href={SSO_META().GuideLinkLegend}
              >
                {t("settings.guideline")}
              </a>
              <a
                className="text-textnumberyellow text-center cursor-pointer"
                onClick={() => {
                  dialog.openCommunity();
                }}
              >
                {t("settings.community")}
              </a>
              <a
                className="text-textnumberyellow text-center"
                target="_blank"
                href={SSO_META().SupportLink}
              >
                {t("settings.contact")}
              </a>
            </div>
            <div className="mt-2 flex justify-between">
              <button
                onClick={() => {
                  onLogoutClick();
                }}
                className="ui-button_cancel_on text-2xl flex items-center justify-center mx-auto interactive"
              >
                {t("settings.logout")}
              </button>
            </div>
            <p className="text-center mt-2 text-slate-400">
              {t("global.version")}: {Environment.VERSION}_
              {SSO_VERSION().backend}@{customerInfo.id}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
